<template>
  <div class="knowle_welcome_message">
    <div class="gr-content-container">
      <div class="personalHeader">
        <detail-page-header @goBack="goBack">
          <div slot="header-name" class="header-name">
            <span class="template-name">{{$route.query.agentName}}</span>
          </div>
          <div slot="header-des" class="content-header-left-box" @click="checkWelcome"> {{$t('flowComponent.changeMode')}}</div>
          <div slot="header-title-c-r-btn" class="header-title-c-r-btn">
            <div class="save-btn" @click="saveWelcome">
              <i class="iconfont guoran-tongyichicun-16-03-baocun"></i>
              {{$t('common.save')}}
            </div>
          </div>
        </detail-page-header>
      </div>
      <div class="welcome_message_content">
        <div class="message_content">
          <div class="message_left">
            <div class="tab">{{$t('channel.knowledgeMsgPage.template')}}</div>
            <div class="template_list">
              <div class="template_view">
                <p class="template_title">{{$t('channel.knowledgeMsgPage.templateTitle')}}</p>
                <div class="template_content" @click="addCard('dialog')">
                  <p class="title">{{$t('channel.knowledgeMsgPage.title')}}</p>
                  <p class="tip">{{$t('channel.knowledgeMsgPage.des')}}</p>
                  <div class="template_card">
                    <div class="card">
                      <section class="card_left">
                        <i class="iconfont"></i>
                      </section>
                      <section class="card_right">
                        <p class="card_title">{{$t('channel.knowledgeMsgPage.cardTitle')}}</p>
                        <p class="card_tip">{{$t('channel.knowledgeMsgPage.cardDes')}}</p>
                      </section>
                    </div>
                    <div class="card">
                      <section class="card_left">
                        <i class="iconfont"></i>
                      </section>
                      <section class="card_right">
                        <p class="card_title">{{$t('channel.knowledgeMsgPage.cardTitle')}}</p>
                        <p class="card_tip">{{$t('channel.knowledgeMsgPage.cardDes')}}</p>
                      </section>
                    </div>
                    <div class="card">
                      <section class="card_left">
                        <i class="iconfont"></i>
                      </section>
                      <section class="card_right">
                        <p class="card_title">{{$t('channel.knowledgeMsgPage.cardTitle')}}</p>
                        <p class="card_tip">{{$t('channel.knowledgeMsgPage.cardDes')}}</p>
                      </section>
                    </div>
                    <div class="card">
                      <section class="card_left">
                        <i class="iconfont"></i>
                      </section>
                      <section class="card_right">
                        <p class="card_title">{{$t('channel.knowledgeMsgPage.cardTitle')}}</p>
                        <p class="card_tip">{{$t('channel.knowledgeMsgPage.cardDes')}}</p>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
              <div class="template_view">
                <p class="template_title">{{$t('channel.knowledgeMsgPage.addCard')}}</p>
                <div class="template_content" @click="addCard('knowledge')">
                  <p class="title">{{$t('channel.knowledgeMsgPage.title')}}</p>
                  <p class="tip">{{$t('channel.knowledgeMsgPage.des')}}</p>
                  <div class="template_card">
                    <div class="card">
                      <section class="card_left">
                        <i class="iconfont"></i>
                      </section>
                      <section class="card_right">
                        <p class="card_title">{{$t('channel.knowledgeMsgPage.knowledgeTitle')}}</p>
                        <p class="card_tip">
                          {{$t('channel.knowledgeMsgPage.creator')}}
                          <el-divider direction="vertical"></el-divider>
                          {{$t('channel.knowledgeMsgPage.addedTime')}}
                        </p>
                      </section>
                    </div>
                    <div class="card">
                      <section class="card_left">
                        <i class="iconfont"></i>
                      </section>
                      <section class="card_right">
                        <p class="card_title">{{$t('channel.knowledgeMsgPage.knowledgeTitle')}}</p>
                        <p class="card_tip">
                          {{$t('channel.knowledgeMsgPage.creator')}}
                          <el-divider direction="vertical"></el-divider>
                          {{$t('channel.knowledgeMsgPage.addedTime')}}
                        </p>
                      </section>
                    </div>
                    <div class="card">
                      <section class="card_left">
                        <i class="iconfont"></i>
                      </section>
                      <section class="card_right">
                        <p class="card_title">{{$t('channel.knowledgeMsgPage.knowledgeTitle')}}</p>
                        <p class="card_tip">
                          {{$t('channel.knowledgeMsgPage.creator')}}
                          <el-divider direction="vertical"></el-divider>
                          {{$t('channel.knowledgeMsgPage.addedTime')}}
                        </p>
                      </section>
                    </div>
                    <div class="card">
                      <section class="card_left">
                        <i class="iconfont"></i>
                      </section>
                      <section class="card_right">
                        <p class="card_title">{{$t('channel.knowledgeMsgPage.knowledgeTitle')}}</p>
                        <p class="card_tip">
                          {{$t('channel.knowledgeMsgPage.creator')}}
                          <el-divider direction="vertical"></el-divider>
                          {{$t('channel.knowledgeMsgPage.addedTime')}}
                        </p>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="message_middle">
            <template v-for="(data, welcomeIndex) in welcomeCardList">
              <div
                  class="message_list-welcome-m"
                  @click="handleClick(welcomeIndex)"
                  :key="welcomeIndex"
                  v-show="data.type == 'welcome_llm_card'"
              >
                <div class="close_icon" @click.prevent="closeCard(welcomeIndex)">
                  <i class="iconfont guoran-tongyichicun-guanbi1"></i>
                </div>
                <div class="mlwm-title" v-if="data.content.enableTitle">{{ data.content.title }}</div>
                <div class="mlwm-des" v-if="data.content.enableDescription">
                  <span class="mlwm-des-pre">{{ data.content.description }}</span>
                </div>
                <div class="mlwm-msg-type" v-if="data.content.enableRadioBtn">
                  <div
                      v-for="(item, itemIndex) in data.content.items"
                      :key="item.id"
                      :class="['mmt-cell', 'mmt-cell-' + itemIndex]"
                      @click="clickFun(item)"
                  >
                    <div class="mmt-cell-img" v-if="data.content.enableRadioIcon && item.icon">
                      <img :src="item.icon" alt srcset />
                    </div>
                    <div class="mmt-cell-des">
                      <span
                          class="mmt-cell-des-title"
                          v-if="data.content.enableRadioTitle"
                      >{{ item.title }}</span>
                      <span
                          class="mmt-cell-des-detail"
                          v-if="data.content.enableRadioDesc"
                      >{{ item.description }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                  class="message_list-welcome-m"
                  @click="handleClick(welcomeIndex)"
                  :key="welcomeIndex"
                  v-show="data.type == 'welcome_knowledge_file'"
              >
                <div class="close_icon" @click.prevent="closeCard(welcomeIndex)">
                  <i class="iconfont guoran-tongyichicun-guanbi1"></i>
                </div>
                <div class="mlwm-title" v-if="data.content.enableTitle">{{ data.content.title }}</div>
                <div class="mlwm-des" v-if="data.content.enableDescription">
                  <span class="mlwm-des-pre">{{ data.content.description }}</span>
                </div>
                <div class="mlwm-msg-type">
                  <div
                      v-for="(item, itemIndex) in fileListCount"
                      :key="item.id"
                      :class="['mmt-cell', 'mmt-cell-' + itemIndex]"
                  >
                    <div class="mmt-cell-svg">
                      <svg
                          t="1709801678586"
                          class="icon"
                          viewBox="0 0 1024 1024"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          p-id="134794"
                          width="30"
                          height="30"
                      >
                        <path
                            d="M538.731891 0h65.98683v107.168391c124.387582 0.722484 248.895579-1.324553 373.28316 0a40.699906 40.699906 0 0 1 45.034808 46.118533c2.047037 222.404516 0 444.929445 1.204139 667.454374-1.204139 24.082785 2.287865 50.694262-11.198495 72.248354-16.978363 12.041392-39.014111 10.957667-59.002822 12.041392-116.319849-0.60207-232.639699 0-349.200376 0V1023.518344h-72.248354C355.100659 990.886171 177.490122 960.662277 0 928.752587V95.488241C179.537159 63.698965 359.074318 31.30762 538.731891 0z"
                            fill="#D24625"
                            p-id="134795"
                        />
                        <path
                            d="M604.718721 142.931326H988.598307v726.216369H604.718721v-95.247413h279.239887v-47.563499H604.718721v-60.206962h279.239887v-46.96143H604.839135v-69.960489c46.118532 14.570085 98.619003 14.208843 139.800564-14.088429 44.553151-27.093133 67.793039-78.630292 71.646284-130.047036H663.119473c0-51.777987 0.60207-103.555974-0.963311-155.213547-19.145814 3.732832-38.171214 7.826905-57.196614 12.041392z"
                            fill="#FFFFFF"
                            p-id="134796"
                        />
                        <path
                            d="M686.35936 224.69238a165.689558 165.689558 0 0 1 153.16651 156.5381c-51.055503 0.60207-102.111007 0-153.286924 0 0.120414-52.380056 0.120414-104.278457 0.120414-156.5381z"
                            fill="#D24625"
                            p-id="134797"
                        />
                        <path
                            d="M186.64158 314.521167c63.21731 3.130762 139.680151-25.527752 192.662277 22.878645 50.092192 62.374412 36.84666 176.888053-37.44873 214.095955-26.370649 13.847601-56.714958 12.041392-85.373471 10.957667v139.68015l-69.238006-5.900282c-1.806209-127.157103-2.047037-254.434619-0.60207-381.712135z"
                            fill="#FFFFFF"
                            p-id="134798"
                        />
                        <path
                            d="M255.759172 378.942615c22.878645-0.963311 51.296331-5.298213 66.709313 16.737536a87.902164 87.902164 0 0 1 1.565381 78.148635c-13.245532 24.082785-43.228598 22.035748-66.468485 24.925682-2.408278-39.857008-2.167451-79.714017-1.806209-119.811853z"
                            fill="#D24625"
                            p-id="134799"
                        />
                      </svg>
                    </div>
                    <div class="mmt-cell-des">
                      <span class="mmt-cell-des-title">{{$t('channel.knowledgeMsgPage.productName')}}</span>
                      <span class="mmt-cell-des-detail">
                      <div class="mmt-cell-avatar">
                        <i class="iconfont guoran-askbotdandutubiao2"></i>
                      </div>
                      <div class="mmt-creator-name">{{$t('channel.knowledgeMsgPage.productAlias')}}</div>
                      <el-divider direction="vertical"></el-divider>
                      <div class="mmt-update-time">{{
                          new Date().Format("yyyy-MM-dd hh:mm")
                        }}</div>
                    </span>
                    </div>
                  </div>
                  <div class="load_moreKnowledge" v-if="canUseLoadMore" @click="loadMore">
                    <span>{{$t('channel.knowledgeMsgPage.viewMore')}}</span>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="message_right">
            <template v-if="welcomeCardData">
              <div class="right_config" v-if="welcomeCardData.type == 'welcome_llm_card'">
                <div class="title">{{$t('channel.knowledgeMsgPage.templateTitle')}}</div>
                <div class="config_content">
                  <p class="config_title">
                    <span>{{$t('channel.knowledgeMsgPage.title')}}</span>
                    <el-switch
                        :width="42"
                        v-model="welcomeCardData.content.enableTitle"
                        active-color="#366AFF"
                        inactive-color="#E2E2E2"
                    ></el-switch>
                  </p>
                  <el-input class="config_ipt" v-model="welcomeCardData.content.title" :placeholder="$t('common.placeholder')"></el-input>
                  <p class="config_title">
                    <span>{{$t('channel.knowledgeMsgPage.des')}}</span>
                    <el-switch
                        :width="42"
                        v-model="welcomeCardData.content.enableDescription"
                        active-color="#366AFF"
                        inactive-color="#E2E2E2"
                    ></el-switch>
                  </p>
                  <el-input v-model="welcomeCardData.content.description" :placeholder="$t('common.placeholder')"></el-input>
                  <div class="switch_view">
                    {{$t('channel.knowledgeMsgPage.btnSelect')}}
                    <el-switch
                        :width="42"
                        v-model="welcomeCardData.content.enableRadioBtn"
                        active-color="#366AFF"
                        inactive-color="#E2E2E2"
                    ></el-switch>
                  </div>
                  <div class="switch_view" v-if="welcomeCardData.content.enableRadioBtn">
                    {{$t('channel.knowledgeMsgPage.iconSelect')}}
                    <el-switch
                        :width="42"
                        v-model="welcomeCardData.content.enableRadioIcon"
                        active-color="#366AFF"
                        inactive-color="#E2E2E2"
                    ></el-switch>
                  </div>
                  <div class="switch_view" v-if="welcomeCardData.content.enableRadioBtn">
                    {{$t('channel.knowledgeMsgPage.titleSelect')}}
                    <el-switch
                        :width="42"
                        v-model="welcomeCardData.content.enableRadioTitle"
                        active-color="#366AFF"
                        inactive-color="#E2E2E2"
                    ></el-switch>
                  </div>
                  <div class="switch_view" v-if="welcomeCardData.content.enableRadioBtn">
                    {{$t('channel.knowledgeMsgPage.descSelect')}}
                    <el-switch
                        :width="42"
                        v-model="welcomeCardData.content.enableRadioDesc"
                        active-color="#366AFF"
                        inactive-color="#E2E2E2"
                    ></el-switch>
                  </div>
                  <div class="cardList" ref="cardList" v-if="welcomeCardData.content.enableRadioBtn">
                    <div
                        class="card_detail"
                        v-for="(cardItem, cardItemIndex) in welcomeCardData.content.items"
                        :key="cardItem.id"
                    >
                      <i class="iconfont guoran-a-16-10"></i>
                      <i class="iconfont guoran-a-16-09" @click="delItems(cardItemIndex)"></i>
                      <div class="upload_view">
                        <el-upload
                            action="https://jsonplaceholder.typicode.com/posts/"
                            :accept="'.png,.PNG'"
                            :before-upload="beforeUpload"
                            :show-file-list="false"
                            :http-request="((data)=>{
                                            return aliyunOssRequest(data, cardItemIndex)
                                        })"
                        >
                          <div slot="default" class="upload_icon">
                            <i class="el-icon-plus" v-if="!cardItem.icon"></i>
                            <img
                                v-else
                                class="el-upload-list__item-thumbnail"
                                :src="cardItem.icon"
                                alt
                            />
                            <i
                                v-if="cardItem.icon"
                                @click.stop="delIcon(cardItemIndex)"
                                class="iconfont guoran-a-16-09"
                            ></i>
                          </div>
                        </el-upload>
                        <div class="upload_text">
                          <p>{{$t('channel.knowledgeMsgPage.uploadImgFormat')}}</p>
                          <p>{{$t('channel.knowledgeMsgPage.imgMaxSize')}}</p>
                        </div>
                      </div>
                      <div class="card_input">
                        <p>{{$t('channel.knowledgeMsgPage.titleSelect')}}</p>
                        <el-input class="config_ipt" v-model="cardItem.title" :placeholder="$t('common.placeholder')"></el-input>
                      </div>
                      <div class="card_input">
                        <p>{{$t('channel.knowledgeMsgPage.descSelect')}}</p>
                        <el-input
                            class="config_ipt"
                            v-model="cardItem.description"
                            :placeholder="$t('common.placeholder')"
                        ></el-input>
                      </div>
                      <div class="card_input">
                        <p>{{$t('channel.knowledgeMsgPage.actionSelect')}}</p>
                        <el-select
                            style="width:100%"
                            v-model="cardItem.actionType"
                            :placeholder="$t('common.selectPlaceholder')"
                        >
                          <el-option :label="$t('channel.knowledgeMsgPage.sendContent')" value="SEND_CONTENT"></el-option>
                        </el-select>
                      </div>
                      <div class="card_input">
                        <p>
                          {{$t('channel.knowledgeMsgPage.sendContent')}}
                          <span>{{$t('channel.knowledgeMsgPage.randomSend')}}</span>
                        </p>
                        <div
                            class="card_content_ipt"
                            v-for="(val, valIndex) in cardItem.value"
                            :key="valIndex"
                        >
                          <el-input
                              class="config_ipt"
                              v-model="cardItem.value[valIndex]"
                              :placeholder="$t('common.placeholder')"
                          ></el-input>
                          <i
                              class="iconfont guoran-a-16-09"
                              @click="delCardItemValue(valIndex, cardItemIndex)"
                          ></i>
                        </div>
                        <div class="add_btn" @click="addCardItemValue(cardItemIndex)">
                          <i class="iconfont guoran-jiahaotianjia"></i>
                          <span>{{$t('channel.knowledgeMsgPage.addAction')}}</span>
                        </div>
                      </div>
                      <div class="card_input">
                        <p>
                          {{$t('channel.knowledgeMsgPage.questionRecommend')}}
                          <el-switch
                              :width="42"
                              v-model="cardItem.enableProblemRecommendation"
                              active-color="#366AFF"
                              inactive-color="#E2E2E2"
                          ></el-switch>
                        </p>
                        <div class="card_tips">
                          <span>{{$t('channel.knowledgeMsgPage.recommendTip')}}</span>
                        </div>
                      </div>
                      <template v-if="cardItem.enableProblemRecommendation">
                        <div class="card_input">
                          <p>{{$t('channel.knowledgeMsgPage.recommendDesc')}}</p>
                          <el-input
                              class="config_ipt"
                              v-model="cardItem.problemRecommendationDesc"
                              :placeholder="$t('common.placeholder')"
                          ></el-input>
                        </div>
                        <div class="card_input">
                          <p>{{$t('channel.knowledgeMsgPage.recommendedQs')}}</p>
                          <div
                              class="card_content_ipt"
                              v-for="(val, valIndex) in cardItem.problemRecommendationValue"
                              :key="valIndex"
                          >
                            <el-input
                                class="config_ipt"
                                v-model="cardItem.problemRecommendationValue[valIndex]"
                                :placeholder="$t('common.placeholder')"
                            ></el-input>
                            <i
                                class="iconfont guoran-a-16-09"
                                @click="delProblemValue(valIndex, cardItemIndex)"
                            ></i>
                          </div>
                          <div class="add_btn">
                            <i class="iconfont guoran-jiahaotianjia" @click="addProblemValue(cardItemIndex)"></i>
                            <span @click="addProblemValue(cardItemIndex)">{{$t('channel.knowledgeMsgPage.addAction')}}</span>
                          </div>
                        </div>
                        <div class="card_input">
                          <p>
                            {{$t('channel.knowledgeMsgPage.batchDisplay')}}
                            <el-switch
                                :width="42"
                                v-model="cardItem.page"
                                active-color="#366AFF"
                                inactive-color="#E2E2E2"
                            ></el-switch>
                          </p>
                          <div class="card_tips">
                            <span>{{$t('channel.knowledgeMsgPage.nextBatch')}}</span>
                          </div>
                        </div>
                        <div class="card_ipt_number" v-show="cardItem.page">
                          <span>{{$t('channel.knowledgeMsgPage.every')}}</span>
                          <el-input
                              v-model="cardItem.pageCount"
                              type="number"
                              :min="3"
                              :max="8"
                              @change="changePageCount(cardItemIndex)"
                          ></el-input>
                          <span>{{$t('channel.knowledgeMsgPage.qPerBatch')}}<span style="color:#a9b3c6;">({{$t('channel.knowledgeMsgPage.qPerBatch')}}3-8)</span></span>
                        </div>
                      </template>
                    </div>
                    <div class="card_add" @click="addCardItem">
                      <i class="el-icon-plus"></i>
                      <span>{{$t('channel.knowledgeMsgPage.addRadio')}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="right_config" v-if="welcomeCardData.type == 'welcome_knowledge_file'">
                <div class="title">{{$t('channel.knowledgeMsgPage.addCard')}}</div>
                <div class="config_content">
                  <p class="config_title">
                    <span>{{$t('channel.knowledgeMsgPage.title')}}</span>
                    <el-switch
                        :width="42"
                        v-model="welcomeCardData.content.enableTitle"
                        active-color="#366AFF"
                        inactive-color="#E2E2E2"
                    ></el-switch>
                  </p>
                  <el-input class="config_ipt" v-model="welcomeCardData.content.title" :placeholder="$t('common.placeholder')"></el-input>
                  <p class="config_title">
                    <span>{{$t('channel.knowledgeMsgPage.des')}}</span>
                    <el-switch
                        :width="42"
                        v-model="welcomeCardData.content.enableDescription"
                        active-color="#366AFF"
                        inactive-color="#E2E2E2"
                    ></el-switch>
                  </p>
                  <el-input
                      class="config_ipt"
                      v-model="welcomeCardData.content.description"
                      :placeholder="$t('common.placeholder')"
                  ></el-input>
                  <p class="config_title">
                    <span>{{$t('channel.knowledgeMsgPage.addNewKnowledge')}}</span>
                  </p>
                  <div class="ipt_number">
                    <span>{{$t('channel.knowledgeMsgPage.near')}}</span>
                    <el-input v-model="welcomeCardData.content.lastDays" type="number" :min="1"></el-input>
                    <span>{{$t('channel.knowledgeMsgPage.day')}}</span>
                  </div>
                  <p class="config_title" style="margin-top:10px">
                    <span>{{$t('channel.knowledgeMsgPage.knowledgePerPage')}}</span>
                  </p>
                  <div class="config_slider">
                    <el-slider
                        v-model="welcomeCardData.content.pageSize"
                        :step="2"
                        :min="2"
                        :max="30"
                        @change="changePageSize"
                        :marks="{
                                        2: '2',
                                        30: '30'
                                    }"
                    ></el-slider>
                  </div>
                  <p class="config_title" style="margin-top:10px">
                    <span>{{$t('channel.knowledgeMsgPage.knowledgeLimit')}}</span>
                  </p>
                  <div class="config_slider">
                    <el-slider
                        v-model="welcomeCardData.content.limitCount"
                        :step="1"
                        :min="10"
                        :max="100"
                        @change="changelimitCount"
                        :marks="{
                                        10: '10',
                                        100: '100'
                                    }"
                    ></el-slider>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <img src="../../assets/images/default.png" class="empty" alt />
              <p class="empty_tip">{{$t('channel.knowledgeMsgPage.welcomeTemplate')}}</p>
            </template>
          </div>
        </div>
      </div>
    </div>
    <SideNav ref="leftMenuObj"></SideNav>
  </div>
</template>

<script>
import switchIcon from "../../components/switch-icon.vue";
import { upload } from '../../assets/js/AliyunIssUtil'
import { v4 as uuidv4 } from "uuid";
import Sortable from "sortablejs";
import DetailPageHeader from "../../components/detail-header";
import SideNav from "../menu/SideNav";
import { requestUrl } from "../../api/requestUrl";

export default {
  name: "knowledgeWelcomeMessage",
  data() {
    return {
      headerName: "应用名称",
      switchStatus: true,
      activeName: "template",
      value: "",
      welcomeCardList: [],
      welcomeCardData: {},
      limitCount: 10,
      pageCount: 1,
      fileListCount: 3,
      totalPageNumbers: 0,
      // 用于对比欢迎语是否被修改
      welcomeCardListOrigin: []
    };
  },
  beforeRouteLeave(to,from,next){
      console.log(
        "beforeRouteLeave to: ", to 
      );
      console.log(
        "beforeRouteLeave from: ", from 
      );
      if (from.path == "/knowledgeWelcomeMessage") {
        if (JSON.stringify(this.welcomeCardList) == this.welcomeCardListOrigin) {
          next();
        } else {
          this.$confirm(this.$t('common.saveTheChangesTip'), this.$t('addMember.tip'), {
            confirmButtonText: this.$t('common.confirm'),
            cancelButtonText: this.$t('common.cancel'),
            closeOnClickModal: false,
            type: 'warning'
          }).then(() => {
            this.saveWelcome(next);
          }).catch(() => {
            next();        
          });
        }
      } else {
        next();
      }
  },
  components: {
    SideNav,
    DetailPageHeader,
    switchIcon
  },
  props:{
    welcomeMessageDate:{
      type:Object,
      default(){
        return {}
      }
    }
  },
  computed: {
    canUseLoadMore() {
      let data = this.welcomeCardList.find(item => {
        return item.type == "welcome_knowledge_file";
      });
      if (data) {
        let count = data.content.pageSize * this.pageCount;
        if (count >= data.content.limitCount) {
          this.fileListCount = data.content.limitCount;
          return false;
        } else {
          this.fileListCount = count;
          return true;
        }
      } else {
        this.fileListCount = 0;
        return false;
      }
      // let count = this.welcomeCardData.content.pageSize * this.pageCount
      // if (count >= this.welcomeCardData.content.limitCount) {
      //     this.fileListCount = this.welcomeCardData.content.limitCount
      //     return false
      // } else {
      //     this.fileListCount = count
      //     return true
      // }
    }
  },
  mounted() {
    this.getWelcome();
  },
  methods: {
    goBack() {
      console.log(625, "++++++++++++++");
      
      let type = this.$route.query.type;
      let bindCode = this.$route.query.bindCode;
      if (this.$route.query.source === 'intelligentAgentConfig'){
        this.$router.push(({
          path:"/intelligentAgentConfig",
          query:{
            id:this.$route.query.id
          }
        }))
        return
      }
      this.$router.push({
        path:"/accessDetails",
        query:{
          type,
          bindCode
        }
      })
    },
    changeswitch(value) {
      this.switchStatus = value;
    },
    handlePictureCardPreview() {

    },
    handleDownload() {

    },
    handleRemove() {

    },
    setSort() {
      const el = this.$refs.cardList;
      Sortable.create(el, {
        handle: ".guoran-a-16-10",
        draggable: ".card_detail",
        filter: ".card_add",
        animation: 200,
        // sorttable进行拖拽的时候并不改变原数组，想改变原数组就必须在拖拽完成后改变数据
        onEnd: ({ newIndex, oldIndex }) => {
          // 在拖动事件结束后把拖拽的值删掉
          const result = this.welcomeCardData.content.items.splice(oldIndex, 1);
          // 在新的位置插入
          this.welcomeCardData.content.items.splice(newIndex, 0, ...result);
        }
      });
    },
    addCard(type) {
      // let uuid =
      if (type == "dialog") {
        if (!this.welcomeCardList.some(card => {
          return card.type == "welcome_llm_card";
        })) {
          let data = {
            type: "welcome_llm_card",
            content: {
              enableRadioIcon: true,
              enableRadioBtn: true,
              enableRadioTitle: true,
              enableRadioDesc: true,
              enableTitle: true,
              enableDescription: true,
              description: this.$t('channel.knowledgeMsgPage.welcomeCardDes'),
              title: this.$t('channel.knowledgeMsgPage.welcomeCardTitle'),
              items: [
                {
                  actionType: "SEND_CONTENT",
                  icon: "https://static.guoranbot.com/cdn-office-website/askbot_doc/mmt-cell-img-m1.png",
                  description: this.$t('channel.knowledgeMsgPage.welcomeCardItem1Des'),
                  scopes: [],
                  title: this.$t('channel.knowledgeMsgPage.welcomeCardItem1Title'),
                  value: [this.$t('channel.knowledgeMsgPage.welcomeCardItem1Des')],
                  id: uuidv4(),
                  enableProblemRecommendation: false,
                  problemRecommendationDesc: this.$t('channel.knowledgeMsgPage.problemRecommendationDesc'),
                  problemRecommendationValue: [""],
                  page: false,
                  pageCount: 3
                },
                {
                  actionType: "SEND_CONTENT",
                  icon: "https://static.guoranbot.com/cdn-office-website/askbot_doc/mmt-cell-img-m2.png",
                  description: this.$t('channel.knowledgeMsgPage.welcomeCardItem2Des'),
                  scopes: [],
                  title: this.$t('channel.knowledgeMsgPage.welcomeCardItem2Title'),
                  value: [this.$t('channel.knowledgeMsgPage.welcomeCardItem2Des')],
                  id: uuidv4(),
                  enableProblemRecommendation: false,
                  problemRecommendationDesc: this.$t('channel.knowledgeMsgPage.problemRecommendationDesc'),
                  problemRecommendationValue: [""],
                  page: false,
                  pageCount: 3
                },
                {
                  actionType: "SEND_CONTENT",
                  icon: "https://static.guoranbot.com/cdn-office-website/askbot_doc/mmt-cell-img-m3.png",
                  description: this.$t('channel.knowledgeMsgPage.welcomeCardItem3Des'),
                  scopes: [],
                  title: this.$t('channel.knowledgeMsgPage.welcomeCardItem3Title'),
                  value: [this.$t('channel.knowledgeMsgPage.welcomeCardItem3Des')],
                  id: uuidv4(),
                  enableProblemRecommendation: false,
                  problemRecommendationDesc: this.$t('channel.knowledgeMsgPage.problemRecommendationDesc'),
                  problemRecommendationValue: [""],
                  page: false,
                  pageCount: 3
                },
                {
                  actionType: "SEND_CONTENT",
                  icon: "https://static.guoranbot.com/cdn-office-website/askbot_doc/mmt-cell-img-m4.png",
                  description: this.$t('channel.knowledgeMsgPage.welcomeCardItem4Des'),
                  scopes: [],
                  title: this.$t('channel.knowledgeMsgPage.welcomeCardItem4Title'),
                  value: [this.$t('channel.knowledgeMsgPage.welcomeCardItem4Des')],
                  id: uuidv4(),
                  enableProblemRecommendation: false,
                  problemRecommendationDesc: this.$t('channel.knowledgeMsgPage.problemRecommendationDesc'),
                  problemRecommendationValue: [""],
                  page: false,
                  pageCount: 3
                }
              ]
            },
            apiKey: null
          };
          this.welcomeCardList.push(data);
          this.$nextTick(() => {
            this.$set(this, "welcomeCardData", data);
            this.setSort();
          });
        } else {
          this.$message.warning(this.$t('channel.knowledgeMsgPage.addTemplateMsg'));
        }
      }
      if (type == "knowledge") {
        if (!this.welcomeCardList.some(card => {
          return card.type == "welcome_knowledge_file";
        })) {
          let data = {
            type: "welcome_knowledge_file",
            content: {
              "enableDescription": true,
              "enableTitle": true,
              "lastDays": 7,
              "limitCount": 20,
              "pageSize": 6,
              "description": this.$t('channel.knowledgeMsgPage.welcomeMsgFileDes'),
              "title": this.$t('channel.knowledgeMsgPage.welcomeMsgTitle')
            },
            apiKey: null
          };
          this.$set(this, "welcomeCardData", data);
          this.welcomeCardList.push(data);
          this.changePageSize(5);
          this.changelimitCount(20);
        } else {
          this.$message.warning(this.$t('channel.knowledgeMsgPage.addTemplateMsg'));
        }
      }
    },
    selectData(data) {

    },
    clickFun() {

    },
    delItems(index) {
      this.welcomeCardData.content.items.splice(index, 1);
    },
    addCardItemValue(index) {
      this.welcomeCardData.content.items[index].value.push("");
    },
    addCardItem() {
      this.welcomeCardData.content.items.push({
        actionType: "SEND_CONTENT",
        icon: "",
        description: "",
        scopes: [],
        title: "",
        value: [""],
        id: uuidv4(),
        enableProblemRecommendation: false,
        problemRecommendationDesc: this.$t('channel.knowledgeMsgPage.problemRecommendationDesc'),
        problemRecommendationValue: [""],
        page: false,
        pageCount: 3
      });
    },
    delCardItemValue(index, cardIndex) {
      if (this.welcomeCardData.content.items[cardIndex].value.length != 1) {
        this.welcomeCardData.content.items[cardIndex].value.splice(index, 1);
      }
    },
    addProblemValue(index) {
      this.welcomeCardData.content.items[index].problemRecommendationValue.push("");
    },
    delProblemValue(index, cardIndex) {
      if (this.welcomeCardData.content.items[cardIndex].problemRecommendationValue.length != 1) {
        this.welcomeCardData.content.items[cardIndex].problemRecommendationValue.splice(index, 1);
      }
    },
    changePageCount(index) {
      let pageCount = this.welcomeCardData.content.items[index].pageCount;
      this.welcomeCardData.content.items[index].pageCount = pageCount < 3 ? 3 : (pageCount > 8 ? 8 : pageCount);
    },
    beforeUpload(file) {
      const isJPG = file.type == "image/png";
      if (!isJPG) {
        this.$message({
          message: this.$t('channel.knowledgeMsgPage.uploadType'),
          type: "warning",
          duration: 2000
        });
        return false;
      }
      const isSize = new Promise(function (resolve, reject) {
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = function () {
          console.log(img.width, img.height, "img.height");
          img.width > 256 || img.height > 256 ? reject() : resolve();
        };
        img.src = _URL.createObjectURL(file);
      }).then(() => {
        return file;
      }, () => {
        this.$message({
          message: this.$t('channel.knowledgeMsgPage.uploadSize'),
          type: "warning",
          duration: 2000
        });
        return Promise.reject();
      });
      return isJPG && isSize;

    },
    aliyunOssRequest(data, cardItemIndex) {
      let file = data.file;
      let res = upload({
        region: "oss-cn-zhangjiakou",
        //云账号AccessKey有所有API访问权限，建议遵循阿里云安全最佳实践，创建并使用STS方式来进行API访问
        accessKeyId: "LTAI4G3QtdEdwkEbihBngAsK",
        accessKeySecret: "OwgdVfc5PeCkIgqIdug660xmiSPchn",
        // stsToken: '<Your securityToken(STS)>',
        bucket: "guoranopen-zjk"
      }, file);
      console.debug("oss upload res", data, res);
      res.then(reslout => {
        this.welcomeCardData.content.items[cardItemIndex].icon = reslout.url;
      });
      // return res;
    },
    delIcon(cardIndex) {
      if (this.welcomeCardData.content.items[cardIndex].icon) {
        this.welcomeCardData.content.items[cardIndex].icon = null;
      }
    },
    changePageSize(value) {
      //  this.limitCount %  (value * pageCount)
      this.pageCount = 1;
      // this.fileListCount = value * this.pageCount
      // this.totalPageNumbers = Math.floor(this.welcomeCardData.content.limitCount / value)
    },
    changelimitCount(value) {
      this.limitCount = value;
    },
    loadMore() {
      this.pageCount = this.pageCount + 1;
    },
    saveWelcome(callback) {
      let dataList = JSON.parse(JSON.stringify(this.welcomeCardList));
      dataList.forEach(data => {
        if (data.type == "welcome_llm_card") {
          data.content.items.forEach(item => {
            delete item.id;
          });
        }
      });
      this.$set(this, "welcomeCardListOrigin", JSON.stringify(this.welcomeCardList));
      let params = {
        bindCode: this.$route.query.bindCode,
        bindSource: 1,
        unifyGreetingType: "KNOWLEDGE",
        unifySetStartNodeActions: dataList
      };
      this.$http.post("/api-open/channel/access/unifySetGreeting", params).then(res => {
        if (res.data.code == 0) {
          this.$message.success(this.$t('channel.knowledgeMsgPage.saveSuccess'));
          // 保存当前已修改并且跳转到目标路由
          setTimeout(() => {
            if (typeof callback === 'function') {
              callback();
            } else {
              this.getWelcome();
            }
          }, 200);
        }
      });
    },
    getWelcome() {
      // let url = "/api-open/channel/access/selectUnifySetGreeting?bindCode=" + this.$route.query.bindCode

      let url = requestUrl.intelligentAgent.getWelcomeConfig + '?bindCode=' + this.$route.query.bindCode;
      this.$http.get(url).then(res => {
        console.log('getWelcome',res)
        if (res.data.code == 0 && res.data.data) {
          this.welcomeCardList = [];
          let arr = [];
          if (res.data.data.setting.unifySetStartNodeActions && res.data.data.setting.unifySetStartNodeActions.length != 0) {
            res.data.data.setting.unifySetStartNodeActions.forEach(item => {
              this.oldDataCompatibility(item);
              if (item.type == "welcome_llm_card" || item.type == "welcome_knowledge_file") {
                arr.push(item);
              }
            });
          }
          this.$set(this, "welcomeCardList", arr);
          if (this.welcomeCardList.length != 0) {
            this.$set(this, "welcomeCardData", this.welcomeCardList[0]);
            if (this.welcomeCardData.type == "welcome_llm_card") {
              this.welcomeCardData.content.items.forEach(item => {
                item.id = uuidv4();
              });
            }
          }
          this.$set(this, "welcomeCardListOrigin", JSON.stringify(arr));
        }
      });
    },
    oldDataCompatibility(item) {
      console.log(item, "itemsitems");
      if (item.type == "welcome_llm_card") {
        item.content.items.forEach(element => {
          if (element.enableProblemRecommendation === undefined) {
            element.enableProblemRecommendation = false;
            element.problemRecommendationDesc = this.$t('channel.knowledgeMsgPage.problemRecommendationDesc'),
                element.problemRecommendationValue = [""];
            element.page = false;
            element.pageCount = 3;
          }
        });
      }
    },
    checkWelcome() {
      let type = this.$route.query.type;
      let bindCode = this.$route.query.bindCode;
      this.$router.push({
        path:"/intentWelcomeMessage",
        query:{
          type,
          bindCode,
          agentName:this.$route.query.agentName,
          wechatType:this.$route.query.wechatType,
          source:this.$route.query.source,
          id:this.$route.query.id
        }
      })
    },
    closeCard(index) {
      this.welcomeCardList.splice(index, 1);
      if (this.welcomeCardList.length != 0) {
        this.$set(this, "welcomeCardData", this.welcomeCardList[0]);
      } else {
        this.welcomeCardData = null;
      }
    },
    handleClick(index) {
      this.$set(this, "welcomeCardData", this.welcomeCardList[index]);
    }
  }
};
</script>

<style lang="less" scoped>
.knowle_welcome_message {
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  .gr-content-container {
    position: absolute;
    left: 64px;
    width: calc(100% - 96px);
    height: calc(100% - 24px);
    padding: 12px 16px;
    min-width: 1000px;
    overflow-x: auto;
    text-align: left;
    .content-header-left-box{
      color: #366aff;
      cursor:pointer;
    }
    .header-title-c-r-btn{
      display: flex;
      align-items: center;
      margin-right: 20px;
      .save-btn {
        padding: 0 14px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        background: #366aff;
        border-radius: 32px;
        cursor: pointer;
        i {
          margin-right: 8px;
        }
      }
    }
    .welcome_message_content {
      width: 100%;
      height: calc(100% - 76px);
      overflow: hidden;
      border-radius: 8px;
      margin-top: 14px;
      .messgae_header {
        width: 100%;
        height: 60px;
        background: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;
        box-sizing: border-box;

        .head_left {
          display: flex;
          align-items: center;

          .text {
            font-size: 14px;
            margin-right: 8px;
          }

          /deep/ .switch {
            .switch-close-icon {
              left: 55% !important;
            }
          }
        }

        .head_right {
          .save_btn {
            width: 80px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            background: #366aff;
            border-radius: 32px;
            cursor: pointer;

            i {
              margin-right: 8px;
            }
          }
        }
      }

      .message_content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;

        .message_left {
          width: 350px;
          height: 100%;
          flex: none;
          background: #ffffff;
          border-top: 1px solid #ddd;

          .tab {
            width: 100%;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #ddd;
            color: #000;
            font-weight: 500;
            box-sizing: border-box;
          }

          .template_list {
            width: 100%;
            height: calc(100% - 50px);
            overflow: hidden;

            .template_view {
              width: 100%;
              margin-bottom: 12px;
              padding: 12px;
              box-sizing: border-box;
              text-align: left;

              .template_title {
                line-height: 28px;
                color: #999;
                text-indent: 5px;
              }

              .template_content {
                width: 100%;
                // height: 165px;
                border-radius: 10px;
                background: #ffffff;
                cursor: pointer;
                padding: 14px;
                padding-bottom: 9px;
                box-sizing: border-box;
                box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
                border: 1px solid #eee;

                .title {
                  font-size: 18px;
                  font-weight: 600;
                  color: #333333;
                }

                .tip {
                  color: #666666;
                  margin-top: 9px;
                  font-size: 14px;
                  font-weight: 500;
                }

                .template_card {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  flex-wrap: wrap;
                  margin-top: 5px;

                  .card {
                    width: 49%;
                    //height: 56px;
                    margin-bottom: 8px;
                    background: #f0f4ff;
                    border-radius: 5px;
                    display: flex;
                    // justify-content: space-between;
                    align-items: center;
                    padding: 8px 0;
                    .card_left {
                      margin: 0 8px;

                      i {
                        width: 22px;
                        height: 22px;
                        border-radius: 22px;
                        background: #999;
                        display: block;
                      }
                    }

                    .card_right {
                      width: calc(100% - 38px);
                      text-align: left;

                      .card_title {
                        font-size: 14px;
                        color: #000000;
                        font-weight: 600;
                        margin-bottom: 6px;
                      }

                      .card_tip {
                        font-size: 12px;

                        .el-divider {
                          margin: 0 5px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .message_right {
          width: 350px;
          height: 100%;
          flex: none;
          background: #ffffff;
          text-align: center;
          .right_config {
            width: 100%;
            height: 100%;

            .title {
              width: 100%;
              height: 48px;
              background: #ffffff;
              font-size: 14px;
              font-weight: 400;
              color: #000;
              line-height: 38px;
              margin-bottom: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-bottom: 1px solid #ddd;
              border-top: 1px solid #ddd;
            }

            .config_content {
              width: 100%;
              height: calc(100% - 59px);
              overflow: hidden;
              overflow-y: auto;
              padding: 0 12px;
              padding-bottom: 50px;
              box-sizing: border-box;

              .config_title {
                margin-bottom: 10px;
                color: #616161;
                display: flex;
                justify-content: space-between;
                align-items: center;
                line-height: 28px;
              }

              > .config_ipt {
                margin-bottom: 10px;
              }

              .switch_view {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #616161;
                height: 38px;
                background: #fbfcfd;
                line-height: 38px;
                margin: 12px 0;
                box-sizing: border-box;
              }

              .card_detail {
                width: 100%;
                padding: 12px;
                box-sizing: border-box;
                position: relative;
                background: #f0f4ff;
                border-radius: 8px;
                margin: 10px 0;

                .iconfont {
                  font-size: 16px;
                  color: #366aff;
                }

                > .guoran-a-16-10 {
                  position: absolute;
                  top: -7px;
                  right: 18px;
                  cursor: move;
                }

                > .guoran-a-16-09 {
                  position: absolute;
                  top: -7px;
                  right: -7px;
                }

                /deep/ .upload_view {
                  display: flex;
                  align-items: center;

                  .el-upload-list {
                    display: none;
                  }

                  .upload_text {
                    text-align: left;
                    margin-left: 12px;
                    color: #616161;

                    p {
                      line-height: 28px;
                    }
                  }

                  .upload_icon {
                    width: 70px;
                    height: 70px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px dashed #d9d9d9;
                    border-radius: 8px;
                    position: relative;

                    img {
                      width: 70px;
                      height: 70px;
                      border-radius: 8px;
                      object-fit: cover;
                    }

                    .iconfont {
                      position: absolute;
                      color: #366aff;
                      right: -7px;
                      top: -7px;
                      cursor: pointer;
                    }
                  }

                  .upload_icon:hover {
                    border: 1px dashed #366aff;
                  }
                }

                .card_input {
                  margin: 10px 0;

                  p {
                    line-height: 28px;
                    color: #616161;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    span {
                      color: #a9b3c6;
                      text-align: left;
                    }
                  }

                  .card_content_ipt {
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    i {
                      font-size: 16px;
                      color: #366aff;
                      margin: 0 8px;
                    }
                  }

                  .add_btn {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    color: #366aff;

                    i {
                      margin-right: 12px;
                      font-size: 28px;
                      cursor: pointer;
                    }

                    span {
                      cursor: pointer;
                    }
                  }
                }

                .card_tips {
                  color: #a9b3c6;
                  text-align: left;
                }

                .card_ipt_number {
                  /deep/ .el-input {
                    .el-input__inner {
                      padding: 0 10px;
                    }
                  }

                  display: flex;
                  align-items: center;
                  font-size: 14px;
                  margin: 10px 0;
                  height: 38px;
                  line-height: 38px;
                  border-radius: 5px;

                  span {
                    white-space: nowrap;
                  }

                  .el-input {
                    width: 80px;
                    margin: 0 10px;
                  }
                }
              }

              .card_add {
                width: 100%;
                height: 40px;
                cursor: pointer;
                background: #f0f4ff;
                border-radius: 8px;
                margin-top: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #366aff;
                font-weight: 500;

                i {
                  margin: 8px;
                }
              }

              .ipt_number {
                // width: 100%;
                // height: 38px;
                // border-radius: 4px;
                // border: 1px solid #dcdfe6;
                // display: flex;
                // align-items: center;
                // justify-content: space-between;
                // padding: 0 8px;
                // box-sizing: border-box;
                /deep/ .number {
                  width: 100%;

                  .el-input__inner {
                    border: none;
                  }
                }

                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                margin: 20px 0;
                height: 58px;
                line-height: 58px;
                background: #fbfcfd;
                border-radius: 5px;

                span {
                  white-space: nowrap;
                }

                .el-input {
                  width: 194px;
                  margin: 0 10px;
                }
              }

              .config_slider {
                width: 100%;
                box-sizing: border-box;
                padding-left: 10px;
              }
            }
          }

          .empty {
            margin-top: 100px;
          }

          .empty_tip {
            color: #999;
          }
        }

        .message_middle {
          width: calc(100% - 700px);
          overflow: hidden;
          overflow-y: auto;

          .message_list-welcome-m {
            min-width: 600px;
            max-width: 1000px;
            width: calc(100% - 24px);
            height: auto;
            background: #ffffff;
            padding: 12px 16px 12px;
            box-sizing: border-box;
            text-align: left;
            margin: 20px auto 0;
            border-radius: 8px;
            position: relative;
            cursor: pointer;

            .close_icon {
              position: absolute;
              right: -10px;
              top: -10px;
              width: 23px;
              height: 23px;
              background: #ffffff;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              i {
                font-size: 10px;
              }
            }

            .mlwm-title {
              font-size: 18px;
              font-weight: 600;
              color: #333333;
              word-wrap: anywhere;
              word-break: break-all;
            }

            .mlwm-des {
              color: #666666;
              margin-top: 9px;
              font-size: 14px;
              font-weight: 500;
              word-wrap: anywhere;
              word-break: break-all;

              .mlwm-des-after {
                display: flex;
                align-items: center;
                height: 28px;
                line-height: 28px;

                .mlwm-des-after-cir {
                  flex: none;
                  width: 6px;
                  height: 6px;
                  background: #366aff;
                  border-radius: 50%;
                  margin-right: 6px;
                }
              }
            }

            .mlwm-msg-type {
              margin-top: 9px;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;

              .mmt-cell {
                // flex: 0.5;
                width: 49%;
                min-width: 280px;
                padding: 10px;
                background: #f0f4ff;
                border-radius: 8px;
                margin: 8px 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                box-sizing: border-box;

                .mmt-cell-img {
                  flex: none;
                  width: 46px;
                  margin-right: 8px;

                  img {
                    height: 46px;
                    width: 46px;
                  }
                }

                .mmt-cell-svg {
                  flex: none;
                  width: 30px;
                  margin-right: 14px;
                }

                .mmt-cell-des {
                  flex: auto;
                  text-align: left;
                  display: flex;
                  flex-direction: column;
                  padding-right: 12px;

                  .mmt-cell-des-title {
                    font-size: 14px;
                    color: #000000;
                    font-weight: 600;
                    margin-bottom: 6px;
                    word-wrap: anywhere;
                    word-break: break-all;
                  }

                  .mmt-cell-des-detail {
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    word-wrap: anywhere;
                    word-break: break-all;

                    .mmt-cell-avatar {
                      flex: none;
                      width: 20px;
                      margin-right: 8px;
                      display: flex;
                      align-items: center;

                      img {
                        height: 20px;
                        width: 20px;
                        border-radius: 50%;
                      }

                      i {
                        font-size: 20px;
                        color: #366aff;
                      }
                    }

                    .mmt-creator-name {
                      padding-right: 6px;
                      color: #666666;
                    }

                    .mmt-update-time {
                      color: #666666;
                      padding-left: 6px;
                    }
                  }
                }
              }

              .load_moreKnowledge {
                width: 100%;
                padding: 10px;
                background: #f0f4ff;
                border-radius: 8px;
                margin: 8px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                box-sizing: border-box;
              }
            }
          }

          .message_list_welcome_knowledge_file {
            min-width: 600px;
            max-width: 1000px;
            width: calc(100% - 24px);
            height: auto;
            background: #ffffff;
            padding: 12px 16px 12px;
            box-sizing: border-box;
            text-align: left;
            margin: 20px auto 0;
            border-radius: 8px;

            .mlwm-title {
              font-size: 18px;
              font-weight: 600;
              color: #333333;
              word-wrap: anywhere;
              word-break: break-all;
            }

            .mlwm-des {
              color: #666666;
              margin-top: 9px;
              font-size: 14px;
              font-weight: 500;
              word-wrap: anywhere;
              word-break: break-all;

              .mlwm-des-after {
                display: flex;
                align-items: center;
                height: 28px;
                line-height: 28px;

                .mlwm-des-after-cir {
                  flex: none;
                  width: 6px;
                  height: 6px;
                  background: #366aff;
                  border-radius: 50%;
                  margin-right: 6px;
                }
              }
            }

            .mlwm-msg-type {
              margin-top: 9px;

              .mmt-cell {
                width: 49%;
                min-width: 280px;
                padding: 10px;
                background: #f0f4ff;
                border-radius: 8px;
                margin: 8px 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;

                .mmt-cell-img {
                  flex: none;
                  width: 20px;
                  margin-right: 8px;

                  img {
                    height: 20px;
                    width: 20px;
                  }
                }

                .mmt-cell-des {
                  flex: auto;
                  text-align: left;
                  display: flex;
                  flex-direction: column;
                  padding-right: 12px;

                  .mmt-cell-des-title {
                    font-size: 14px;
                    color: #000000;
                    font-weight: 600;
                    margin-bottom: 6px;
                    word-wrap: anywhere;
                    word-break: break-all;
                  }

                  .mmt-cell-des-detail {
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    word-wrap: anywhere;
                    word-break: break-all;

                    .mmt-cell-avatar {
                      flex: none;
                      width: 16px;
                      margin-right: 8px;
                      display: flex;
                      align-items: center;

                      img {
                        height: 16px;
                        width: 16px;
                        border-radius: 50%;
                      }
                    }

                    .mmt-creator-name {
                      padding-right: 6px;
                      color: #666666;
                    }

                    .mmt-update-time {
                      color: #666666;
                      padding-left: 6px;
                    }

                    .el-divider {
                      margin: 0 5px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  /deep/ .tips {
    color: #366aff;
    font-size: 12px;
    cursor: pointer;
  }
}
</style>