<template>
  <div class="intentWelcomeMessage">
    <div class="gr-content-container">
      <div class="personalHeader">
        <detail-page-header @goBack="goBack">
          <div slot="header-name" class="header-name">
            <span class="template-name">
              {{$t('channel.welcomeMsg')}}
            </span>
          </div>
          <div slot="header-des" class="check_welcome_type" @click="checkWelcomeType">
            {{$t('flowComponent.changeMode')}}
          </div>
          <div slot="header-title-c-r-btn" class="header-title-c-r-btn">
            <div class="save-btn" @click="saveWelcomeMessage">
              <i class="iconfont guoran-tongyichicun-16-03-baocun"></i>
              {{$t('common.save')}}
            </div>
          </div>
        </detail-page-header>
      </div>
      <div class="container">
        <flow-component
            ref="ChatFlowComponent"
            :isPart="0"
            :intentIds="['','']"
            :drawerSize="1000"
            :isBotLink="true"
            @saveWelcomeMessage="saveWelcomeMessage"
        ></flow-component>
      </div>
    </div>
    <SideNav ref="leftMenuObj"></SideNav>
  </div>
</template>

<script>
import SideNav from "../menu/SideNav";
import DetailPageHeader from "../../components/detail-header";
import FlowComponent from "../ChatFlow/FlowComponent";
import { requestUrl } from "../../api/requestUrl";
export default {
  name: "intentWelcomeMessage",
  components: { FlowComponent, DetailPageHeader, SideNav },
  data(){
    return{

    }
  },
  beforeRouteLeave(to,from,next){
      console.log(
        "beforeRouteLeave to: ", to 
      );
      console.log(
        "beforeRouteLeave from: ", from 
      );
      if (from.path == "/intentWelcomeMessage") {
        if (JSON.stringify(this.$refs.ChatFlowComponent.actionList) == JSON.stringify(this.$refs.ChatFlowComponent.actionListCopy)) {
          next();
        } else {
          this.$confirm(this.$t('common.saveTheChangesTip'), this.$t('addMember.tip'), {
            confirmButtonText: this.$t('common.confirm'),
            cancelButtonText: this.$t('common.cancel'),
            closeOnClickModal: false,
            type: 'warning'
          }).then(() => {
            this.saveWelcomeMessage(next);
          }).catch(() => {
            next();        
          });
        }
      } else {
        next();
      }
      
  },
  methods:{
    goBack() {
      console.log(52, "++++++++++++++");
      let type = this.$route.query.type;
      let bindCode = this.$route.query.bindCode;
      if (this.$route.query.source === 'intelligentAgentConfig'){
        this.$router.push(({
          path:"/intelligentAgentConfig",
          query:{
            id:this.$route.query.id
          }
        }))
        return
      }
      this.$router.push({
        path:"/accessDetails",
        query:{
          type,
          bindCode
        }
      })
    },
    checkWelcomeType(){
      let type = this.$route.query.type;
      let bindCode = this.$route.query.bindCode;
      this.$router.push({
        path:"/knowledgeWelcomeMessage",
        query:{
          type,
          bindCode,
          agentName:this.$route.query.agentName,
          wechatType:this.$route.query.wechatType,
          source:this.$route.query.source,
          id:this.$route.query.id
        }
      })
    },
    // 保存欢迎语
    saveWelcomeMessage(callback){
      let data = this.$refs.ChatFlowComponent.actionList;
      this.$http.post(this.requestUrl.bindV2.unifySetGreeting,{
        bindCode:this.$route.query.bindCode,
        bindSource: 1,
        unifySetStartNodeActions:data
      }).then((res) => {
        if (res.data.code === "0") {
          this.$message.success(this.$t('channel.saveSuccess'));
          this.$refs.ChatFlowComponent.actionListCopy = JSON.parse(JSON.stringify( this.$refs.ChatFlowComponent.actionList));
          // 保存当前已修改并且跳转到目标路由
          setTimeout(() => {
            if (typeof callback === 'function') {
              callback();
            }
          }, 200);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    getInfoByBindCode(){
      let bindCode = this.$route.query.bindCode;
      // let url = this.requestUrl.bindV2.getInfoByBindCode + '?bindCode=' + bindCode;
      let url = requestUrl.intelligentAgent.getWelcomeConfig + '?bindCode=' + bindCode
      this.$http.get(url).then(res => {
        console.log('getInfoByBindCode',res)
        if (res.data.code == 0 && res.data.data){
          this.setAskbotWelcomeMessage(res.data.data)

        }
      })
    },
    getIntelligentAgentDetail(){
      let id = this.$route.query.id;
      let url = requestUrl.intelligentAgent.getDetail + id;
      this.$http.get(url).then(res => {
        if (res.data.code == 0){
          let intelligentAgentDetail = res.data.data;
          if (!intelligentAgentDetail.bindBot){
            let obj = {
              botInfos:[],
              agentBindBotInfos:[]
            }
            this.$set(intelligentAgentDetail,'bindBot',obj);
          } else {
            intelligentAgentDetail.bindBot.botInfos = intelligentAgentDetail.bindBot.agentBindBotInfos
          }
          let botIdList = []
          intelligentAgentDetail.bindBot.botInfos.forEach(v => {
            botIdList.push(v.botId)
          })
          this.$refs.ChatFlowComponent.getIntentListByBotId('',{},botIdList);
        }
      })
    },
    setAskbotWelcomeMessage(data) {
      let botIdList = [];
      console.log('data',data,data.commonEntry)
      if(!(this.$route.query.type === 'dingding' && data.commonEntry == 1)){
        data.bindBot && data.bindBot.botInfos.forEach(v => {
          botIdList.push(v.botId)
        })
        this.$refs.ChatFlowComponent.getAllEntityByBot(); // 获取实体
        this.$refs.ChatFlowComponent.getWebhookBaseList(); //  获取webhookbaselist
        this.$refs.ChatFlowComponent.drawer = true;
        this.$refs.ChatFlowComponent.nodeId = "start";
        this.$refs.ChatFlowComponent.intentId = 0;
        this.$refs.ChatFlowComponent.activeNodeName = "开始";
        this.$refs.ChatFlowComponent.skillId = 0;
        this.$refs.ChatFlowComponent.isStartOrEnd = true;

        this.$http.get(this.requestUrl.bindV2.selectUnifySetGreeting + '?bindCode=' + data.bindCode).then((res) => {
          if (res.data.code === "0") {
            let tampActions = res.data.data && res.data.data.setting && res.data.data.setting.unifySetStartNodeActions ? res.data.data.setting.unifySetStartNodeActions : [];
            tampActions && tampActions.forEach((action, index) => {
              if (!("effectiveTime" in action) || action.effectiveTime == null) {
                action.effectiveTime = {
                  range: "PERMANENT",
                  startTime: "",
                  endTime: "",
                  repeat: ["1", "2","3","4","5","6", "7",],
                  filterHolidays: true,
                  balanceHolidays: true,
                  timeRange: [
                    new Date(),
                    new Date(
                        "Thu Dec 31 2100 23:59:59 GMT+0800 (中国标准时间)"
                    ),
                  ],
                };
              }
            });
            tampActions = tampActions.filter(action =>{
              return action.type != 'welcome_knowledge_file' && action.type != 'welcome_llm_card'
            })
            this.$refs.ChatFlowComponent.actionList = tampActions;
            this.$refs.ChatFlowComponent.actionListCopy = JSON.parse(JSON.stringify(tampActions));
            this.$refs.ChatFlowComponent.rowDrop();
          } else {
            this.$message.error(res.data.message);
          }
        }).catch((err) => {
          this.$message.error(this.$t('channel.welcomeMsgError'));
        });
      } else {
        this.showMnDdLimitTip();
      }
    },
    showMnDdLimitTip(){
      this.$message.warning(this.$t('channel.connection'));
    },
    saveWelcome(){

    }
  },
  mounted() {
    // let bindDetail = sessionStorage.getItem('bindDetail') ? JSON.parse(sessionStorage.getItem('bindDetail')) : {};
    // console.log('bindDetail',bindDetail)
    // this.setAskbotWelcomeMessage(bindDetail);
    this.getInfoByBindCode();
    this.getIntelligentAgentDetail()
  }
};
</script>

<style scoped lang="less">
.intentWelcomeMessage{
  .gr-content-container {
    position: absolute;
    left: 64px;
    width: calc(100% - 96px);
    height: calc(100% - 24px);
    padding: 12px 16px;
    min-width: 1000px;
    overflow-x: auto;
    text-align: left;
    .header-title-c-r-btn{
      display: flex;
      align-items: center;
      margin-right: 20px;
      .save-btn {
        padding: 0 14px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        background: #366aff;
        border-radius: 32px;
        cursor: pointer;
        i {
          margin-right: 8px;
        }
      }
    }
    .container{
      height: calc(100% - 82px);
      margin-top: 20px;
    }
    .check_welcome_type{
      color: #366aff;
      cursor:pointer;
      font-size: 14px;
      margin-left: 14px;
    }
  }
}
</style>