<template>
    <div id="accountManageCustom">
        <div class="gr-content-container">
            <div class="personalHeader">
                <top-header>
                    <div slot="setup-header-left" class="setup-header-left">
                        <span class="setup-header-left-icon">
                            <i class="iconfont guoran-a-14-03"></i
                        ></span>
                        <span>{{$t('common.enterpriseEdition')}} - </span>
                        <span>{{$t('accountManage.accountEdition')}}</span>
                    </div>
                    <div
                        class="setup-header-right-l"
                        slot="setup-header-right-l"
                    >
                        <el-input
                            size="small"
                            class="search-account"
                            style="width: 180px;display: inline-block;margin-right: 10px;"
                            :placeholder="$t('accountManage.searchAccount')"
                            prefix-icon="el-icon-search"
                            @change="searchAccount"
                            @input="changeSearchAccount"
                            v-model="inputSearchKey">
                        </el-input>
                        <el-button size="small" round @click="goAccountTemplate">
                            <i class="iconfont guoran-a-14-05"></i>
                            {{$t('accountManage.accountTemplate')}}
                        </el-button>
                        <el-button size="small" round @click="clickSet">
                            <i class="iconfont guoran-tongyichicun-16-14-shezhiliebiao"></i>
                            {{$t('accountManage.setUp')}}
                        </el-button>
                        <el-button size="small" round @click="clickClassHandle">
                            <span
                                v-if="
                                    operableAccount(
                                        'PORTAL_MANAGEMENT_GROUP_UPDATE'
                                    )
                                "
                            >
                                <i
                                    class="
                                        iconfont
                                        guoran-tongyichicun-shezhiquanxian
                                    "
                                ></i>
                                {{$t('accountManage.accountGroupManagement')}}</span
                            >
                            <span v-else>
                                <i class="iconfont guoran-a-16-04"></i
                                >{{$t('accountManage.viewAccountGroup')}}</span
                            >
                        </el-button>
                        <el-button
                            size="small"
                            type="primary"
                            @click="addAccount"
                            v-if="
                                operableAccount('PORTAL_MANAGEMENT_ACCOUNT_ADD')
                            "
                        >
                            <i class="iconfont guoran-a-16-13"></i>
                            {{$t('accountManage.addAccount')}}</el-button
                        >
                    </div>
                </top-header>
            </div>
            <div class="content">
                <div class="manage-nav-section">
                    <ManangeNav></ManangeNav>
                </div>
                <div class="content-section">
                    <div v-loading="loading" class="content-box">
                        <el-table
                            ref="accountTable"
                            :data="accountList"
                            style="width: 100%"
                            height="calc(100vh - 160px)"
                            max-height="100%">
                            <template slot="empty">
                                <DefaultImage></DefaultImage>
                            </template>
                            <!-- 新版本 -->
                            <template v-for="item in customTableHeader">
                                <div  :key="item.prop">
                                    <template v-if="item.fieldType !== ''">
                                        <el-table-column v-if="item.fieldType === 'EMAIL'" fixed="left" class-name="account-name" :label="item.name" align="left" width="290">
                                            <template slot-scope="scope">
                                                <span>{{ scope.row.account }}</span>
                                                <div class="ac-type--icon">
                                                    <el-tooltip
                                                        :content="$t('accountManage.dingDing')"
                                                        placement="bottom"
                                                        effect="light">
                                                        <el-image v-if="scope.row.dingTalkLoginInfoUserId"
                                                            style="height: 20px"
                                                            fit="contain"
                                                            src="https://static.guoranbot.com/cdn-office-website/images/ac_type_dingding.png"
                                                            alt=""
                                                            srcset=""
                                                        ></el-image>
                                                    </el-tooltip>
                                                    <el-tooltip
                                                        :content="$t('accountManage.feiShu')"
                                                        placement="bottom"
                                                        effect="light">
                                                        <el-image
                                                            v-if="scope.row.feishuLoginInfoCorpId !=''&&scope.row.feishuLoginInfoCorpId!=null&&scope.row.feishuLoginInfoOpenId!=''&&scope.row.feishuLoginInfoOpenId!=null"
                                                            style="height: 19px"
                                                            fit="contain"
                                                            src="https://static.guoranbot.com/cdn-office-website/images/ac_type_feishu.png"
                                                            alt=""
                                                            srcset=""
                                                        ></el-image>
                                                    </el-tooltip>
                                                    <el-tooltip
                                                        :content="$t('accountManage.weiXin')"
                                                        placement="bottom"
                                                        effect="light">
                                                        <el-image
                                                            v-if="scope.row.weChatTableDataId"
                                                            style="height: 19px"
                                                            fit="contain"
                                                            src="https://static.guoranbot.com/cdn-office-website/images/ac_type_weixin.png"
                                                            alt=""
                                                            srcset=""
                                                        ></el-image>
                                                    </el-tooltip>
                                                    <el-tooltip
                                                        :content="$t('accountManage.mail')"
                                                        placement="bottom"
                                                        effect="light">
                                                        <el-image
                                                            v-if="scope.row.email"
                                                            style="height: 18px;margin-top: -1px;"
                                                            fit="contain"
                                                            src="https://static.guoranbot.com/cdn-office-website/images/ac_type_youxiang.png"
                                                            alt=""
                                                            srcset=""
                                                        ></el-image>
                                                    </el-tooltip>
                                                    <el-tooltip
                                                        :content="$t('accountManage.eWechat')"
                                                        placement="bottom"
                                                        effect="light">
                                                        <el-image
                                                            v-if="scope.row.weWorkQrCodeLoginInfoUserId"
                                                            style="height: 19px"
                                                            fit="contain"
                                                            src="https://static.guoranbot.com/cdn-office-website/images/ac_type_qiyeweixin.png"
                                                            alt=""
                                                            srcset=""
                                                        ></el-image>
                                                    </el-tooltip>
                                                    <el-tooltip
                                                        :content="$t('accountManage.phone')"
                                                        placement="bottom"
                                                        effect="light">
                                                        <el-image
                                                            v-if="scope.row.tel"
                                                            style="height: 19px"
                                                            fit="contain"
                                                            src="https://static.guoranbot.com/cdn-office-website/images/ac_type_phone.png"
                                                            alt=""
                                                            srcset=""
                                                        ></el-image>
                                                    </el-tooltip>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column v-else-if="item.fieldType === 'USER_NAME'" :label="item.name" align="center">
                                            <template slot-scope="scope">
                                                <div style="text-align: center;width: 100%;word-break: break-all;">
                                                    {{ scope.row.name }}
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column v-else-if="item.fieldType === 'ROLE'" width="200px" align="center" >
                                            <template #header>
                                                <div class="sort-order" @click="openSortSelect" >
                                                    <div class="sort-order-r">
                                                        <span>{{ activeListTypeName}}<span>&nbsp;</span></span>
                                                        <i :class="sortIcon"></i>
                                                    </div>
                                                    <div class="sort-order-r-select">
                                                        <div
                                                            class="sort-order-r-select-item"
                                                            key="-10000"
                                                            @click.stop="handleTypeCommand('-10000')"
                                                            :class="tHeaderType == -10000 ? 'active-select': ''">
                                                            <span>{{$t('accountManage.allType')}}</span>
                                                            <span v-if="tHeaderType == -10000"><i class="el-icon-check"></i></span>
                                                        </div>
                                                        <div
                                                            class="sort-order-r-select-item"
                                                            v-for="item in accountTypes"
                                                            :key="item.id"
                                                            @click.stop="handleTypeCommand(item.id)"
                                                            :class="tHeaderType === item.id ? 'active-select' : ''">
                                                            <span>{{ item.name }}</span>
                                                            <span v-if="tHeaderType === item.id"><i class="el-icon-check"></i></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                            <template slot-scope="scope">
                                                <div style="text-align: center; width: 100%" v-if="scope.row.roleName !== null">
                                                    {{ scope.row.roleName }}
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column v-else-if="item.fieldType === 'ACCOUNT_GROUP'" :label="item.name" width="180px" align="center">
                                            <template #header>
                                                <div class="sort-order" @click="openactiveListGroupSelect">
                                                    <div class="sort-order-r">
                                                        <span>{{ activeListGroupName}}<span>&nbsp;</span></span>
                                                        <i :class="sortGroupIcon"></i>
                                                    </div>
                                                    <div class="sort-order-r-group-select">
                                                        <div @click.stop style="margin: 15px 0;padding: 0 16px;">
                                                            <el-input size="small" @input="changeGroupNameKey" placeholder="请输入" v-model="activeGroupNameKey">
                                                                <template slot="append">
                                                                    <span class="el-icon-search"></span>
                                                                </template>
                                                            </el-input>
                                                        </div>
                                                        <div 
                                                            class="sort-order-r-select-item" 
                                                            key="-1" 
                                                            @click.stop="handleGroupCommand(-1)"
                                                            :class="tHeaderGroup === -1 ? 'active-select' : '' ">
                                                            <span>{{$t('accountManage.allGroup')}}</span>
                                                            <span v-if="tHeaderGroup === -1"><i class="el-icon-check"></i></span>
                                                        </div>
                                                        <div
                                                            class="sort-order-r-select-item"
                                                            v-for="item in filterGroupListOptions"
                                                            :key="item.id"
                                                            @click.stop="handleGroupCommand(item.id)"
                                                            :class="tHeaderGroup === item.id ? 'active-select' : ''">
                                                            <span>{{ item.name }}</span>
                                                            <span v-if="tHeaderGroup === item.id"><i class="el-icon-check"></i></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                            <template slot-scope="scope">
                                                <div style="text-align: center; width: 100%">
                                                    <span v-for="(item,index) in scope.row.groupNames" :key="index">
                                                        {{item}}
                                                        <span v-if="index+1 !== scope.row.groupNames.length">、</span>
                                                    </span>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column v-else-if="item.fieldType === 'DATA_PERMISSIONS'" :label="item.name" align="center" min-width="300px">
                                            <template slot-scope="scope">
                                                <div class="botAndKnowLedge">
                                                    <div
                                                        style="text-align: center"
                                                        class="icon"
                                                        v-if="formatRoleName(scope.row.userDataPrivilegesId).length == 1 &&
                                                                (formatRoleName(scope.row.userDataPrivilegesId)[0].id == 'empty' || 
                                                                formatRoleName(scope.row.userDataPrivilegesId)[0].id == '0')" >
                                                        <span v-if="formatRoleName(scope.row.userDataPrivilegesId)[0].id == '0'">{{$t('accountManage.allPermissions')}}</span>
                                                        <span v-if="formatRoleName(scope.row.userDataPrivilegesId)[0].id == 'emptu'">{{$t('accountManage.noSet')}}</span>
                                                    </div>
                                                    <div v-else class="bot-infp-box">
                                                        <div class="part-data-list">
                                                            <span 
                                                                v-for="(cell, cellIndex) in formatRoleName(scope.row.userDataPrivilegesId)"
                                                                :key="cell.id"
                                                                class="part-data-cell">
                                                                <em v-if="cellIndex != 0">,</em>
                                                                {{ cell.privilegesName }}
                                                            </span>
                                                            <span class="show-more">
                                                                <span class="iconfont guoran-a-16-04"
                                                                    style="color: #366aff;font-size: 14px;cursor: pointer;"
                                                                    @click="lookMoreRole(scope.$index)">
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column v-else-if="item.fieldType === 'STATUS'" :label="item.name" align="center" width="140">
                                            <template #header>
                                                    <el-dropdown @command="handleCommand" @visible-change="statusVisibleChange">
                                                    <span class="el-dropdown-link">
                                                        {{activeStatusName}}
                                                        <i :class="sortGroupIcon"></i>
                                                    </span>
                                                    <el-dropdown-menu slot="dropdown">
                                                        <el-dropdown-item :command="null">{{$t('accountManage.all')}}</el-dropdown-item>
                                                        <el-dropdown-item :command="0">{{$t('accountManage.notActive')}}</el-dropdown-item>
                                                        <el-dropdown-item :command="1">{{$t('accountManage.inUse')}}</el-dropdown-item>
                                                        <el-dropdown-item :command="2">{{$t('accountManage.disabled')}}</el-dropdown-item>
                                                        <el-dropdown-item :command="3">{{$t('accountManage.notLoggedIn')}}</el-dropdown-item>
                                                        <el-dropdown-item :command="4">{{$t('accountManage.resignation')}}</el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </el-dropdown>
                                            </template>
                                            <template slot-scope="scope">
                                                <div class="wait-ing" v-if="scope.row.status == 0" style="text-align: center; width: 100%">
                                                  {{$t('accountManage.notActive')}}
                                                </div>
                                                <div class="use-ing" v-if="scope.row.status == 1" style="text-align: center; width: 100%">
                                                  {{$t('accountManage.inUse')}}
                                                </div>
                                                <div class="disable-ing" v-if="scope.row.status == 2" style="text-align: center; width: 100%">
                                                  {{$t('accountManage.disabled')}}
                                                </div>
                                                <div v-if="scope.row.status == 3">
                                                    <span style="color:#FE5965;white-space:nowrap;">{{$t('accountManage.notLoggedIn')}}</span>
                                                    <div class="disable-ing" style="text-align: center; width: 100%"> {{$t('accountManage.autoDisable')}} </div>
                                                </div>
                                                <div v-if="scope.row.status == 4">
                                                    <span style="color:#FE5965;white-space:nowrap;">{{$t('accountManage.resignation')}}</span>
                                                    <div class="disable-ing" style="text-align: center; width: 100%">  {{$t('accountManage.autoDisable')}} </div>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column v-else-if="item.fieldType === 'LANGUAGE'" :label="item.name" align="center" width="140">
                                            <template slot-scope="scope">
                                                <div  style="text-align: center; width: 100%">
                                                    {{ filterLanguage(scope.row.languageCode) }}
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column 
                                            v-else-if="item.fieldType === 'TIME_PICKER_RANGE'"
                                            :label="item.name"  
                                            align="center" 
                                            width="220" 
                                            :show-overflow-tooltip="true">
                                            <template slot-scope="scope">
                                                <template v-if="scope.row.taccount && scope.row.taccount.fieldValue">
                                                    <span v-if="scope.row.taccount.fieldValue&&scope.row.taccount.fieldValue[item.enName] && item.extInfo">
                                                        <span v-if="!item.extInfo.mold">
                                                            {{new Date(scope.row.taccount.fieldValue[item.enName][0]).Format('hh:mm')}} 至 {{new Date(scope.row.taccount.fieldValue[item.enName][1]).Format('hh:mm')}}
                                                        </span>
                                                        <span v-if="item.extInfo.mold==='TIME' || getMoldTime(item) == 'TIME' ">
                                                            {{new Date(scope.row.taccount.fieldValue[item.enName][0]).Format('hh:mm')}} 至 {{new Date(scope.row.taccount.fieldValue[item.enName][1]).Format('hh:mm')}}
                                                        </span>
                                                        <span v-if="item.extInfo.mold==='DATA_TIME' || item.extInfo.mold==='DATE_TIME' || getMoldTime(item) == 'DATA_TIME' ">
                                                            {{new Date(scope.row.taccount.fieldValue[item.enName][0]).Format('yyyy-MM-dd hh:mm')}} 至 {{new Date(scope.row.taccount.fieldValue[item.enName][1]).Format('yyyy-MM-dd hh:mm')}}
                                                        </span>
                                                        <span v-if="item.extInfo.mold==='DATA' || item.extInfo.mold==='DATE' || getMoldTime(item) == 'DATA' ">
                                                            {{new Date(scope.row.taccount.fieldValue[item.enName][0]).Format('yyyy-MM-dd')}} 至 {{new Date(scope.row.taccount.fieldValue[item.enName][1]).Format('yyyy-MM-dd')}}
                                                        </span>
                                                    </span>
                                                    <span v-else>--</span>
                                                </template>
                                                <span v-else>--</span>
                                            </template>
                                        </el-table-column>
                                        <!-- <template v-if="isMENGNIU"> -->
                                        <el-table-column v-else-if="item.fieldType === 'MN_MODULE'" prop="userSystem"  label="模块/系统" align="center" width="200">
                                            <template slot="header" slot-scope="scope">
                                                <div class="sort-order" @click="openUserSystems">
                                                    <el-popover
                                                        popper-class="sort-order-r-user-systems-popover"
                                                        placement="bottom"
                                                        width="300"
                                                        :visible-arrow="false"
                                                        v-model="userSystemsPopover"
                                                        trigger="manual">
                                                        <div class="sort-order-r" slot="reference">
                                                            <span>{{$t('accountManage.module')}}<span>&nbsp;</span></span>
                                                            <i :class="sortUserSystemsIcon"></i>
                                                        </div>
                                                        <el-tree
                                                            :data="setCascadeOption"
                                                            :default-expand-all="true"
                                                            :expand-on-click-node="false"
                                                            ref="tree-search"
                                                            node-key="value"
                                                            :props="{isLeaf:'isLeaf'}"
                                                            check-strictly
                                                            @node-click="handleTreeClickFilter">
                                                            <span class="el-tree-title" slot-scope="{ node, data }" >
                                                            <span class="el-tree-title-content">{{ data.label }}</span>
                                                            </span>
                                                        </el-tree>
                                                    </el-popover>
                                                </div>
                                            </template>
                                            <template slot-scope="scope">
                                                <div style="text-align: center; width: 100%" v-if="scope.row.userSystems && scope.row.userSystems.length > 0">
                                                    <span v-for="(items,indexs) in scope.row.userSystems" :key="indexs">
                                                        <span class="firstName" style="color: #000;" v-if="items.selectAll === 'true'">{{$t('accountManage.allModules')}}</span>
                                                        <span class="firstName" style="color: #000;" v-else>{{items.secondName }}</span>
                                                        <span class="secondName" style=" color: #A9B3C6;font-size: 12px;" v-if="items.firstName && items.firstName !== ''"> ({{items.firstName}})</span>
                                                        <span v-if="indexs+1 !== scope.row.userSystems.length"> 、</span>
                                                    </span>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            v-else-if="item.fieldType === 'MN_COMPANY'"
                                            prop="mengNiuCompanyName"
                                            :label="$t('accountManage.company')"
                                            align="center"
                                            width="100">
                                            <template #header>
                                                    <el-dropdown @command="handleCommandStatus" @visible-change="visibleChange">
                                                    <span class="el-dropdown-link">
                                                        {{filterMengNiuCompanyName}}
                                                        <i :class="sortmengNiuCompanyIcon"></i>
                                                    </span>
                                                    <el-dropdown-menu slot="dropdown" class="comask-el-dropdown-menu">
                                                        <el-dropdown-item :command="null">{{$t('accountManage.all')}}</el-dropdown-item>
                                                        <el-dropdown-item :command="companyItem.id" v-for="(companyItem,companyIndex) in companyData" :key="companyIndex">{{companyItem.name}}</el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </el-dropdown>
                                            </template>
                                            <template slot-scope="scope">
                                                <div style="text-align: center; width: 100%" >{{ scope.row.mengNiuCompanyName}}</div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            v-else-if="item.fieldType === 'MN_WORK_NO'"
                                            prop="workNumber"
                                            :label="$t('accountManage.jobId')"
                                            align="center"
                                            width="130">
                                        </el-table-column>
                                        <el-table-column
                                            v-else-if="item.fieldType === 'MN_TEL'"
                                            prop="tel"
                                            :label="$t('accountManage.telephone')"
                                            align="center"
                                            width="130">
                                        </el-table-column>
                                        <el-table-column
                                            v-else-if="item.fieldType === 'MN_CONTACT_PERSON'"
                                            :label="$t('accountManage.contactPerson')"
                                            align="center"
                                            width="100">
                                            <template #header>
                                                <el-dropdown trigger="click" style="max-height:500px;overflow-y:auto" @command="handleCommandPersonMember" @visible-change="visibleChangePersonMember">
                                                    <span class="el-dropdown-link">
                                                        {{filterPersonMemberName}}
                                                        <i :class="sortPersonMemberIcon"></i>
                                                    </span>
                                                    <el-dropdown-menu slot="dropdown" class="person-member-dropdown-menu">
                                                        <el-dropdown-item :command="null">{{$t('accountManage.all')}}</el-dropdown-item>
                                                        <el-dropdown-item :command="personMemberItem.id" v-for="(personMemberItem,personMemberIndex) in personMemberList" :key="personMemberIndex">{{personMemberItem.name}}</el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </el-dropdown>
                                            </template>
                                            <template slot-scope="scope">
                                                <div style="text-align: center; width: 100%" >{{scope.row.contactPersonName}}</div>
                                            </template>
                                        </el-table-column>
                                    <!-- </template> -->
                                        <el-table-column 
                                            v-else
                                            :label="item.name"  
                                            align="center" 
                                            min-width="150" 
                                            :show-overflow-tooltip="true">
                                            <template slot-scope="scope">
                                                <template v-if="scope.row.taccount && scope.row.taccount.fieldValue">
                                                    <div v-if="item.fieldType==='INPUT'||item.fieldType==='TEXTAREA'">
                                                        <div v-if="scope.row.taccount.fieldValue&&scope.row.taccount.fieldValue[item. enName]">
                                                            {{scope.row.taccount.fieldValue[item. enName]}}
                                                        </div>
                                                        <div v-else>--</div>
                                                    </div>
                                                    <div v-else-if="item.fieldType === 'CHECKBOX'&&scope.row.taccount.fieldValue !==null">
                                                        <span v-if="scope.row.taccount.fieldValue!==null">
                                                            <span v-if="scope.row.taccount.fieldValue[item. enName]!==undefined">
                                                                <span v-for="(item,index) in scope.row.taccount.fieldValue[item. enName]" :key="index">
                                                                    {{index===0?item:","+item}}
                                                                </span>
                                                            </span>
                                                            <span v-if="scope.row.taccount.fieldValue[item. enName]===undefined">--</span>
                                                        </span>
                                                        <span v-if="scope.row.taccount.fieldValue===null">--</span>
                                                    </div>
                                                    <div v-else-if="item.fieldType==='CASCADER'" style="text-overflow:ellipsis;white-space:nowrap;overflow:hidden;">
                                                        <span v-if="scope.row.taccount.fieldValue!==null">
                                                            <span v-if="scope.row.taccount.fieldValue[item. enName]!==undefined">
                                                                <span v-for="(item,index) in scope.row.taccount.fieldValue[item. enName]" :key="index">
                                                                    {{index===0?item:"/"+item}}
                                                                </span>
                                                            </span>
                                                            <span v-if="scope.row.taccount.fieldValue[item. enName]===undefined">--</span>
                                                        </span>
                                                        <span v-if="scope.row.taccount.fieldValue===null">--</span>
                                                    </div>
                                                    <div v-else-if="item.fieldType === 'DATE_PICKER'&&scope.row.taccount.fieldValue!==null">
                                                        <span v-if="scope.row.taccount.fieldValue[item. enName]!==undefined">
                                                            <span v-if="scope.row.taccount.fieldValue[item. enName]!==''">
                                                                {{new Date(scope.row.taccount.fieldValue[item. enName]).Format('yyyy-MM-dd')}}
                                                            </span>
                                                        </span>
                                                        <span v-if="scope.row.taccount.fieldValue[item. enName]===undefined||scope.row.taccount.fieldValue[item. enName]===''">--</span>
                                                    </div>
                                                    <div v-else-if="item.fieldType === 'DATE_PICKER'&&scope.row.taccount.fieldValue===null">--</div>
                                                    <div v-else-if="item.fieldType === 'TIME_PICKER'">
                                                        <span v-if="scope.row.taccount.fieldValue&&scope.row.taccount.fieldValue[item.enName] && item.extInfo">
                                                            <span v-if="!item.extInfo.mold">
                                                                {{new Date(scope.row.taccount.fieldValue[item.enName]).Format('hh:mm')}}
                                                            </span>
                                                            <span v-else-if="item.extInfo.mold==='TIME' || getMoldTime(item) == 'TIME'">
                                                                {{new Date(scope.row.taccount.fieldValue[item.enName]).Format('hh:mm')}}
                                                            </span>
                                                            <span v-else-if="item.extInfo.mold==='DATA_TIME' || item.extInfo.mold==='DATE_TIME' || getMoldTime(item) == 'DATA_TIME' ">
                                                                {{new Date(scope.row.taccount.fieldValue[item.enName]).Format('yyyy-MM-dd hh:mm')}}
                                                            </span>
                                                            <span v-else-if="item.extInfo.mold==='DATA' || item.extInfo.mold==='DATE' || getMoldTime(item) == 'DATA'">
                                                                {{new Date(scope.row.taccount.fieldValue[item.enName]).Format('yyyy-MM-dd')}}
                                                            </span>
                                                        </span>
                                                        <span v-else>--</span>
                                                    </div>
                                                    <!-- v-if="item.fieldType!=='CASCADER'&&item.fieldType!=='DATE_PICKER'&&item.fieldType!=='TIME_PICKER'&&item.fieldType!=='CHECKBOX'&&item.fieldType!=='INPUT'&&item.fieldType!=='TEXTAREA'" -->
                                                    <div v-else style="text-overflow:ellipsis;white-space:nowrap;overflow:hidden;">
                                                        <div v-if="scope.row.taccount.fieldValue&&scope.row.taccount.fieldValue[item. enName]">
                                                            {{scope.row.taccount.fieldValue[item. enName]}}
                                                        </div>
                                                        <div v-else>--</div>
                                                    </div>
                                                </template>
                                                <span v-else>--</span>
                                            </template>
                                        </el-table-column>
                                    </template>
                                </div>
                            </template>
                            <el-table-column :label="$t('accountManage.operation')" fixed="right" align="center" width="120">
                                <template slot-scope="scope">
                                    <div class="table-handle-icon-box">
                                        <el-tooltip class="item" effect="dark" :content="$t('accountManage.edit')" placement="top">
                                            <span 
                                                class="table-handle-icon" 
                                                v-if="roleOper(scope.row.roleId,scope.row.userId,'PORTAL_MANAGEMENT_ACCOUNT_UPDATE')"
                                                @click="editAccount(
                                                        scope.row.account,
                                                        scope.row.name,
                                                        scope.row.groupNames,
                                                        scope.row.roleName,
                                                        scope.row.privileges,
                                                        scope.row.userId,
                                                        scope.row.roleId,
                                                        scope.row.mainName,
                                                        scope.row.editable,
                                                        scope.row)">
                                                <i class="iconfont guoran-a-16-15"></i>
                                            </span>
                                        </el-tooltip>
                                        <el-tooltip class="item" effect="dark" :content="$t('accountManage.enable')" placement="top">
                                            <span
                                                class="table-handle-icon"
                                                v-if="(roleOper(scope.row.roleId,scope.row.userId,'PORTAL_MANAGEMENT_ACCOUNT_FORBID') &&
                                                    scope.row.editable && scope.row.status == '2') || scope.row.status == 3 || scope.row.status == 4"
                                                :icon="scope.row.status == '2' ||  scope.row.status == 3 || scope.row.status == 4 ? 'el-icon-success': 'el-icon-remove'"
                                                :disabled="!scope.row.editable"
                                                @click="changeStatusAccount(scope.row.userId,scope.row.status)">
                                                <i class="iconfont guoran-a-xuanze1"></i>
                                            </span>
                                        </el-tooltip>
                                        <el-tooltip class="item" effect="dark" :content="$t('accountManage.disabled')" placement="top">
                                            <span
                                                class="table-handle-icon"
                                                v-if="roleOper(scope.row.roleId,scope.row.userId,'PORTAL_MANAGEMENT_ACCOUNT_FORBID') &&
                                                    scope.row.editable && scope.row.status != '2' && scope.row.status != 3 && scope.row.status != 4"
                                                :icon="scope.row.status == '2' ? 'el-icon-success' : 'el-icon-remove'"
                                                :disabled="!scope.row.editable"
                                                @click="changeStatusAccount(scope.row.userId,scope.row.status)">
                                                <i class="iconfont guoran-jinyong"></i>
                                            </span>
                                        </el-tooltip>
                                        <el-tooltip class="item" effect="dark" :content="$t('accountManage.delete')" placement="top">
                                            <span
                                                class="table-handle-icon"
                                                v-if="roleOper(scope.row.roleId,scope.row.userId,'PORTAL_MANAGEMENT_ACCOUNT_DELETE') && scope.row.editable"
                                                @click="deleteAccount(scope.row.userId)"
                                                :disabled="!scope.row.editable">
                                                <i class="iconfont  guoran-a-18-13"></i>
                                            </span>
                                        </el-tooltip>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <popup v-if="dialogVisible" @closeEvent="closeHandleAcountDialog" class="add-account">
                            <div slot="popup-name" class="popup-name">
                                {{ diaglogTitle }}
                            </div>
                            <div slot="popup-con">
                                <div style="overflow: hidden" class="dialog-content">
                                    <el-form
                                        style="width: 98%"
                                        ref="form"
                                        id="account-manage-add"
                                        :model="changeAcountForm"
                                        label-width="90px">
                                        <el-form-item label="邮箱">
                                            <span slot="label">{{$t('accountManage.mail')}}<span class="red">*</span></span>
                                            <el-input
                                                :placeholder="$t('accountManage.mailPlaceholder')"
                                                v-model.trim="changeAcountForm.emailArresss"
                                                :disabled="isEditAccount">
                                            </el-input>
                                        </el-form-item>
                                        <el-form-item :label="$t('accountManage.bindThirdPartyAccounts')">
                                         <div class="bind-third-account-input" @click="openMemberPopup">
                                           <template v-if="valueName">
                                             <open-data v-if="memberInfo4Client.bindType === 0" :openid="memberInfo4Client.name" :type="'userName'"></open-data>
                                             <span v-else>{{changeAcountForm.fullName}}</span>
                                           </template>
                                           <template v-else>
                                             <span style="color: #A9B3C6;">{{$t('accountManage.selectPlaceholder')}}</span>
                                           </template>
                                         </div>
                                        </el-form-item>
                                        <el-form-item label="姓名">
                                          <div slot="label" class="form-name-label">
                                            <div class="left">{{$t('accountManage.name')}}<span class="red">*</span></div>
                                            <div class="right" v-if="memberInfo4Client.bindType === 0">{{$t('accountManage.nameTip')}}</div>
                                          </div>
                                            <div style="position: relative">
                                                <el-input
                                                    :placeholder="$t('accountManage.namePlaceholder')"
                                                    ref="fullName"
                                                    v-model="changeAcountForm.fullName"
                                                    @focus="openTree"
                                                    @blur="onBlur"
                                                    @input="fullInput"
                                                ></el-input>
                                            </div>
                                        </el-form-item>
                                        <el-form-item label="账号类型">
                                            <span slot="label">{{$t('accountManage.accountType')}}<span class="red">*</span></span>
                                            <el-select
                                                v-if="!(changeAcountForm.accountType == -1 && !changeAcountForm.editable) "
                                                v-model="changeAcountForm.accountType"
                                                :placeholder="$t('accountManage.accountTypePlaceholder')"
                                                :disabled="isSuperAcconut || !changeAcountForm.editable"
                                                @change="changeAccountType">
                                                <el-option
                                                    v-for="typeItem in accountTypes"
                                                    :key="typeItem.id"
                                                    :label="typeItem.name"
                                                    :value="typeItem.id">
                                                </el-option>
                                            </el-select>
                                            <el-input
                                                v-if="changeAcountForm.accountType == -1 && !changeAcountForm.editable"
                                                :placeholder="$t('accountManage.subEntity')"
                                                disabled
                                                v-model="palceholderText"
                                            ></el-input>
                                        </el-form-item>
                                        <el-form-item v-if="changeAcountForm.accountType == -1" label="子主体名称">
                                            <span slot="label">{{$t('accountManage.subEntityName')}}<span class="red">*</span></span>
                                            <el-input
                                                :placeholder="$t('accountManage.subEntityNamePla')"
                                                :disabled="!changeAcountForm.editable"
                                                v-model="changeAcountForm.mainName"
                                            ></el-input>
                                        </el-form-item>
                                        <el-form-item label="账号组">
                                            <span slot="label">{{$t('accountManage.accountGroup')}}<span class="red">*</span></span>
                                            <el-select
                                                multiple
                                                filterable
                                                v-model="changeAcountForm.groupNames"
                                                filterable
                                                :placeholder="$t('accountManage.accountGroupPla')">
                                                <el-option
                                                    v-for="item in groupList"
                                                    :key="item.id"
                                                    :label="item.name"
                                                    :value="item.name">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item label="数据权限" v-if="isShowBotAuthority && changeAcountForm.accountType !=-1">
                                            <span slot="label">{{$t('accountManage.dataPermissions')}}<span class="red">*</span></span>
                                            <el-select
                                                v-model="changeAcountForm.userDataPrivilegesIds"
                                                multiple
                                                key="_userDataPrivilegesIds"
                                                @change="changeUserDataPrivileges"
                                                :placeholder="$t('accountManage.dataPermissionPla')">
                                                <el-option
                                                    style="height: 42px"
                                                    key="0"
                                                    label="全部权限"
                                                    :value="0">
                                                    <span style="line-height: 28px;display: block;">{{$t('accountManage.allPermission')}}</span>
                                                    <span style="line-height: 14px;display: block;color: #c0c4cc;font-size: 12px;">
                                                        <span>&nbsp;&nbsp;</span>
                                                        {{$t('accountManage.permissionTip')}}
                                                    </span>
                                                </el-option>
                                                <el-option
                                                    style="height: 42px"
                                                    key="0"
                                                    label="账号自建可见权限"
                                                    :value="-1">
                                                    <span style="line-height: 28px;display: block; ">{{$t('accountManage.visiblePermissions')}}</span>
                                                    <span style="line-height: 14px;display: block;color: #c0c4cc;font-size: 12px;" >
                                                        <span>&nbsp;&nbsp;</span>
                                                        {{$t('accountManage.visiblePermissionsTip')}}
                                                        </span>
                                                </el-option >
                                                <el-option 
                                                    style="height: 42px" 
                                                    key="0" 
                                                    label="当前账号组内所有工单" 
                                                    :value="99999">
                                                    <span style="line-height: 28px;display: block;">{{$t('accountManage.allOrder')}}</span>
                                                    <span style="line-height: 14px;display: block;color: #c0c4cc;font-size: 12px;">
                                                        <span>&nbsp;&nbsp;</span>
                                                        {{$t('accountManage.allOrderTip')}}
                                                    </span>
                                                </el-option>
                                                <el-option
                                                    style="height: 42px"
                                                    v-for="item in userDataPrivileges"
                                                    :key="item.id"
                                                    :label="item.privilegesName"
                                                    :value="item.id">
                                                    <span style="line-height: 28px;display: block;">{{item.privilegesName}}</span>
                                                    <span style="line-height: 14px;display: block;color: #c0c4cc;font-size: 12px;">
                                                        <span>&nbsp;&nbsp;</span>{{item.description}}</span>
                                                </el-option>
                                            </el-select>
                                            <div style="text-align: right">
                                                <el-button
                                                    @click="navigateToAddPriv"
                                                    type="text"
                                                    style="color: #366aff">
                                                    {{$t('accountManage.addDataPermission')}}
                                                </el-button>
                                            </div>
                                        </el-form-item>
                                        <el-form-item>
                                            <span slot="label">{{$t('accountManage.languageCode')}}</span>
                                            <el-select
                                                v-model="changeAcountForm.languageCode"
                                                :placeholder="$t('accountManage.languageCodePla')">
                                                <el-option label="中文" value="cn"></el-option>
                                                <el-option label="English" value="en"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <template v-if="isMENGNIU">
                                            <el-form-item :label="$t('accountManage.module')" >
                                                <div class="cascade-class" @click="openCascadeOption">
                                                    <template v-if="moduleSystem && moduleSystem && moduleSystem.length !== 0">
                                                        <div v-for="(cas,index) in moduleSystem"  :key="cas + index">
                                                            {{index === 0?cas.label:','+cas.label}}
                                                        </div>
                                                    </template>
                                                    <div class="placeholder-div" v-else>{{$t('accountManage.selectPlaceholder')}}</div>
                                                </div>
                                            </el-form-item>
                                            <el-form-item :label="$t('accountManage.company')">
                                                <el-select
                                                    clearable
                                                    @change="forceUpdateFn"
                                                    @clear="forceUpdateFn"
                                                    v-model="changeAcountForm.mengNiuCompanyId"
                                                    :placeholder="$t('accountManage.companyPlaceholder')">
                                                    <el-option
                                                        v-for="item in companyData"
                                                        :key="item.id"
                                                        :label="item.name"
                                                        :value="item.id">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item :label="$t('accountManage.jobId')">
                                                <el-input
                                                    :placeholder="$t('accountManage.jobIdPlaceholder')"
                                                    @input="forceUpdateFn"
                                                    v-model="changeAcountForm.workNumber">
                                                </el-input>
                                            </el-form-item>
                                            <el-form-item :label="$t('accountManage.telephone')">
                                                <el-input
                                                    :placeholder="$t('accountManage.telephonePlaceholder')"
                                                    v-model="changeAcountForm.tel"
                                                    @input="forceUpdateFn"
                                                    @blur="judgeTel">
                                                </el-input>
                                            </el-form-item>
                                            <el-form-item :label="$t('accountManage.contactPerson')">
                                                <el-cascader
                                                    popper-class="contact-person-cascader"
                                                    :placeholder="$t('common.selectPlaceholder')"
                                                    clearable
                                                    v-model="changeAcountForm.contactPersonId"
                                                    :show-all-levels="false"
                                                    :props="{children:'accountInfos', label: 'name',value:'id'}"
                                                    :options="personList">
                                                </el-cascader>
                                            </el-form-item>
                                        </template>
                                        <template v-if="templateInfo && templateInfo.fieldColumns">
                                            <template v-for="(item,index) in templateInfo.fieldColumns[0].allFields">
                                                <div 
                                                    :key="index" 
                                                    v-if="item.type !== 'EMAIL' && item.type !== 'THIRD_PARTY_ACCOUNT' && item.type !== 'USER_NAME' && item.type !== 'ROLE' && item.type !== 'ACCOUNT_GROUP' && item.type !== 'DATA_PERMISSIONS' &&
                                                            item.type !== 'MN_MODULE' && item.type !== 'MN_COMPANY' && item.type !== 'MN_WORK_NO' && item.type !== 'MN_TEL' && item.type !== 'MN_CONTACT_PERSON' && item.type !== 'LANGUAGE'">
                                                    <el-form-item>
                                                        <span slot="label" v-if="(item.type !== 'EXPLAIN' && item.type !== 'TYPE_MATE' && item.type !== 'WORKING_TIME' && item.type !== 'MAX_NUM_OF_ORDERS_PROCESSED') || ((item.type === 'TYPE_MATE' || item.type === 'WORKING_TIME' || item.type === 'MAX_NUM_OF_ORDERS_PROCESSED') && isShowWorkOrder)">
                                                            {{$t('common.' + item.type)}}
                                                            <span class="red" v-if="item.required">*</span>
                                                            <el-tooltip 
                                                                class="item" 
                                                                popper-class="add-count-tooltip"
                                                                v-if="item.remark && item.remark.enable"
                                                                :disabled="item.remark && item.remark.text === ''"
                                                                effect="dark" 
                                                                :content="item.remark.text" placement="top-start">
                                                                <i class="iconfont guoran-tongyichicun-18-16-youxianghouzhuishuomingtishifuhe" style="fontSize:14px;margin:0 5px;"></i>
                                                            </el-tooltip>
                                                        </span>
                                                        <date-tem 
                                                            v-if="item.type !== 'TYPE_MATE' && item.type !== 'WORKING_TIME' && item.type !== 'MAX_NUM_OF_ORDERS_PROCESSED'"
                                                            :items="item" 
                                                            :fieldValue="orderFieldValue"
                                                            @changeFieldValues="changeFieldValues" 
                                                            @changeSelect="changeSelect"
                                                            :extInfoFieldValue="extInfoFieldValue" 
                                                            :dataTemplate="templateInfo.fieldColumns[0].allFields"
                                                            :ref="'file' + item.id"
                                                            :orderDefaultContent="orderDefaultContent" 
                                                            :statusPriorityFieldValue="statusPriorityFieldValue">
                                                        </date-tem>
                                                        <template v-else>
                                                            <el-dropdown
                                                                v-if="item.type === 'TYPE_MATE' && isShowWorkOrder"
                                                                trigger="click"
                                                                id="el-dropdown-work-order"
                                                                @visible-change="elDropdownvisibleChange"
                                                                :hide-on-click="false">
                                                                <span :class="['el-dropdown-link','el-dropdown-link-workorder',workOrderSelectIng ? 'active-el-dropdown-link' : '',]">
                                                                    <span v-if="workOrderType.length != 0" class="el-dropdown-work-order-content">
                                                                        <el-tag
                                                                            v-for="(workOrder,workOrderIndex) in workOrderTypeFilter"
                                                                            :key="workOrderIndex"
                                                                            size="small"
                                                                            type="info"
                                                                            @close="closeWorkOrder(workOrderIndex)"
                                                                            closable>
                                                                            {{workOrder.workOrderTypeName}}
                                                                        </el-tag>
                                                                    </span>
                                                                    <span
                                                                        v-if="workOrderType.length == 0"
                                                                        class=" el-dropdown-placeholder">
                                                                        {{$t('accountManage.orderTypePlaceholder')}}
                                                                    </span>
                                                                    <span class="el-icon">
                                                                        <em v-show="!workOrderSelectIng" class="el-icon-arrow-down"></em>
                                                                        <em v-show="workOrderSelectIng" class="el-icon-arrow-up"></em>
                                                                    </span>
                                                                </span>
                                                                <el-dropdown-menu id="work-order-dropdown" slot="dropdown" class="el-dropdown-work-order">
                                                                    <el-dropdown-item class="work-order-dropdown-item">
                                                                        <div style="height: 300px; overflow: auto;">
                                                                            <div
                                                                                v-for="(cellTree,cellTreeIndex) in workOrderTypeTree"
                                                                                :key="cellTreeIndex">
                                                                                <el-tree
                                                                                    :data="cellTree"
                                                                                    node-key="id"
                                                                                    :ref="'ordertree_' + cellTreeIndex"
                                                                                    default-expand-all
                                                                                    :highlight-current="true"
                                                                                    :expand-on-click-node="false"
                                                                                    show-checkbox
                                                                                    :check-on-click-node="true"
                                                                                    @check-change="(node,val,c_val) =>WorkOrderTypeHandleCheckChange(node,val,c_val,cellTreeIndex)"
                                                                                    check-strictly
                                                                                    :props="typeTreeDefaultProps">
                                                                                </el-tree>
                                                                            </div>
                                                                        </div>
                                                                    </el-dropdown-item>
                                                                </el-dropdown-menu>
                                                            </el-dropdown>
                                                            <el-select
                                                                v-if="item.type === 'WORKING_TIME' && isShowWorkOrder"
                                                                v-model="changeAcountForm.userWorkTimeId"
                                                                :placeholder="$t('accountManage.workTimePlaceholder')">
                                                                <el-option
                                                                    v-for="item in workTimes"
                                                                    :key="item.id"
                                                                    :label="item.workTimeName"
                                                                    :value="item.id">
                                                                </el-option>
                                                            </el-select>
                                                            <template v-if="item.type === 'MAX_NUM_OF_ORDERS_PROCESSED' && isShowWorkOrder">
                                                                <el-radio-group v-model="changeAcountForm.workOrderSetting.limitOrderNum">
                                                                    <el-radio :label="false">{{$t('accountManage.unlimited')}}</el-radio>
                                                                    <el-radio :label="true">{{$t('accountManage.custom')}}</el-radio>
                                                                </el-radio-group>
                                                                <el-input
                                                                    v-model="changeAcountForm.workOrderSetting.maxNumOfWorkOrdersProcessed"
                                                                    v-if="changeAcountForm.workOrderSetting.limitOrderNum"
                                                                    class="processed"
                                                                    :placeholder="$t('accountManage.maxNumOfWorkOrdersProcessed')"
                                                                    type="number"
                                                                    @input="processedInput"
                                                                    onkeyup="this.value=this.value.replace(/D/g,'')"
                                                                    @blur="() =>{ !changeAcountForm.workOrderSetting.maxNumOfWorkOrdersProcessed && (changeAcountForm.workOrderSetting.maxNumOfWorkOrdersProcessed = 0) } "
                                                                    :min="0"
                                                                    :max="10000"
                                                                ></el-input>
                                                            </template>
                                                        </template>
                                                    </el-form-item>
                                                </div> 
                                            </template>
                                        </template>
                                      <!-- 代码迁移过来 本身就不显示,先保留-->
                                        <el-form-item
                                            label="机器人权限"
                                            v-if="isShowBotAuthority && isRoleVisable && changeAcountForm.accountType != -1 && false"
                                            style="display: flex;flex-direction: column;"
                                            class="quanxian">
                                            <div slot="label">{{$t('accountManage.robotPermissions')}}</div>
                                            <div class="quanxian-content">
                                                <div class="auth-content">
                                                    <div class="content">
                                                        <div class="bot">
                                                            <el-checkbox
                                                                :disabled="isSuperAcconut"
                                                                style="text-align: left;"
                                                                :indeterminate="isIndeterminate"
                                                                v-model="checkAll"
                                                                @change="handleCheckAllChange"
                                                                class="allBot">
                                                                {{$t('accountManage.bot')}}
                                                            </el-checkbox>
                                                            <div class="botListItem">
                                                                <div 
                                                                    v-for="(item,index) in botList"
                                                                    style="display: flex;flex-direction: row;height: 40px;line-height: 40px;padding-left: 10px;"
                                                                    :key="index"
                                                                    :class="skillBotId === item.botId ? 'activeBotClass' : ''">
                                                                    <el-checkbox
                                                                        v-model="item.checked"
                                                                        :indeterminate="item.botIsIndeterminate"
                                                                        :value="item.botId"
                                                                        @change="handleCheckedBotChange($event,item.botId)">
                                                                    </el-checkbox>
                                                                    <div class="botName"
                                                                        style=""
                                                                        @click="clickBot(item.botId)">
                                                                        {{item.botName}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="right-auth-content">
                                                    <div class="knowledgeBase">
                                                        <div class="title">
                                                            <span>{{$t('accountManage.knowledgeBase')}}</span>
                                                        </div>
                                                        <div class="skillListItem">
                                                            <el-checkbox-group
                                                                :disabled="isSuperAcconut"
                                                                v-model="skillCheckList"
                                                                @change="handleCheckedSkillChange"
                                                                style="display: flex;flex-direction: column;justify-content: flex-start;text-align: left;">
                                                                <el-checkbox
                                                                    v-for="item in skillList"
                                                                    :key="item.id"
                                                                    :label="item.id"
                                                                    name="type">
                                                                    {{item.name}}
                                                                </el-checkbox>
                                                            </el-checkbox-group>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </el-form-item>
                                    </el-form>
                                </div>
                            </div>
                            <div slot="dialog-footer">
                                <el-button
                                    class="btn"
                                    @click="closeHandleAcountDialog">
                                    {{$t('common.cancel')}}</el-button>
                                <el-button
                                    :disabled="saveAccountDisable"
                                    type="primary"
                                    class="btn"
                                    style="color: #ffffff"
                                    @click="saveAccount">
                                    <span v-if="diaglogTitle == $t('accountManage.addAccount')">{{$t('common.inviteNow')}}</span>
                                    <span v-if="diaglogTitle == $t('accountManage.editAccount')">{{$t('common.save')}}</span>
                                </el-button>
                            </div>
                        </popup>
                        <!-- 分组管理 -->
                        <popup
                            id="diaHandleClass"
                            v-if="classHandleVisible"
                            @closeEvent="classHandleVisible = false"
                        >
                            <div slot="popup-name" class="popup-name">
                                {{
                                    operableAccount(
                                        "PORTAL_MANAGEMENT_GROUP_UPDATE"
                                    )
                                        ? $t('accountManage.accountGroupManagement')
                                        : $t('accountManage.viewAccountGroup')
                                }}
                            </div>
                            <div slot="popup-con">
                                <div class="classListBoxUp">
                                    <ul id="classList" class="classList">
                                        <li
                                            v-for="(item, index) in groupList"
                                            :key="index"
                                        >
                                            <span class="num">{{
                                                index + 1
                                            }}</span>
                                            <div class="content-list">
                                                <span v-show="!item.isEdit">{{
                                                    item.name
                                                }}</span>
                                                <span v-show="item.isEdit">
                                                    <el-input
                                                        size="small"
                                                        v-model="item.name"
                                                        oninput="value = value.replace(/\s/g, '')"
                                                        maxlength="15"
                                                    ></el-input>
                                                </span>
                                            </div>
                                            <div class="classHandle">
                                                <em
                                                    @click="editClass(index)"
                                                    v-show="
                                                        !item.isEdit &&
                                                        operableGroup(
                                                            'PORTAL_MANAGEMENT_GROUP_UPDATE'
                                                        )
                                                    "
                                                    class="
                                                        iconfont
                                                        guoran-a-16-15
                                                    "
                                                ></em>
                                                <em
                                                    @click="deleteClass(index)"
                                                    class="
                                                        iconfont
                                                        guoran-a-18-13
                                                    "
                                                    v-if="
                                                        operableGroup(
                                                            'PORTAL_MANAGEMENT_GROUP_UPDATE'
                                                        )
                                                    "
                                                ></em>
                                            </div>
                                        </li>
                                    </ul>
                                    <div
                                        class="tar-add-up"
                                        v-if="
                                            operableGroup(
                                                'PORTAL_MANAGEMENT_GROUP_UPDATE'
                                            )
                                        "
                                        @click="addClass"
                                    >
                                        <span class="tar-add-up-icon"
                                            ><i
                                                class="iconfont guoran-a-16-13"
                                            ></i
                                        ></span>
                                        <span class="tar-add-up-text">
                                            {{$t('accountManage.addAccountGroup')}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div slot="dialog-footer">
                                <el-button
                                    class="btn"
                                    @click="classHandleVisible = false"
                                    >{{$t('common.cancel')}}</el-button
                                >
                                <el-button
                                    type="primary"
                                    class="btn"
                                    style="color: #ffffff"
                                    @click="saveClassHandle"
                                    v-if="
                                        operableGroup(
                                            'PORTAL_MANAGEMENT_GROUP_UPDATE'
                                        )
                                    "
                                    >{{$t('common.save')}}</el-button
                                >
                            </div>
                        </popup>
                        <!-- 权限详情 -->
                        <popup
                            v-if="activeViewVisible"
                            @closeEvent="activeViewVisible = false"
                        >
                            <div slot="popup-name" class="popup-name">
                                {{ activeViewAcount.account }}
                            </div>
                            <div slot="popup-con">
                                <DataroleDetail
                                    ref="dataroled"
                                    :detailIds="
                                        activeViewAcount.userDataPrivilegesId
                                    "
                                />
                            </div>
                            <div slot="dialog-footer">
                                <el-button
                                    type="primary"
                                    class="btn"
                                    style="color: #ffffff"
                                    @click="activeViewVisible = false"
                                    >{{$t('common.close')}}</el-button
                                >
                            </div>
                        </popup>
                        <delete-dialog
                            @closeEvent="deleteAutomated = false"
                            v-if="deleteAutomated"
                        >
                            <div slot="popup-container" class="popup-container">
                                <div class="delete-title">{{$t('accountManage.deleteAccountTitle')}}</div>
                                <div>
                                  {{$t('accountManage.deleteAccountTip')}}
                                </div>
                            </div>
                            <div slot="dialog-footer">
                                <el-button
                                    @click="deleteAutomated = false"
                                    size="small"
                                    plain
                                    >{{$t('common.cancel')}}</el-button
                                >
                                <el-button
                                    @click="delAutomated"
                                    style="
                                        background-color: #fe5965;
                                        color: white;
                                    "
                                    size="small"
                                    >{{$t('common.delete')}}</el-button
                                >
                            </div>
                        </delete-dialog>
                        <delete-dialog
                            @closeEvent="openOrCloseAutomated = false"
                            v-if="openOrCloseAutomated"
                        >
                            <div slot="popup-container" class="popup-container">
                                <div class="delete-title">
                                    {{ openOrCloseAutomatedTitle }}
                                </div>
                                <div>
                                    {{ openOrCloseAutomatedText }}
                                </div>
                            </div>
                            <div slot="dialog-footer">
                                <el-button
                                    @click="openOrCloseAutomated = false"
                                    size="small"
                                    plain
                                    >{{$t('common.cancel')}}</el-button
                                >
                                <el-button
                                    @click="openOrCloseAutom"
                                    style="
                                        background-color: #fe5965;
                                        color: white;
                                    "
                                    size="small"
                                    >{{ openOrCloseHandleType }}</el-button
                                >
                            </div>
                        </delete-dialog>
                        <el-tooltip class="item" effect="dark" :content="$t('accountManage.tableHeaderSettings')" placement="bottom">
                            <div class="table-header" @click="openTableHeader">
                                <i class="iconfont guoran-a-18-14"></i>
                            </div>
                        </el-tooltip>
                    </div>
                    <div v-if="accountList.length != 0" class="pagination">
                        <pagination
                            :pageSize="15"
                            :currentPage="currentPage"
                            :total="accountData.total"
                            @currentChange="handleCurrentChange"
                        ></pagination>
                    </div>
                </div>
            </div>
        </div>

        <SideNav ref="leftMenuObj"></SideNav>
        <delete-dialog
            @closeEvent="dleteAccDialogVisible = false"
            v-if="dleteAccDialogVisible"
        >
            <div slot="popup-container" class="popup-container">
                <div class="delete-title">{{$t('accountManage.deleteAccountTitle')}}</div>
                <div class="delete-acc-group-c">
                    <span style="text-align: left; padding-left: 14px"
                        >{{$t('accountManage.isDeleteAccountGroup')}}
                        <span style="padding-left: 4px; font-weight: 600">{{
                            activeDeleteAccGroup.name
                        }}</span></span
                    >
                    <p
                        v-if="activeAccGroupHasA"
                        style="
                            text-align: left;
                            padding-left: 14px;
                            margin-top: 12px;
                            color: #909399;
                        "
                    >
                        <span
                            >{{$t('accountManage.activeAccGroupTip')}}</span
                        >
                        <br />
                        <br />
                        <el-select
                            size="small"
                            v-model="moveToId"
                            :placeholder="$t('accountManage.moveToAcc')"
                        >
                            <el-option
                                v-for="item in groupExitList()"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </p>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button
                    @click="dleteAccDialogVisible = false"
                    size="small"
                    plain
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    @click="deleteAccGroup"
                    style="background-color: #fe5965; color: white"
                    size="small"
                    >{{$t('common.delete')}}</el-button
                >
            </div>
        </delete-dialog>
        <popup @closeEvent="dialogMemberTree = false" v-if="dialogMemberTree">
            <div slot="popup-name" class="popup-name">{{$t('accountManage.memberTitle')}}</div>
            <div slot="popup-con" class="member-contanier">
            <staff-customer
                            :isShowUpDown="false"
                            @onNodeClick="onNodeClick"
                            :clientType="'ASKBOT_MEMBER'"
                            :memberInfo4Client="memberInfo4Client"
                            ref="staffCustomer"
            ></staff-customer>
            </div>
            <div slot="dialog-footer">
            <el-button @click="closeStaff" plain size="small">{{$t('common.cancel')}}</el-button>
            <el-button type="primary" @click="preserStaff" size="small">{{$t('common.confirm')}}</el-button>
            </div>
        </popup>
        <popup @closeEvent="closeEvent" v-if="setPopup">
           <div slot="popup-name" class="popup-name">{{$t('accountManage.setUp')}}</div>
              <div slot="popup-tip">{{$t('accountManage.setRule')}}</div>
            <div slot="popup-con"  class="set-popup-content">
               <!-- <div class="switch-box"> -->
                    <div class="switch-icon-box">
                        <span class="label">{{$t('accountManage.accountAutomaticallyDisabled')}}</span>
                        <el-switch
                            :width="42"
                            v-model="accountAutoDisabled"
                            active-color="#366AFF"
                            inactive-color="#E2E2E2"
                            active-icon-class="el-icon-check"
                            inactive-icon-class="el-icon-close">
                        </el-switch>
                        <span class="tips">{{$t('accountManage.setRuleTip')}}</span>
                    </div>
                    <div class="switch-icon-box">
                        <el-input v-model="loginDate" placeholder="请输入内容" type="number"></el-input>
                        <span class="text">{{$t('accountManage.setRuleTip1')}} , </span> <span class="label">{{$t('accountManage.accountAutomaticallyDisabled')}}</span>
                    </div>
                    <div class="switch-icon-box">
                        <span class="label">{{$t('accountManage.switch')}}</span>
                        <el-switch
                            :width="42"
                            v-model="departureAutoDisable"
                            active-color="#366AFF"
                            inactive-color="#E2E2E2"
                            active-icon-class="el-icon-check"
                            inactive-icon-class="el-icon-close">
                        </el-switch>
                    </div>
               <!-- </div> -->
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeEvent" plain round >{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="saveSet" round >{{$t('common.save')}}</el-button>
            </div>
        </popup>
        <popup
            v-if="showCascadePop"
            @closeEvent="showCascadePop = false"
            class="cascade-tree"
            :width="1000">
            <div slot="popup-name" class="popup-name">{{$t('accountManage.moduleTitle')}}</div>
            <div slot="popup-con" class="popup-con">
                <el-input :placeholder="$t('accountManage.filter')" size="small" v-model="filterText"></el-input>
                <div class="container-tree">
                <el-tree
                    :data="setCascadeOption"
                    :default-expand-all="true"
                    :expand-on-click-node="false"
                    ref="tree"
                    :filter-node-method="filterNodes"
                    node-key="value"
                    show-checkbox
                    :check-strictly="false"
                    :props="{isLeaf:'isLeaf'}"
                    @check-change="checkChange"
                >
                <!--  :props="{
                    disabled:'disabled'
                    }"-->
                    <span class="el-tree-title" slot-scope="{ node, data }" >
                        <span class="el-tree-title-content">{{ data.label }}</span>
                        <!-- <span class="check-icon" v-if="data.hierarchy === 'secondName'">
                            <el-checkbox v-model="data.checked" @change="changeFn(data)"></el-checkbox>
                        </span> -->
                    </span>
                </el-tree>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button size="small" plain @click="showCascadePop = false">{{$t('common.cancel')}}</el-button>
                <el-button type="primary" size="small" @click="saveCascadeOption">{{$t('common.confirm')}}</el-button>
            </div>
        </popup>
        <popup @closeEvent='headerFilterDialog = false' v-if="headerFilterDialog" class="set-header-popup">
            <div slot="popup-name">{{$t('accountManage.setHeaderTitle')}}</div>
            <div slot="popup-tip">{{$t('accountManage.setHeaderTip')}}</div>
            <div slot="popup-con-c" class="popup-con-c">
                <div class="popup-con-panels">
                    <div class="popup-con-panel-title left-title">{{$t('accountManage.notDisplayed')}}</div>
                    <div class="popup-con-panel-con">
                        <div v-for="(item,index) in hiddenTitle" :key="index" class="popup-con-panel-con-item">
                            <div v-if="item.fieldType !== 'EXPLAIN'">
                                <div class="popup-con-panel-con-item-content">
                                    <span>{{item.name}}</span>
                                    <span class="iconfont" @click="leftClick(item.enName,'left')">
                                        <i class="iconfont guoran-a-16-13"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="popup-con-panels">
                    <div class="popup-con-panel-title right-title">{{$t('accountManage.displayed')}}</div>
                    <div class="popup-con-panel-con">
                        <div id="filterResort">
                            <div class="popup-con-panel-con-item" v-for="(item,index) in showTitle" :key="index">
                                <div v-if="item.displayed && item.fieldType !== 'EXPLAIN'" class="popup-con-panel-con-item-content">
                                    <template v-if="item.defaulted">
                                        <span>{{item.name}}</span>
                                    </template>
                                    <template v-else>
                                        <span class="handles">{{item.name}}</span>
                                        <span class="iconfont" @click="rightClick(item.enName,'right')">
                                            <i class="el-icon-minus"></i>
                                        </span>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="popup-con-panel-tip" id="title-text">
                            {{$t('accountManage.changeOrder')}}
                        </div>
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="headerFilterDialog=false" plain size="small">{{$t('common.cancel')}}</el-button>
                <el-button @click="restoreDefault" plain size="small">{{$t('accountManage.restoreDefault')}}</el-button>
                <el-button type="primary" @click="titleClick" size="small">{{$t('common.confirm')}}</el-button>
            </div>
        </popup>
    </div>
</template>
<script>
import Popup from "@/components/popup";
import SideNav from "../../menu/SideNav.vue";
import TopHeader from "../../../components/top-header.vue";
import DeleteDialog from "../../../components/deleteDialogue.vue";
import pagination from "../../../components/pagination.vue";
import ManangeNav from "../../menu/ManageNave.vue";
import { operable } from "../js/operable";
import { operableAll } from "../js/operable";
import { formatTree } from "../staffManage/utils/formatTree";
import DataroleDetail from "../staffManage/components/DataroleDetail.vue";
import DefaultImage from "../../../components/DefaultImage.vue";
import StaffCustomer from "@/components/staffCustomer";
import OpenData from "@/components/openData";
import Sortable from "sortablejs";
import DateTem from "./components/dateTem.vue";
export default {
    name: "accountManageu",
    components: {
        OpenData,
        StaffCustomer,
        Popup,
        SideNav,
        TopHeader,
        DeleteDialog,
        pagination,
        ManangeNav,
        DataroleDetail,
        DefaultImage,
        DateTem
    },
    data() {
        return {
            activeGroupNameKey: "", // 账号组搜索
            filterGroupListOptions: [],

            inputSearchKey: "", // 账号搜索
            // 工作时间
            workTimes: [],
            defaultWorktimeId: "",

            sortIcon: "el-icon-arrow-down",
            sortUserSystemsIcon: "el-icon-arrow-down",
            dleteAccDialogVisible: false,
            activeDeleteAccGroup: "",
            activeDeleteAccGroupIndex: -1,
            moveToId: "",
            activeAccGroupHasA: false,

            openOrCloseAutomatedTitle: "",
            openOrCloseAutomatedText: "",
            openOrCloseAutomatedUserId: "",
            openOrCloseAutomated: false,
            openOrCloseHandleType: "禁用",
            deleteAutomated: false,
            deleteAutomatedId: "",
            saveAccountDisable: false,
            activeViewVisible: false,
            activeViewAcount: { account: "name" },
            tHeaderGroup: "-1", // 当前列表筛选分组
            activeListGroupName: this.$t('accountManage.belongAccountGroup'),
            groupListOptions: [{ id: "1", name: "组1" }],
            tHeaderType: "-10000", // 当前列表筛选账号类型
            activeListTypeName: this.$t('accountManage.accountType'),
            activePageInfo: {
                headerName: this.$t('accountManage.memberManagement'),
            },
            dialogVisible: false,
            moreDialogVisible: false,
            diaglogTitle: "",
            changeAcountForm: {
                emailArresss: "",
                fullName: "",
                accountType: "",
                groupNames: "",
                groupIds: "",
                roleId: "",
                accountAuthority: [],
                userId: "",
                mainName: "",
                editable: true,
                userDataPrivilegesIds: [0],
                userWorkTimeId: "",
                workOrderSetting:{
                    limitOrderNum:false,
                    maxNumOfWorkOrdersProcessed:30
                },
                languageCode:'',
                userSystems:[],
                mengNiuCompanyId:"",
                workNumber:'',
                tel:"",
                contactPersonId:null
            },
            isGroupEdit: false,
            accountList: [],
            accountData: { pages: "", total: "" },
            currentPage: 1,
            botList: [],
            botNum: "",
            currentAccountBotNum: "",
            botInfoList: [],
            roleList: [],
            botSkillList: [],
            botProps: {
                label: "name",
            },
            botKeys: "",
            botCheckList: [],
            privileges: [],
            skillCheckList: [],
            skillList: [],
            checked: true,
            selectTree: [],
            selectCurrentTree: [],
            skills: "",
            checkAll: false,
            isIndeterminate: false,
            //账号组管理
            classHandleVisible: false,
            groupList: [],
            pageshow: true,
            currentStatus: "",
            loading: true,
            isSuperAcconut: false,
            isEditAccount: false,
            isShowBotAuthority: true,
            accountTypes: [],
            rolePrivilegesList: [],
            botIsIndeterminate: false,
            skillBotId: 0,
            botIndex: 0,
            roleOperable: [],
            isRoleVisable: false,
            showSelect: false,
            orgTree: [],
            defaultExpandAll: false,
            defaultProps: {
                children: "children",
                label: "label",
            },
            valueName: "",
            valueNull: "",
            orgTreeName: "",
            orgTreeData: {},
            selectOrgValue: false,
            userDataPrivileges: [],
            workOrderType: [],
            workOrderTypeTree: [],
            typeTreeDefaultProps: {
                children: "children",
                label: "workOrderTypeName",
            },
            workOrderSelectIng: false,
            isShowWorkOrder: true, // 是否有工单入口权限
            dialogMemberTree:false,//绑定第三方账号选择员工
            memberInfo4Client:{
              type:'',
              bindType:'',
              memberId:'',
              name:'',
            },
            isWeWorkThirdData:false,
           isCheckData:null,
           setPopup:false,
           accountAutoDisabled:false,
           loginDate:90,
           departureAutoDisable:true,
           roleId:null,
           moduleSystem:[],
           showCascadePop:false,
           filterText:"",
           setCascadeOption:[],
           AXIOSSetCascadeOption:[],
           checkList:[],
           checkDatas:[],
           companyData:[],
            personList:[],
            userSystemsPopover:false,
            filterMengNiuCompanyName:this.$t('accountManage.company'),
            sortmengNiuCompanyIcon: "el-icon-arrow-down",
            filterMengNiuCompanyId:null,
            personMemberList:[],
            filterPersonMemberName:this.$t('accountManage.contactPerson'),
            sortPersonMemberIcon:"el-icon-arrow-down",
            filterContactPersonId:null,
            filterHierarchy:null,
            filterSystemName:null,
            chekeckedList:[],
            searchFirstName:'',
            activateStatus:null,
            activeStatusName:this.$t('accountManage.activationStatus'),
            sortGroupIcon: "el-icon-arrow-down",
            isMENGNIU:false,
            headerFilterDialog:false,
            hiddenTitle:[],
            showTitle:[],
            customTableHeader:[],
            headerData:[],
            templateInfo:{
                fieldColumns:[{
                   allFields:[] 
                }]
            }, // 模版信息
            orderFieldValue: {}, // 模版中自定义字段的值
            extInfoFieldValue:{},
            editActiveItem:{},
            hiddenTitleCopy:[],
            showTitleCopy:[],
        };
    },
    created() {
        let mainId = localStorage.getItem('_mainId');
        let mainIdList = ['51778574f46346ce96478d7b7fc2ca1d','ab0f90737c8b4f2d85ba2157e4473110','31623ccfe9dd4957bbd59c5823878bbe','3e83057a1bd74b79b86859ad619fdbbb'];
        if (mainIdList.indexOf(mainId) !== -1 ){
            this.isMENGNIU = true;
        } else {
            this.isMENGNIU = false;
        }
        console.log(this.isMENGNIU,'isMENGNIU');
    },
    mounted() {
        this.roleId = Number(localStorage.getItem("roleId"))
        if(this.isMENGNIU){
            this.getModuleData();
            this.getCompanyData();
            this.getContactPersonList();
        }
        this.queryWorkOrderGroup();
        this.accountGroupList();
        this.getRoleList();
        this.getTableTitle(()=>{
            this.getAccountList(1, 15);
            // this.getDynamic(paramer, '')
            
        })
        this.getBotList();
        this.rolePrivilegesList = this.getRolePrivileges();
        this.roleOperable = this.getRoleId();
        // this.getTree();
        this.getWorkOrderTypeTree();
        this.getWorkTimes();



        
    },
    watch: {
        orgTreeName: {
            handler(val) {
                this.$refs.tree && this.$refs.tree.filter(val);
            },
        },
        filterText(val) {
            this.$refs.tree && this.$refs.tree.filter(val);
        },
    },
    filters: {},
    computed: {
        workOrderTypeFilter() {
            let tampArr = [],
                tampIdArr = [];
            this.workOrderType.forEach((item) => {
                if (tampIdArr.indexOf(item.id) == -1) {
                    tampArr.push(item);
                    tampIdArr.push(item.id);
                }
            });
            return tampArr;
        },
        roleOper() {
            return function (roleId, userId, roleType) {
                return operableAll(roleType, roleId);
            };
        },
        operableAccount() {
            return function (type) {
                if (this.rolePrivilegesList.indexOf(type) !== -1) {
                    return true;
                }
            };
        },
        operableGroup() {
            return function (type) {
                return operable(type);
            };
        },
    },
    methods: {
        filterLanguage(code) {
            switch (code) {
                case 'cn':
                    return '中文'
                case 'en':
                    return 'English'
                default:
                    return '中文'
            }
        },
        changeGroupNameKey(value){
            if(value.trim() == "") {
                this.filterGroupListOptions = this.groupListOptions;
            } else {
                this.filterGroupListOptions = [];
                this.groupListOptions.forEach(item => {
                    if (item.name.indexOf(value.trim()) != -1) {
                        this.filterGroupListOptions.push(item);
                    }
                })
            }
        },
        getMoldTime(field){
            let otherTime = null
            if ((field.type==='TIME_PICKER' || field.type==='TIME_PICKER_RANGE')&& field.extInfo && field.extInfo.mold==='OTHER'){
                let pattern =  field.extInfo.pattern
                let yearVal = pattern.indexOf('YEAR')!==-1
                let monthVal = pattern.indexOf('MONTH')!==-1
                let dayVal = pattern.indexOf('DAY')!==-1
                let hourVal = pattern.indexOf('HOUR')!==-1
                let minuteVal = pattern.indexOf('MINUTE')!==-1
                let secondVal = pattern.indexOf('SECOND')!==-1
                if ((yearVal||monthVal||dayVal)&&(!hourVal&&!minuteVal&&!secondVal)){
                    otherTime ='DATE'
                }
                else if ((yearVal||monthVal||dayVal)&&(hourVal||minuteVal||secondVal)){
                    otherTime ='DATE_TIME'
                }
                else if((yearVal||monthVal||dayVal)&&(hourVal&&minuteVal&&!secondVal)) {
                    otherTime = 'DATE_HOUR_MINUTE'
                }
                else if ((hourVal||minuteVal||secondVal)&&(!yearVal&&!monthVal&&!dayVal)){
                    otherTime ='TIME'
                }
            }
            return otherTime
        },
        StrIsNull (str) {
            if ( str == "" ) return true;
            let regu = "^[ ]+$";
            let re = new RegExp(regu);
            return re.test(str);
        },
        RequiredVerification () {
            let flag = true,isHaveWorkTimeField = false,isHaveTypeMadeField = false
            console.log(1720, this.templateInfo);
            if (this.templateInfo != null && this.templateInfo != "null") {
                    for (let index = 0; index < this.templateInfo.fieldColumns.length; index++) {
                    const element = this.templateInfo.fieldColumns[index];
                    for (let j = 0; j < element.allFields.length; j++) {
                        const field = element.allFields[j];
                        if(field.type === 'TYPE_MATE'){
                            isHaveTypeMadeField = true;
                        }
                        if (field.required) {
                            if(field.type === 'WORKING_TIME'){
                                isHaveWorkTimeField = true;
                            }
                            if(field.type !== 'EMAIL' && field.type !== 'THIRD_PARTY_ACCOUNT' && field.type !== 'USER_NAME' && field.type !== 'ROLE' && field.type !== 'ACCOUNT_GROUP' && field.type !== 'DATA_PERMISSIONS' && field.type !== 'TYPE_MATE' && field.type !== 'WORKING_TIME' && field.type !== 'MAX_NUM_OF_ORDERS_PROCESSED'){
                                if (!this.orderFieldValue[field.id] || this.orderFieldValue[field.id].length === 0 || this.StrIsNull(this.orderFieldValue[field.id])) {
                                    this.$message({
                                        type: 'error',
                                        message: field.name + this.$t('accountManage.required')
                                    })
                                    return flag = false
                                }
                            }
                        }
                    }
                }
            }
            
            return {
                isHaveWorkTimeField:isHaveWorkTimeField,
                isHaveTypeMadeField:isHaveTypeMadeField,
                flag:flag
            }
            
        },
        changeFieldValues (item, value) {
            this.changeSelect(item)
        },
        changeSelect (field, value) {

        },
        // 根据角色获取相对应的模版信息
        getRoleTemplate(roleId,type){
            if(type === 'add'){
                this.$http.get('/api/account-template/find-one?roleId=' + roleId).then(res => {
                    if (res.data.code === '0') {
                        this.templateInfo = res.data.data;
                        this.handlerTemplate('add')
                    }
                 })
            }
        },
        // 处理模版中的数据 : orderFieldValue 
        handlerTemplate(type){
            console.log(this.templateInfo,'this.templateInfo');
            this.orderFieldValue = {};
            this.extInfoFieldValue = {};
            let CASCADERItem = {}
            this.templateInfo && this.templateInfo.fieldColumns && this.templateInfo.fieldColumns.forEach(fieldColumn => {
                fieldColumn.allFields.forEach(items => {
                    if(items.type !== 'EMAIL' && items.type !== 'THIRD_PARTY_ACCOUNT' && items.type !== 'USER_NAME' && items.type !== 'ROLE' && items.type !== 'ACCOUNT_GROUP' && items.type !== 'DATA_PERMISSIONS'){
                        if (items.type == 'CHECKBOX' || items.type === 'CASCADER') {
                            this.$set(this.orderFieldValue, items.id, [])
                            this.$set(this.extInfoFieldValue, items.id, [])
                        } else {
                            this.$set(this.orderFieldValue, items.id, '')
                            this.$set(this.extInfoFieldValue, items.id, '')
                        }
                        if(items.type === 'CASCADER'){
                            CASCADERItem = items;
                        }
                        if(type === 'add'){
                            if (items.defaultValue) {
                                this.$set(this.orderFieldValue, items.id, items.defaultValue)
                                this.$set(this.extInfoFieldValue, items.id, items.defaultValue)
                            } 
                            if (!items.relationDisplay) {
                                let val = items.type === 'CHECKBOX' ? [] : ""
                                this.$set(this.orderFieldValue, items.id, val)
                                this.$set(this.extInfoFieldValue, items.id, val)
                            }
                            if (items.templateField) {
                                items.field = items.templateField
                            }
                            if (!items.isSystemField) {
                                this.$set(this.orderFieldValue, items.id, '')
                                if (items.type === 'CHECKBOX' && (!items.defaultValue || items.defaultValue.length === 0)) {
                                    this.$set(this.orderFieldValue, items.id, [])
                                }
                                if (items.defaultValue && items.type !== 'EXPLAIN') {
                                    this.$set(this.orderFieldValue, items.id, items.defaultValue)
                                }
                                if (items.defaultValue && items.type !== 'EXPLAIN') {
                                    this.$set(this.orderFieldValue, items.id, items.defaultValue)
                                    if (items.type == "CASCADER") {
                                        this.$set(this.extInfoFieldValue, items.id, items.extInfo.fieldValue ? items.extInfo.fieldValue : items.defaultValue)
                                    }
                                }
                                
                            }   
                        }
                    }
                })
            })
            if(type === 'edit'){
                if(this.editActiveItem.taccount && this.editActiveItem.taccount.fieldValue){
                    for (const key in this.editActiveItem.taccount.fieldValue) {
                        const element = this.editActiveItem.taccount.fieldValue[key];
                        this.$set(this.orderFieldValue, key, element)
                        this.$set(this.extInfoFieldValue, key, element)
                        if(CASCADERItem.id === key){
                            if(CASCADERItem.extInfo&&CASCADERItem.extInfo.selectType ==='单选'){
                                this.$set(this.extInfoFieldValue, key, [{label:element[element.length-1],value:element[element.length-1]}])
                            } 
                        }
                    }
                }
            }
            console.log( this.orderFieldValue,' this.orderFieldValue');
            console.log( this.extInfoFieldValue,' this.extInfoFieldValue');
        },
        openTableHeader(){
            this.headerFilterDialog = true;
            this.showTitle = JSON.parse(JSON.stringify(this.showTitleCopy));
            this.hiddenTitle = JSON.parse(JSON.stringify(this.hiddenTitleCopy))
            this.$nextTick(() => {
                this.resortTitle()
            })
        },
        
        //点击左边不显示字段的事件
        leftClick(enName) {
            for (let i = 0; i < this.hiddenTitle.length; i++) {
                if (this.hiddenTitle[i].enName === enName) {
                    this.hiddenTitle[i].displayed = true;
                    this.showTitle.push(this.hiddenTitle[i])
                    this.hiddenTitle.splice(i, 1)
                }
            }
        },
        //点击右边显示字段的事件
        rightClick(enName) {
            for (let i = 0; i < this.showTitle.length; i++) {
                if (this.showTitle[i].enName === enName) {
                    this.hiddenTitle.push(this.showTitle[i])
                    this.showTitle.splice(i, 1)
                }
            }
        },
        //恢复默认按钮
        restoreDefault() {
            this.$http.put('/api/account-header/'+this.headerData.id,this.headerData.defaultHeaders).then(res => {
                if (res.data.code === '0') {
                    this.$message({
                        message: this.$t('accountManage.restoreDefaultSettings'),
                        duration: 2000,
                        type: 'success'
                    })
                    this.headerFilterDialog = false
                    this.getTableTitle()
                }
            })

        },
        //调整完表头顺序之后确认按钮
        titleClick() {
            let num = 0;
            this.showTitle.forEach(item => {
                item.fieldType = item.fieldType === 'STATUS' ? 'RADIO' : item.fieldType;
                if(item.displayed){
                    num ++;
                }
            })
            if(num === 0){
                this.$message({
                    message: this.$t('accountManage.atLeastOne'),
                    duration: 2000,
                    type: 'warning'
                })
                return false;
            }
            this.$http.put('/api/account-header/'+this.headerData.id,this.showTitle).then(res => {
                if (res.data.code === '0') {
                    this.$message({
                        message: this.$t('accountManage.setHeaderSuccess'),
                        duration: 2000,
                        type: 'success'
                    })
                    this.getTableTitle()
                } else {
                    this.$message({
                        message: this.$t('accountManage.setHeaderFail'),
                        duration: 2000,
                        type: 'error'
                    })
                }
            })
            this.headerFilterDialog = false
        },
        //获取工单表头
        getTableTitle(callback) {
            this.$http.get('/api/account-header').then(res => {
                if (res.data.code === '0') {
                    this.headerData = res.data.data
                    let displayNotTitle = []
                    this.showTitle = this.uniqueTitle(this.headerData.headers)
                    console.log(this.showTitle,'showTitle');
                    // 配置表头显示标签和受理人备注字段
                    // this.custom = JSON.parse(JSON.stringify(this.showTitle))
                    this.customTableHeader = [];
                    this.showTitle.forEach(showTitleItem => {
                        // 激活状态字段特殊处理
                        if(showTitleItem.enName === 'status' && showTitleItem.defaulted){
                            showTitleItem.fieldType = 'STATUS';
                        }
                        this.customTableHeader.push(showTitleItem);
                    })
                    this.customTableHeader = [...this.customTableHeader,...[{
                        defaulted:true,
                        enName:"userSystem",
                        name:this.$t('accountManage.module'),
                        fieldType:"",
                    },{
                        defaulted:true,
                        enName:"mengNiuCompanyName",
                        name:this.$t('accountManage.company'),
                        fieldType:"",
                    },{
                        defaulted:true,
                        enName:"workNumber",
                        name:this.$t('accountManage.jobId'),
                        fieldType:"",
                    },{
                        defaulted:true,
                        enName:"tel",
                        name:this.$t('accountManage.telephone'),
                        fieldType:"",
                    },{
                        defaulted:true,
                        enName:"contactPersonName",
                        name:this.$t('accountManage.contactPerson'),
                        fieldType:"",
                    }]]
                    this.headerData.unselectedHeaders && this.headerData.unselectedHeaders.forEach(item => {
                        displayNotTitle.push(item)
                    })
                    this.hiddenTitle = this.uniqueTitle(displayNotTitle)
                    this.showTitleCopy = JSON.parse(JSON.stringify(this.showTitle));
                    this.hiddenTitleCopy = JSON.parse(JSON.stringify(this.hiddenTitle))
                    this.$nextTick(() => {
                      if (this.$refs.accountTable){
                        this.$refs.accountTable.doLayout();
                      }
                    });
                    if (callback){
                        callback()
                    }
                }
            })
        },
        resortTitle() {
            let _this = this;
            let el = document.getElementById('filterResort');
            new Sortable(el, {
                animation: 200,
                sort: true,
                handle: '.handles',
                onUpdate: function (event) {
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = el.children[newIndex],
                        $oldLi = el.children[oldIndex];
                    // 先删除移动的节点
                    el.removeChild($li)
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if (newIndex > oldIndex) {
                        el.insertBefore($li, $oldLi)
                    } else {
                        el.insertBefore($li, $oldLi.nextSibling)
                    }
                    // 更新items数组
                    let item = _this.showTitle.splice(oldIndex, 1)
                    _this.showTitle.splice(newIndex, 0, item[0])
                    // 下一个tick就会走patch更新
                },
            });

        },
        uniqueTitle(arr) {
            for (var i = 0; i < arr.length; i++) {
                for (var j = i + 1; j < arr.length; j++) {
                    if (arr[i].enName === arr[j].enName) {         //第一个等同于第二个，splice方法删除第二个
                        arr.splice(j, 1);
                        j--;
                    }
                }
            }
            return arr
        },
        goAccountTemplate(){
            this.$router.push({
                name:"accountTemplate",
            })
        },
        statusVisibleChange(flag){
            if(flag){
                this.sortGroupIcon = "el-icon-arrow-up"
            } else {
                this.sortGroupIcon = "el-icon-arrow-down"
            }
        },
        handleCommand(command){
            this.activateStatus = command;
            if(this.activateStatus === 0){
                this.activeStatusName = this.$t('accountManage.notActive')
            } else if(this.activateStatus === 1){
                this.activeStatusName = this.$t('accountManage.inUse')
            } else if(this.activateStatus === 2){
                this.activeStatusName = this.$t('accountManage.disabled')
            } else if(this.activateStatus === 3){
                this.activeStatusName = this.$t('accountManage.notLoggedIn')
            } else if(this.activateStatus === 4){
                this.activeStatusName = this.$t('accountManage.resignation')
            }   else {
                this.activeStatusName = this.$t('accountManage.activationStatus')
            }
            this.currentPage = 1;
            this.getAccountList(this.currentPage,15);
        },
        searchAccount(value){
            this.getAccountList(1, 15)
        },
        changeSearchAccount(value){
            if (value == "") {
                this.getAccountList(1, 15)
            }
        },
        // 设置
        clickSet(){
            this.setPopup = true;
            this.$http.get( this.requestUrl.companyManage.queryCompanyAccountAutoDisable)
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        console.log(res.data);
                        this.accountAutoDisabled =  res.data.data.autoDisable ? res.data.data.autoDisable.accountAutoDisable : false;
                        this.loginDate =  res.data.data.autoDisable ?  res.data.data.autoDisable.autoDisableNotLoginDays : 90;
                        this.departureAutoDisable =  res.data.data.autoDisable ?  res.data.data.autoDisable.departureAutoDisable : true;
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
            
            
        },
        closeEvent(){
            this.setPopup = false;
        },
        saveSet(){
            var regNumber = /^\+?[1-9][0-9]*$/;
            if(regNumber.test(this.loginDate) == false) {
                this.$message.warning(this.$t('accountManage.notLoggedDay'));
                return false;
            }
            this.$http.put( this.requestUrl.companyManage.setCompanyAccountAutoDisable,{
                accountAutoDisable:this.accountAutoDisabled,
                autoDisableNotLoginDays:this.loginDate,
                departureAutoDisable:this.departureAutoDisable
            })
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        this.setPopup = false;
                        this.accountAutoDisabled = false;
                        this.loginDate = 90;
                        this.departureAutoDisable = true;
                        this.$message.success(this.$t('accountManage.setSuccess'));

                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        // 模块/系统选中处理
        checkChange(datas){
            this.chekeckedList = this.$refs.tree.getCheckedNodes() || [];
        },
        // 处理tree结构选中   父子节点联动
        judgeCheckedTree(treeData){
            // this.setCascadeOption
            treeData.forEach((item,index) => {
                // 如果父级选中
                if(item.checked){
                    if(item.children && item.children.length > 0){
                        item.children.forEach((childrenItem,childrenIndex) => {
                            childrenItem.checked = true;
                            this.$set(childrenItem,'checked',true);
                            this.$forceUpdate();
                        })
                    }
                } else {
                    if(item.children && item.children.length > 0){
                        item.children.forEach((childrenItem,childrenIndex) => {
                            if(childrenItem.checked){

                            }
                        })
                    }
                }
            })
        },
        forceUpdateFn(){
            this.$forceUpdate();
        },
        changeFn(data){
            data.checked = !data.checked;
            this.judgeCheckedTree(this.setCascadeOption);
        },
        handleTreeClickFilter(data,node,row){
            console.log(data);
            if(data.hierarchy === "secondName"){
                this.searchFirstName = data.firstName;
            }
            console.log(this.searchFirstName,'this.firstName---');
            this.filterSystemName = data.label;            
            this.filterHierarchy = data.hierarchy;
            this.userSystemsPopover = false;
            this.sortUserSystemsIcon = "el-icon-arrow-down"
            this.getAccountList(1, 15);
        },
        visibleChange(flag){
            if(flag){
                this.sortmengNiuCompanyIcon = "el-icon-arrow-up"
            } else {
                this.sortmengNiuCompanyIcon = "el-icon-arrow-down"
            }
        },
        handleCommandStatus(command){
            this.filterMengNiuCompanyId = command;
            this.companyData.forEach((item,index) => {
                if(item.id == command){
                    this.filterMengNiuCompanyName = item.name;
                }
            })
            this.getAccountList(1, 15);
        },
        visibleChangePersonMember(flag){
            if(flag){
                this.sortPersonMemberIcon = "el-icon-arrow-up"
            } else {
                this.sortPersonMemberIcon = "el-icon-arrow-down"
            }
        },
        handleCommandPersonMember(command){
            this.filterContactPersonId = command;
            this.companyData.forEach((item,index) => {
                if(item.id === command){
                    this.filterPersonMemberName = item.name;
                }
            })
            this.getAccountList(1, 15);
        },
        openUserSystems(){
            this.sortUserSystemsIcon = this.sortUserSystemsIcon == "el-icon-arrow-down" ? "el-icon-arrow-up" : "el-icon-arrow-down";
            if(this.sortUserSystemsIcon === 'el-icon-arrow-up'){
                this.userSystemsPopover = true;
            } else {
                this.userSystemsPopover = false;
                this.getAccountList(1, 15);
            }
        },
        // 获取对接人列表
        getContactPersonList(){
            this.$http.get("api/account/getContactPersonList").then((res) => {
                if(res.data.code === '0'){
                    this.personList = res.data.data || [];
                    let personMemberList = [];
                    this.personList.forEach((item,index) => {
                        personMemberList = [...personMemberList,...item.accountInfos]
                    })
                    this.personMemberList = this.uniqueContactPerson(personMemberList);
                }
            });
        },
        uniqueContactPerson(arr){
            const res= new Map()
            return arr.filter((a)=> !res.has(a.id) && res.set(a.id,1) && !res.has(a.name) && res.set(a.name,1))
        },
        judgeTel(){
            // 匹配手机号
            var reg = /^[1][3,4,5,6.7,8,9][0-9]{9}$/
            if(!reg.test(this.changeAcountForm.tel)){
                this.$message.warning(this.$t('accountManage.checkPhone'))
            }
        },
        // 获取工单数据表中公司名称
        getCompanyData(){
            this.$http.get("api/account/getDataTemplate").then((res) => {
                if(res.data.code === '0'){
                    let data = res.data.data
                    this.companyData = [];
                    if(!data.code) {
                        if((JSON.stringify(res.data.data) != "{}")){
                            for (let key in res.data.data) {
                                this.companyData.push({
                                    id:Number(key),
                                    name:res.data.data[key]
                                })
                            }
                        }
                        console.log(this.companyData,'companyData');
                    }
                    
                }
            });
        },
        // 获取工单模板中配置的系统名称
        getModuleData(){
            this.$http.get("api/account/getTemplateSysName").then((res) => {
                if(res.data.code === '0'){
                    this.setCascadeOption = this.judgeTree(res.data.data && res.data.data.options ? res.data.data.options : [],false);
                    this.AXIOSSetCascadeOption = JSON.parse(JSON.stringify(this.setCascadeOption))
                    console.log(this.setCascadeOption,'setCascadeOption');
                }
            });
        },
        judgeTree(treeData,isChildren){
            treeData.forEach((item,index) => {
                item.selectAll = 'false';
                item.firstName = '';
                item.hierarchy = 'firstName'
                if(item.children && item.children.length > 0){
                    item.isLeaf = false;
                    item.children.forEach((childrenItem,childrenIndex) => {
                        childrenItem.firstName = item.label;
                        childrenItem.selectAll = 'false';
                        childrenItem.hierarchy = 'secondName'
                    })
                } else {
                    item.isLeaf = true;
                    item.firstName = '';
                }
            })
            return treeData;
        },
        changeFn(data){
             console.log(data,'点击--');
        },
        handleTreeClick(data,node,row) {
           
            // this.$nextTick(() =>{
            //     data.checked = !data.checked;
            //     this.$forceUpdate();

            // })
            // data.checked = !data.checked
        },
        handleCheckChange(data, checked,) {
            // console.log(data,checked,'选中');
            // if(!checked) return
            // this.$nextTick(() =>{
            //     this.$set(data,'checked',checked);
            //     this.$refs.tree.setChecked(data,checked)
            // })
        },
        filterNodes(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        openCascadeOption(){
            this.showCascadePop = true;
            this.$nextTick(() => {
                this.setCascadeOption.forEach((v,i) => {
                    this.moduleSystem.forEach((item,index) => {
                        if(item.selectAll === 'true'){
                            if(v.children && v.children.length > 0 && item.firstName === v.label){
                                v.children.forEach((childrenItem,childrenIndex) => {
                                    this.$refs.tree.setChecked(childrenItem.value,true)
                                })
                            }
                            this.$refs.tree.setChecked(item.firstName,true)
                        } else {
                            this.$refs.tree.setChecked(item.label,true)
                        }
                    })
                })
            })
        },
        async saveCascadeOption(){
            this.changeAcountForm.userSystems = [];
            this.checkDatas = [];
            let moduleSystem = [];
            console.log(this.chekeckedList,'chekeckedList');
            this.chekeckedList.forEach((chekeckedListItem,chekeckedListIndex) => {
                this.setCascadeOption.forEach((item,index) => {
                    if(chekeckedListItem.hierarchy === 'firstName' && chekeckedListItem.value === item.value){
                        chekeckedListItem.selectAll = 'true'; 
                        item.selectAll = 'true'; 
                        moduleSystem.push(Object.assign(item,{
                            firstName:item.label
                        }));
                    } else {
                        if(item.children && item.children.length > 0){
                            item.children.forEach((childrenItem,childrenIndex) => {
                                if(childrenItem.value === chekeckedListItem.value){
                                    if(item.selectAll !== 'true'){
                                        moduleSystem.push(childrenItem);
                                    }
                                }
                            })
                        }
                    }
                })
            })
            this.moduleSystem = moduleSystem;
            this.changeAcountForm.userSystems = moduleSystem;
            console.log(this.moduleSystem,'moduleSystem');
            this.showCascadePop = false;
        },
        // 获取工作时间列表
        getWorkTimes(){
            this.$http
                .get(
                    this.requestUrl.workTime.queryAllUserWorkTime)
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        this.workTimes = [...res.data.data];
                        this.defaultWorktimeId = this.workTimes[0].id;
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        openSortSelect() {
            $(".sort-order-r-select").slideToggle(300);
            this.sortIcon =
                this.sortIcon == "el-icon-arrow-down"
                    ? "el-icon-arrow-up"
                    : "el-icon-arrow-down";
        },
        openactiveListGroupSelect() {
            $(".sort-order-r-group-select").slideToggle(300);
            this.sortGroupIcon =
                this.sortGroupIcon == "el-icon-arrow-down"
                    ? "el-icon-arrow-up"
                    : "el-icon-arrow-down";
        },
        groupExitList() {
            let arr = [];
            this.groupList.forEach((item) => {
                if (item.id != this.activeDeleteAccGroup.id) {
                    arr.push(item);
                }
            });
            return arr;
        },
        confirmDeleteGroup() {
            this.$http
                .delete(
                    this.requestUrl.accountGroup.deleteAccountGroup +
                        "?groupId=" +
                        this.activeDeleteAccGroup.id
                )
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        this.$message.success(this.$t('index.groupDeleteSuccess'));
                        this.dleteAccDialogVisible = false;
                        this.groupList.splice(
                            this.activeDeleteAccGroupIndex,
                            1
                        );
                        this.getAccountList(this.currentPage, 15);
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        deleteAccGroup() {
            if (this.activeAccGroupHasA && this.moveToId === "") {
                this.$message.error(this.$t('index.selectAccountMsg'));
            } else {
                if (!this.activeAccGroupHasA) {
                    this.confirmDeleteGroup();
                } else {
                    this.$http
                        .get(
                            this.requestUrl.accountGroup
                                .moveUserToSpecifiedGroup +
                                "?inGroupId=" +
                                this.moveToId +
                                "&outGroupId=" +
                                this.activeDeleteAccGroup.id
                        )
                        .then((res) => {
                            if (res.data && res.data.code === "0") {
                                this.moveToId = "";
                                this.confirmDeleteGroup();
                            } else {
                                this.$message.error(res.data.message);
                            }
                        });
                }
            }
        },
        closeHandleAcountDialog() {
            this.dialogVisible = false;
            this.workOrderType = [];
            this.$forceUpdate();
        },
        elDropdownvisibleChange(val) {
            this.workOrderSelectIng = val;
        },
        WorkOrderTypeHandleCheckChange(node, val, c_val, index) {
            if (val) {
                node.treeIndex = index;
                node.adminAssign = true;
                node.userId = localStorage.getItem("_uid");
                this.workOrderType.push(node);
            } else {
                this.workOrderType.forEach((item, index) => {
                    if (item.id == node.id) {
                        this.workOrderType.splice(index, 1);
                    }
                });
            }
            this.workOrderType = [...this.workOrderType];
        },
        closeWorkOrder(index) {
            let refTree = "ordertree_" + this.workOrderType[index].treeIndex;
            let oldTreeSelect = this.$refs[refTree] ? this.$refs[refTree][0].getCheckedKeys():[];
            let currentTreeSelect = [];
            oldTreeSelect.forEach((item) => {
                if (item != this.workOrderType[index].id) {
                    currentTreeSelect.push(item);
                }
            });
            if (this.$refs[refTree]){
              this.$refs[refTree][0].setCheckedKeys(currentTreeSelect);
            }
            this.workOrderType.splice(index, 1);
        },
        getWorkOrderTypeTree() {
            let url = this.requestUrl.capital.workOrderTypeTreeByUser;
            this.$http.get(url).then((res) => {
                if (res.data.code === "0") {
                    this.workOrderTypeTree = res.data.data.map(
                        (item, index) => {
                            item.treeIndex = index;
                            return [item];
                        }
                    );
                } else {
                    this.$message({
                        message: res.data.message,
                        type: "error",
                        duration: 2000,
                    });
                }
            });
        },
        formatRoleName(ids) {
            let nameList = [];
            if (ids == null || ids == "null" || ids == "") {
                nameList = [
                    {
                        id: "empty",
                        privilegesName: this.$t('accountManage.noSet'),
                    },
                ];
            } else {
                let isAll = false;
                JSON.parse(ids).forEach((id) => {
                    if (id == 0) {
                        isAll = true;
                    } else {
                        if (id == -1) {
                            nameList.push({
                                id: -1,
                                privilegesName: this.$t('accountManage.visiblePermissions'),
                                description: this.$t('accountManage.visiblePermissionsTip'),
                            });
                        } else if (id == 99999) {
                            nameList.push({
                                id: 99999,
                                privilegesName: this.$t('accountManage.allOrder'),
                                description: this.$t('accountManage.allOrderTip'),
                            });
                        } else {
                            this.userDataPrivileges.forEach((cell) => {
                                if (id == cell.id) {
                                    nameList.push(cell);
                                }
                            });
                        }
                    }
                });
                if (isAll) {
                    nameList = [
                        {
                            id: 0,
                            privilegesName: this.$t('accountManage.allPermission'),
                        },
                    ];
                }
            }
            return nameList;
        },
        lookMoreRole(index) {
            this.activeViewAcount = this.accountList[index];
            this.activeViewVisible = true;
            this.$nextTick(() => {
                this.$refs.dataroled.getInfoList();
            });
        },
        navigateToAddPriv() {
            this.$router.push({
                name: "_dataRoleManage",
                params: {
                    showAdd: true,
                },
            });
        },
        changeUserDataPrivileges(val) {
            this.changeAcountForm.userDataPrivilegesIds = [...val];
            this.$forceUpdate();
        },
        // 判断是否有权限操作工单
        canHandleWorkOrder(list) {
            let flag = false;
            if (list.indexOf(0) != -1) {
                flag = true;
            } else {
                this.userDataPrivileges.forEach((privilege) => {
                    if (list.indexOf(privilege.id) != -1) {
                        if (
                            privilege.workOrderDataPrivileges != null &&
                            privilege.workOrderDataPrivileges != "[]" &&
                            JSON.stringify(privilege.workOrderDataPrivileges) !=
                                "[]"
                        ) {
                            flag = true;
                        }
                    }
                });
            }
            return flag;
        },
        // 获取指定组工单
        queryWorkOrderGroup() {
            this.$http
                .get(
                    this.requestUrl.userDataPrivilege
                        .getUserDataPrivilegesAllList
                )
                .then((res) => {
                    if (res.data.code == 0) {
                        this.userDataPrivileges = res.data.data;
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        handleGroupCommand(val) {
            this.tHeaderGroup = val;
            if (val == -1) {
                this.activeListGroupName = this.$t('accountManage.belongAccountGroup');
            } else {
                this.groupListOptions.forEach((item) => {
                    if (item.id == val) {
                        this.activeListGroupName = item.name;
                    }
                });
            }
            
            this.activeGroupNameKey = "";
            this.changeGroupNameKey("");

            this.getAccountList(1, 15);
            this.openactiveListGroupSelect();
        },
        handleTypeCommand(val) {
            this.tHeaderType = val;
            if (val == -10000) {
                this.activeListTypeName = this.$t('accountManage.accountType');
            } else {
                this.accountTypes.forEach((item) => {
                    if (item.id == val) {
                        this.activeListTypeName = item.name;
                    }
                });
            }
            this.getAccountList(1, 15);
            this.openSortSelect();
        },
        selectOrg() {},
        //绑定第三方账号时选择员工
        openMemberPopup(){
          this.dialogMemberTree = true;
        },
        onNodeClick(data,type,memberInfo,row){
          console.debug('onNodeClick',data,type,memberInfo,row);
          this.isCheckData = data;
        },
        closeStaff(){
            this.isCheckData = null;
            this.dialogMemberTree = false;
        },
        preserStaff(){
            if (this.isCheckData){
            this.valueName = this.isCheckData.id;
            this.memberInfo4Client.name = this.isCheckData.label;
            this.memberInfo4Client.memberId = this.isCheckData.id;
            this.isWeWorkThirdData = (this.isCheckData.type === 0 && this.isCheckData.originData && this.isCheckData.originData.source === 0) || (this.isCheckData.isWeWorkThirdData) ? true : false;
            if (!this.isWeWorkThirdData){
                this.changeAcountForm.fullName = this.isCheckData.label;
            }
            else {
                this.changeAcountForm.fullName = ""
            }
            this.memberInfo4Client.bindType = this.isCheckData.originData ? this.isCheckData.originData.source:'';
            }
            this.dialogMemberTree = false;
        },
        onBlur() {
            /*this.$refs.fullName.blur()*/
            this.showSelect = false;
        },
        openTree() {
            this.showSelect = true;
        },
        fullInput() {
            this.orgTreeName = "";
        },
        mouseleaver() {
            if (this.selectOrgValue) {
                this.showSelect = false;
            } else {
                this.showSelect = true;
            }
        },
        mouseEnter() {
            this.selectOrgValue = false;
            this.showSelect = true;
        },
        changeCheckedOrg(node, data) {
            this.orgTreeData = data;
            if (data.type === 0) {
                this.changeAcountForm.fullName = data.label;
                this.valueName = data.id;
                this.selectOrgValue = true;
                this.showSelect = false;
            } else {
                this.selectOrgValue = false;
            }
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        //获取员工树
        getTree() {
            this.$http
                .get(this.requestUrl.department.getDepartmentMemberTree)
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        this.orgTree = formatTree(res.data.data);
                        if (this.orgTree[0].total > 300) {
                            this.defaultExpandAll = false;
                        } else {
                            this.defaultExpandAll = true;
                        }
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        // 获取机器人列表
        getBotList() {
            this.$http.get("api/skill/tree").then((res) => {
                if (res.data.data !== null) {
                    for (let i = 0; i < res.data.data.length; i++) {
                        this.botInfoList.push({
                            botId: res.data.data[i].id,
                            botName: res.data.data[i].name,
                        });
                        this.botList.push({
                            botId: res.data.data[i].id,
                            botName: res.data.data[i].name,
                            botIsIndeterminate: false,
                            checked: true,
                            skillList: [],
                            botSkillList: res.data.data[i].children,
                        });
                    }
                    if (this.botList.length > 0) {
                        this.skillList = this.botList[0].botSkillList;
                    }
                    this.botNum = this.botList.length;
                }
            });
        },
        // 获取角色列表
        getRoleList() {
            this.$http.get(this.requestUrl.role.getRoleList).then((res) => {
                if (res.data && res.data.code === "0") {
                    this.roleList = res.data.data;
                    let accountTypes = [];
                    let id = localStorage.getItem("roleId");
                    let RoleId = this.getRoleId();
                    if (RoleId.indexOf(0) !== -1) {
                        this.accountTypes = res.data.data;
                    } else {
                        accountTypes = res.data.data;
                        this.accountTypes = [];
                        for (let i = 0; i < accountTypes.length; i++) {
                            if (RoleId.indexOf(accountTypes[i].id) !== -1) {
                                this.accountTypes.push(accountTypes[i]);
                            }
                        }
                        if (RoleId.indexOf(-2) !== -1) {
                            accountTypes.forEach((item) => {
                                if (item.id === Number(id)) {
                                    this.accountTypes.push(item);
                                }
                            });
                        }
                    }
                    for (let i = 0; i < this.accountTypes.length; i++) {
                        if (this.accountTypes[i].id === 1) {
                            this.accountTypes.splice(i, 1);
                        }
                    }
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        //获取知识库
        getSkill() {},
        getAccountList(pageNo, pageSize) {
            this.accountList = [];
            let params = { pageNo: pageNo, pageSize: pageSize };
            if (this.tHeaderGroup != -1) {
                params.groupId = this.tHeaderGroup;
            }
            if (this.tHeaderType != -10000) {
                params.roleId = this.tHeaderType;
            }
            console.log(this.searchFirstName,'this.firstName');
            params.hierarchy = this.filterHierarchy;
            params.systemName = this.filterSystemName;
            params.mengniuCompanyId = this.filterMengNiuCompanyId;
            params.contactPersonId = this.filterContactPersonId;
            params.status = this.activateStatus;
            if(this.filterHierarchy === 'secondName'){
                params.firstName = this.searchFirstName;
            }
            let url = this.requestUrl.accountManage.getAccountList;
            if (this.inputSearchKey.trim() !== "") {
                url = url + "?keyword=" + this.inputSearchKey.trim();
            }
            this.$http
                .get(url, {
                    params: params,
                })
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        res.data.data.list.forEach((item,index) => {
                            item.userSystems = item.userSystem && JSON.parse(item.userSystem) || [];
                            item.mengNiuCompanyName = '';
                            item.contactPersonName = '';
                            item.groupIds = [73,74]
                            this.companyData.forEach((v,i) => {
                                if(v.id == item.mengNiuCompanyId){
                                    item.mengNiuCompanyName = v.name;
                                }
                            })
                            this.personMemberList.forEach((v,i) => {
                                if(v.id === item.contactPersonId){
                                    item.contactPersonName = v.name;
                                }
                            })
                        })
                        this.accountList = res.data.data.list;
                        this.accountData.total = res.data.data.total;
                        this.getJsonLength(res.data.data.list.privileges);
                        //表格发生错位的原因，是由于table在数据请求后，渲染异常的问题；因此，解决办法就是接口请求成功后重新渲染,让table重新布局。
                        this.$nextTick(() => {
                            this.$refs.accountTable.doLayout();
                        });
                    } else {
                        this.$message.error(res.data.message);
                    }
                    this.loading = false;
                });
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.pageshow = false; //让分页隐藏
            this.$nextTick(() => {
                //重新渲染分页
                this.pageshow = true;
            });
            this.getAccountList(val, 15);
        },
        // 全选反选
        handleCheckAllChange(val) {
            let botIds = [];
            this.botList.forEach((item) => {
                item.checked = val ? true : false;
                botIds.push(item.botId);
            });
            this.skillList = this.botList[0].botSkillList;
            this.skillCheckList = [];
            this.botCheckList = val ? botIds : [];

            if (!val) {
                this.botList.forEach((item) => {
                    item.skillList = [];
                });
                this.skillCheckList = [];
            }
            if (val) {
                this.botList.forEach((item) => {
                    let botSkill = [];
                    item.botSkillList.forEach((botSkillItem) => {
                        botSkill.push(botSkillItem.id);
                    });
                    item.skillList = botSkill;
                });
                this.skillCheckList = this.botList[0].skillList;
                this.checkAll = true;
            }
            this.checkAll = val ? true : false;
            this.isIndeterminate = val ? false : true;
        },
        // 选择机器人权限
        handleCheckedBotChange(value, botId) {
            this.skillBotId = botId;
            let checkedCount;
            this.botCheckList = [];
            this.botList.forEach((item) => {
                if (item.checked === true) {
                    this.botCheckList.push(item.botId);
                }
                if (item.botId === botId) {
                    this.skillCheckList = [];
                    this.skillList = item.botSkillList;
                    if (value) {
                        let botSkill = [];
                        item.botSkillList.forEach((botSkillItem) => {
                            botSkill.push(botSkillItem.id);
                        });
                        item.skillList = botSkill;
                        this.skillCheckList = item.skillList;
                    } else {
                        this.skillCheckList = [];
                        item.skillList = [];
                    }
                }
            });
            checkedCount = this.botCheckList.length;
            this.checkAll = checkedCount === this.botList.length;
            this.isIndeterminate =
                checkedCount > 0 && checkedCount < this.botList.length;
        },
        //选择知识库的权限
        handleCheckedSkillChange(value) {
            /*this.skillCheckList=value*/
            this.botList.forEach((item, index) => {
                if (item.botId === this.skillBotId) {
                    item.skillList = value;
                    item.checked = value.length > 0 ? true : false;
                    /*if (item.skillList.length > 0){
                        this.skillCheckList=item.skillList
                    }*/
                }
                if (item.checked) {
                    this.botCheckList.push(item.botId);
                } else {
                    this.botCheckList.splice(index, 1);
                }
            });

            this.botCheckList = this.botCheckList.distinct();
            this.checkAll = this.botCheckList.length === this.botList.length;
            this.isIndeterminate =
                this.botCheckList.length > 0 &&
                this.botCheckList.length < this.botList.length;
        },
        clickBot(botId) {
            this.skillBotId = botId;
            this.botList.forEach((item) => {
                if (item.botId === botId) {
                    this.skillList = item.botSkillList;
                    if (item.skillList !== undefined) {
                        this.skillCheckList = item.skillList;
                    }
                }
            });
        },
        // 获取某机器人知识库列表
        getSkillList(value, botId) {
            this.$http
                .get(this.requestUrl.bot.getSkillList + "?bid=" + botId)
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        this.skillList = res.data.data;
                        let skillCheck = [];
                        this.skillCheckList = [];
                        this.skillList.forEach((item) => {
                            skillCheck.push(item.id);
                        });
                        let botSkillList = JSON.parse(
                            JSON.stringify(this.skillList)
                        );
                        this.botList.forEach((item) => {
                            if (item.botId === botId) {
                                item.botSkillList = botSkillList;
                            }
                        });
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        setCheckedKeys(val) {
            this.$refs.tree.setCheckedNodes(this.botSkillList);
        },

        //合并数组，无知识库暂不用
        mergeJson(arr) {
            let botIds = [];
            let res = [];
            if (arr != null && arr.length > 0) {
                for (let i = 0, len = arr.length; i < len; i++) {
                    let node = arr[i];
                    if (botIds.indexOf(node.botId) == -1) {
                        let item = {
                            botId: node.botId,
                            skillIds: [node.skillIds],
                        };
                        res.push(item);
                        botIds.push(node.botId);
                    } else {
                        for (let j = 0, l = res.length; j < l; j++) {
                            if (res[j].botId == node.botId) {
                                res[j].skillIds.push(node.skillIds);
                                break;
                            }
                        }
                    }
                }
            }
            return res;
        },
        // 判断当前帐号类型是否有配置擅长工单权限
        arbitrationWorkOrderPri(accountId) {
            let flag = false;
            console.log(this.accountTypes,'this.accountTypes');
            this.accountTypes.forEach((item) => {
                if (item.id == accountId) {
                    item.privileges && item.privileges.privileges.forEach((pri) => {
                        pri.children && pri.children.forEach((priCell) => {
                            priCell.children &&priCell.children.forEach((priIn) => {
                                if (priIn.id == "PORTAL_ENTRANCE_WORKORDER" || priIn.id == 'WORKORDER') {
                                    console.log(priIn,'priIn');
                                    flag = priIn.checked;
                                }
                                // priIn.children && priIn.children.forEach(p =>{
                                //     // 编辑工单的权限
                                //     if (p.id == "GNAV0004-02") {
                                //         flag = p.checked;
                                //          console.log(p,'p');
                                //         debugger
                                //     }
                                // })
                            });
                        });
                    });
                }
            });
            console.log(flag,'flag');
            return flag;
        },
        changeAccountType(value) {
            let flag = true;
            this.accountTypes.forEach((item, index) => {
                if (item.id === value) {
                    if (item.name === "上下游负责人" && item.isDefault) {
                        flag = false;
                        this.changeAcountForm.accountType = "";
                        this.$message({
                            message: this.$t('accountManage.upDownTip'),
                            type: "error",
                            duration: 2000,
                        });
                    }
                }
            });
            if (!flag) return;
            this.getRRoleDetail(value);
            this.getRoleTemplate(value,'add')
            this.isShowBotAuthority = true;
            this.changeAcountForm.userDataPrivilegesIds = [0];
            this.isShowWorkOrder = this.arbitrationWorkOrderPri(value);
            console.log(this.orderFieldValue,'this.orderFieldValue');
        },
        //获取角色详情
        getRRoleDetail(value) {
            let url = "api/role/detail/" + value;
            this.$http.get(url).then((res) => {
                if (res.data.code === "0") {
                    if (res.data.data.privileges !== null) {
                        res.data.data.privileges.privileges.forEach((item) => {
                            if (item.id === "OPEN") {
                                if (item.checked) {
                                    this.isRoleVisable = true;
                                } else {
                                    this.isRoleVisable = false;
                                }
                            }
                        });
                    }
                }
            });
        },
        getJsonLength(jsonData) {
            var jsonLength = 0;
            for (var item in jsonData) {
                jsonLength++;
            }
            //return jsonLength;
            this.currentAccountBotNum = jsonLength;
        },
        addAccount() {
            this.templateInfo = {
                fieldColumns:[{
                   allFields:[] 
                }]
            };
            this.isShowBotAuthority = true;
            this.dialogVisible = true;
            this.isSuperAcconut = false;
            this.isEditAccount = false;
            this.isRoleVisable = false;
            this.diaglogTitle = this.$t('accountManage.addAccount');
            this.changeAcountForm.emailArresss = "";
            this.changeAcountForm.fullName = "";
            this.changeAcountForm.roleName = "";
            this.changeAcountForm.groupNames = "";
            this.changeAcountForm.accountType = "";
            this.changeAcountForm.mainName = "";
            this.changeAcountForm.userWorkTimeId = this.defaultWorktimeId;
            this.changeAcountForm.userDataPrivilegesIds = [0];
            this.isShowWorkOrder = false;
            this.changeAcountForm.editable = true;
            this.changeAcountForm.workOrderSetting = {
                limitOrderNum:false,
                maxNumOfWorkOrdersProcessed:30
            }
            this.changeAcountForm.languageCode = 'cn'
            this.valueName = "";
            this.orgTreeName = "";
            this.accountGroupList();
            this.botCheckList = [];
            this.checkAll = false;
            this.botList.forEach((item) => {
                item.checked = false;
                item.skillList = [];
            });
            if (this.botList.length > 0) {
                this.skillBotId = this.botList[0].botId;
                this.skillList = this.botList[0].botSkillList;
            }
            this.skillCheckList = [];
            this.memberInfo4Client = {
              bindType: '',
              name:"",
              memberId: ""
            }
            this.changeAcountForm.workNumber = '';
            this.changeAcountForm.mengNiuCompanyId = null;
            this.changeAcountForm.contactPersonId = null;
            this.changeAcountForm.tel = '';
            this.moduleSystem = [];
        },
        handlerModule(datas){
            console.log(datas,'datas');
            // this.showCascadePop = true;
            this.moduleSystem = [];
            if(datas && datas.length > 0){
                // this.setCascadeOption.forEach((v,i) => {
                    datas.forEach((item,index) => {
                        if(item.selectAll === 'true'){
                            // this.$nextTick(() => {
                            //     this.$refs.tree.setChecked(item.firstName,true)
                            // })
                            this.moduleSystem.push({
                                label:item.firstName,
                                firstName:item.firstName,
                                selectAll:'true',
                                hierarchy:'firstName'
                            })
                        } else {
                            if(item.secondName && item.secondName !== ''){
                                // this.$nextTick(() => {
                                //     this.$refs.tree.setChecked(item.secondName,true)
                                // })
                                // v.children && v.children.forEach((childrenItem,childrenIndex) => {
                                    // if(childrenItem.label === item.secondName){
                                        // this.$set(childrenItem,'checked',true)
                                        this.moduleSystem.push({
                                            label:item.secondName,
                                            firstName:item.firstName,
                                            selectAll:'false',
                                            hierarchy:'secondName'
                                        })
                                    // }
                                // })
                            }
                        }
                    })
                // })
            }
            console.log( this.moduleSystem,' this.moduleSystem');
        },
        editAccount(
            account,
            name,
            groupNames,
            roleName,
            privileges,
            userId,
            roleId,
            mainName,
            editable,
            item
        ) {
            this.editActiveItem = item;
            this.templateInfo = item.accountTemplate;
            this.handlerTemplate('edit')
            this.getRRoleDetail(roleId);
            if (roleName == "超级管理员") {
                this.isSuperAcconut = true;
                this.isShowBotAuthority = false;
                this.isShowWorkOrder = true;
            } else {
                this.isSuperAcconut = false;
                this.isShowBotAuthority = true;
            }
            this.valueName = "";
            this.orgTreeName = "";
            this.accountGroupList();
            this.changeAcountForm.emailArresss = account;
            this.changeAcountForm.fullName = name;
            this.changeAcountForm.userId = userId;
            this.changeAcountForm.mainName = mainName;
            this.changeAcountForm.editable = editable;
            this.changeAcountForm.userWorkTimeId = item.userWorkTimeId;
            this.changeAcountForm.userDataPrivilegesIds = JSON.parse(
                item.userDataPrivilegesId
            );
            this.changeAcountForm.workNumber = item.workNumber;
            this.changeAcountForm.mengNiuCompanyId = item.mengNiuCompanyId;
            this.changeAcountForm.contactPersonId = item.contactPersonId;
            this.changeAcountForm.tel = item.tel;
            this.changeAcountForm.userSystems = item.userSystems;
            this.changeAcountForm.languageCode = item.languageCode ? item.languageCode : 'cn'
            this.moduleSystem = [];
            this.setCascadeOption = JSON.parse(JSON.stringify(this.AXIOSSetCascadeOption))
            this.handlerModule(item.userSystems);
            if (roleName != "超级管理员") {
                this.isShowWorkOrder = this.arbitrationWorkOrderPri(roleId);
            }
            // 老数据兼容
            if(item.workOrderSetting) {
                this.changeAcountForm.workOrderSetting = JSON.parse(JSON.stringify(item.workOrderSetting))
                if(item.workOrderSetting.limitOrderNum === null ) {
                    this.changeAcountForm.workOrderSetting.limitOrderNum = true
                }
            } else {
                this.changeAcountForm.workOrderSetting = {
                    limitOrderNum:false,
                    maxNumOfWorkOrdersProcessed:30
                }
            }
            this.valueName = item.memberTableDataId
                ? item.memberTableDataId
                : "";
            this.memberInfo4Client.bindType = item.extInfo && item.extInfo.data ? item.extInfo.data.source:'';
            this.memberInfo4Client.name = item.extInfo && item.extInfo.data ? item.extInfo.data.memberName:'';
            this.memberInfo4Client.memberId = item.memberTableDataId ? item.memberTableDataId : "";
            if (roleId === 1) {
                this.changeAcountForm.accountType = this.$t('accountManage.superAdministrator');
            } else {
                this.changeAcountForm.accountType = roleId;
            }
            this.changeAcountForm.groupNames = groupNames;
            
            //新增同时处理工单数，默认值设为30;
            // 回显工单类
            this.workOrderType = [];
            this.workOrderType = [...item.userGoodAtWorkOrders];

            this.$nextTick(() => {
                let CheckedIds = this.workOrderType.map((item) => item.id);
                this.workOrderTypeTree.forEach((item, index) => {
                    let refTree = "ordertree_" + index;
                    if (this.$refs[refTree] && this.$refs[refTree][0]){
                        this.$refs[refTree][0].setCheckedKeys(CheckedIds);
                    }
                });
            });
            this.botCheckList = [];
            this.skillList = [];
            this.skillCheckList = [];
            let privilegesNew = JSON.parse(privileges);

            if (privilegesNew !== null && privilegesNew.length > 0) {
                for (let i = 0; i < privilegesNew.length; i++) {
                    for (let j = 0; j < this.botList.length; j++) {
                        if (this.botList[j].botId === privilegesNew[i].botId) {
                            this.botCheckList.push(privilegesNew[i].botId);
                        }
                    }
                }
                for (let i = 0; i < this.botList.length; i++) {
                    this.botList[i].skillList = [];
                    this.botList[i].checked = false;
                    for (let j = 0; j < privilegesNew.length; j++) {
                        if (this.botList[i].botId === privilegesNew[j].botId) {
                            if (
                                privilegesNew[j].skillIds !== "" &&
                                privilegesNew[j].skillIds !== undefined
                            ) {
                                this.botList[i].skillList =
                                    privilegesNew[j].skillIds;
                            } else {
                                this.botList[i].botSkillList.forEach(
                                    (skill) => {
                                        this.botList[i].skillList.push(
                                            skill.id
                                        );
                                    }
                                );
                            }
                            if (privilegesNew[j].checked === undefined) {
                                this.botList[i].checked = true;
                            } else {
                                this.botList[i].checked = privilegesNew[j]
                                    .checked
                                    ? true
                                    : false;
                            }
                        }
                    }
                }
            } else {
                for (let i = 0; i < this.botList.length; i++) {
                    this.botList[i].checked = false;
                }
            }
            this.checkAll =
                this.botCheckList.length === this.botList.length ? true : false;
            this.isIndeterminate = this.checkAll ? false : true;
            if (this.botList.length > 0) {
                this.clickBot(this.botList[0].botId);
                this.skillList = this.botList[0].botSkillList;
                if (this.botList[0].checked) {
                    this.skillCheckList = this.botList[0].skillList;
                } else {
                    this.skillCheckList = [];
                }
            }
            this.isEditAccount = true;
            this.dialogVisible = true;
            this.diaglogTitle = this.$t('accountManage.editAccount');
        },
        // 保存账号
        saveAccount() {
            let VerificationFieldObj = this.RequiredVerification()
            if (this.isShowBotAuthority && this.isRoleVisable) {
                if (this.botCheckList.length > 0) {
                    this.privileges = [];
                    for (let s = 0; s < this.botList.length; s++) {
                        this.botList[s].botCheckSkill = [];
                        if (this.botList[s].botSkillList){
                          this.botList[s].botSkillList.forEach((item) => {
                            if (this.botList[s].skillList !== undefined) {
                            }
                            this.botList[s].skillList.forEach((skill) => {
                              if (item.id === skill) {
                                this.botList[s].botCheckSkill.push(
                                    item.name
                                );
                              }
                            });
                          });
                        }
                    }
                    for (let s = 0; s < this.botList.length; s++) {
                        if (this.botList[s].checked) {
                            this.privileges.push({
                                botId: this.botList[s].botId,
                                botName: this.botList[s].botName,
                                skillIds: this.botList[s].skillList,
                                checked: this.botList[s].checked,
                                skillList: this.botList[s].botCheckSkill,
                            });
                        }
                    }
                } else {
                    if (
                        this.changeAcountForm.userDataPrivilegesIds.length == 0
                    ) {
                        this.$message.error(
                            this.$t('accountManage.dataPermissionRequired')
                        );
                        return;
                    }
                }
            } else {
                this.privileges = [];
            }
            if (this.changeAcountForm.emailArresss == "") {
                this.$message.error(this.$t('accountManage.mailRequired'));
                return;
            }
            if (this.changeAcountForm.fullName == ""){
              this.$message.error(this.$t('accountManage.nameRequired'));
              return;
            }
            if (this.changeAcountForm.accountType.length > 0 || this.changeAcountForm.accountType !== "") {
                for (let index = 0; index < this.roleList.length; index++) {
                    if (
                        this.roleList[index].id ==
                        this.changeAcountForm.accountType
                    ) {
                        this.changeAcountForm.roleId = this.roleList[index].id;
                    } else if (
                        this.changeAcountForm.accountType ===
                        this.roleList[index].name
                    ) {
                        this.changeAcountForm.roleId = this.roleList[index].id;
                    }
                }
            } else {
                this.$message.error(this.$t('accountManage.accountTypeRequired'));
                return;
            }
            // 生成GroupID
            this.changeAcountForm.groupIds = [];
            if (this.changeAcountForm.groupNames != null && this.changeAcountForm.groupNames.length > 0) {
                this.groupList.forEach((item,index) => {
                    if(this.changeAcountForm.groupNames.indexOf(item.name) !== -1){
                        this.changeAcountForm.groupIds.push(item.id);
                    }
                })
            } else {
                this.$message.error(
                    this.$t('accountManage.groupRequired')
                );
                return;
            }
          
            if (this.changeAcountForm.accountType == -1 && this.changeAcountForm.mainName == "") {
                this.$message.error(this.$t('accountManage.subEntityNameRequired'));
                return;
            }
            if (this.changeAcountForm.userWorkTimeId == "") {
                this.$message.error(this.$t('accountManage.workTimeRequired'));
                return;
            }
            let email = this.changeAcountForm.emailArresss;
            let name = this.changeAcountForm.fullName;
            let roleId = this.changeAcountForm.roleId;
            let groupIds = this.changeAcountForm.groupIds;
            let userId = this.changeAcountForm.userId;
            let userDataPrivilegesId = [];
            if (this.changeAcountForm.userDataPrivilegesIds == null) {
                this.changeAcountForm.userDataPrivilegesIds = [0];
            }

            if (this.changeAcountForm.userDataPrivilegesIds.length == 0) {
                this.$message.error(this.$t('accountManage.dataPermissionRequired'));
                return;
            }
           
            if (VerificationFieldObj.isHaveWorkTimeField && this.changeAcountForm.workOrderSetting.limitOrderNum && this.changeAcountForm.workOrderSetting.maxNumOfWorkOrdersProcessed == 0 && this.isShowWorkOrder){
              this.$message({
                message:this.$t('accountManage.maxNumOfWorkOrdersProcessed'),
                type:"error",
                duration:2000
              })
              return;
            }
            // 自定义字段的必填校验
            if (!VerificationFieldObj.flag) {
                return
            }
            userDataPrivilegesId = JSON.stringify(
                this.changeAcountForm.userDataPrivilegesIds
            );
            let workOrderTypes = this.workOrderType;
            // 模块/系统
            let userSystems = [];
            this.changeAcountForm.userSystems && this.changeAcountForm.userSystems.forEach((item,index) => {
                // selectAll
                if(item.selectAll === 'true'){
                    userSystems.push({
                        firstName:item.label,
                        secondName:'',
                        selectAll:'true'
                    }) 
                }  else {
                    userSystems.push({
                        firstName:item.firstName,
                        secondName:item.label || item.secondName,
                        selectAll:'false'
                    }) 
                }
            })
            let contactPersonId = null;
            if(Array.isArray(this.changeAcountForm.contactPersonId)){
                contactPersonId = this.changeAcountForm.contactPersonId && this.changeAcountForm.contactPersonId.length > 0 ? this.changeAcountForm.contactPersonId[1] : null;
            } else {
                if(Number(this.changeAcountForm.contactPersonId) > 0){
                    contactPersonId = this.changeAcountForm.contactPersonId;
                }
            }
            let templateId = '';
            if(this.templateInfo != null && this.templateInfo != "null") {
                templateId = this.templateInfo.id
            }
            
            if (this.diaglogTitle == this.$t('accountManage.addAccount')) {
                let param = {
                    account: email,
                    name: name,
                    email: email,
                    privileges: JSON.stringify(this.privileges),
                    roleId: roleId,
                    groupIds: groupIds,
                    userWorkTimeId: this.changeAcountForm.userWorkTimeId,
                    mainName: this.changeAcountForm.mainName,
                    memberTableDataId: this.valueName,
                    userDataPrivilegesId: userDataPrivilegesId,
                    memberSource:this.memberInfo4Client.bindType,
                    extInfo:{
                        data:{
                          memberName:this.memberInfo4Client.name,
                          source:this.memberInfo4Client.bindType
                        }
                    },
                    languageCode:this.changeAcountForm.languageCode,
                    userSystems:userSystems,
                    mengNiuCompanyId:this.changeAcountForm.mengNiuCompanyId,
                    workNumber:this.changeAcountForm.workNumber,
                    tel:this.changeAcountForm.tel,
                    contactPersonId:contactPersonId,
                    taccount:{
                        fieldValue:this.orderFieldValue,
                        templateId: templateId,
                        id:this.editActiveItem.taccount && this.editActiveItem.taccount.id ? this.editActiveItem.taccount.id : null
                    }
                };
                if (VerificationFieldObj.isHaveTypeMadeField && this.isShowWorkOrder) {
                    param.userGoodAtWorkOrders = workOrderTypes.map((item) => {
                        return {
                            userId: userId,
                            id: item.id,
                            workOrderTypeName: item.workOrderTypeName,
                            treeIndex: item.treeIndex,
                            adminAssign: item.adminAssign,
                        };
                    });
                    // param.limitOrderNum = false
                    param.workOrderSetting = this.changeAcountForm.workOrderSetting
                }
                this.saveAccountDisable = true;
                this.$http
                    .post(this.requestUrl.accountManage.accountv2, param)
                    .then((res) => {
                        if (res.data && res.data.code === "0") {
                            this.$message({
                                message: this.$t('accountManage.addAccountMsg'),
                                type: "success",
                            });
                            this.changeAcountForm = {
                                emailArresss: "",
                                fullName: "",
                                accountType: "",
                                groupNames: "",
                                groupIds: "",
                                roleId: "",
                                accountAuthority: [],
                                userId: "",
                                mainName: "",
                                editable: true,
                                userWorkTimeId: "",
                                languageCode:'cn',
                                workOrderSetting:{
                                    limitOrderNum:false,
                                    maxNumOfWorkOrdersProcessed:30
                                },
                            };
                            this.dialogVisible = false;
                            this.saveAccountDisable = false;
                            // 跳转到最后一页
                            this.$http
                                .get(
                                    this.requestUrl.accountManage
                                        .getAccountList,
                                    {
                                        params: {
                                            pageNo: 1,
                                            pageSize: 15,
                                        },
                                    }
                                )
                                .then((res) => {
                                    if (res.data && res.data.code === "0") {
                                        let pages = res.data.data.pages;
                                        this.currentPage = pages;
                                        this.getAccountList(pages, 15);
                                    } else {
                                        this.$message.error(res.data.message);
                                    }
                                });
                        } else {
                            this.saveAccountDisable = false;
                            this.$message.error(res.data.message);
                        }
                    });
            }
            if (this.diaglogTitle == this.$t('accountManage.editAccount')) {
                let param = {
                    account: email,
                    name: name,
                    email: email,
                    privileges: JSON.stringify(this.privileges),
                    roleId: roleId,
                    groupIds: groupIds,
                    mainName: this.changeAcountForm.mainName,
                    userWorkTimeId: this.changeAcountForm.userWorkTimeId,
                    memberTableDataId: this.valueName,
                    userDataPrivilegesId: userDataPrivilegesId,
                    memberSource:this.memberInfo4Client.bindType,
                    extInfo:{
                      data:{
                        memberName:this.memberInfo4Client.name,
                        source:this.memberInfo4Client.bindType
                      }
                    },
                    userSystems:userSystems,
                    mengNiuCompanyId:this.changeAcountForm.mengNiuCompanyId,
                    workNumber:this.changeAcountForm.workNumber,
                    languageCode:this.changeAcountForm.languageCode,
                    tel:this.changeAcountForm.tel,
                    contactPersonId:contactPersonId,
                    taccount:{
                        fieldValue:this.orderFieldValue,
                        templateId: templateId,
                        id:this.editActiveItem.taccount && this.editActiveItem.taccount.id ? this.editActiveItem.taccount.id : null
                    }
                };
                if (VerificationFieldObj.isHaveTypeMadeField && this.isShowWorkOrder) {
                    param.userGoodAtWorkOrders = workOrderTypes.map((item) => {
                        return {
                            userId: userId,
                            id: item.id,
                            workOrderTypeName: item.workOrderTypeName,
                            treeIndex: item.treeIndex,
                            adminAssign: item.adminAssign,
                        };
                    });
                    let tampWWIdArr = [],
                        tampWWArr = [];
                    param.userGoodAtWorkOrders.forEach((item) => {
                        if (tampWWIdArr.indexOf(item.id) == -1) {
                            tampWWIdArr.push(item.id);
                            tampWWArr.push(item);
                        }
                    });
                    param.userGoodAtWorkOrders = tampWWArr;
                    param.workOrderSetting = this.changeAcountForm.workOrderSetting
                    param.workOrderSetting.limitOrderNum = this.changeAcountForm.workOrderSetting.limitOrderNum
                }
                this.saveAccountDisable = true;
                console.log(this.changeAcountForm,'this.changeAcountForm');
                console.log(param,'param');
                this.$http
                    .put(
                        this.requestUrl.accountManage.updateAccountv2 +
                            "/" +
                            userId,
                        param
                    )
                    .then((res) => {
                        if (res.data && res.data.code === "0") {
                            this.$message({
                                message: this.$t('accountManage.editAccountSuccess'),
                                type: "success",
                            });
                            let userId = localStorage.getItem('_uid') ? Number(localStorage.getItem('_uid')) : ""
                            if (this.changeAcountForm.userId == userId){
                              sessionStorage.setItem('systemLanguage',this.changeAcountForm.languageCode);
                              this.$i18n.locale = this.changeAcountForm.languageCode;
                              // this.$i18n.locale = "en"
                              if (this.changeAcountForm.languageCode){
                                sessionStorage.removeItem('noLanguageCode');
                              }
                            }
                            this.changeAcountForm = {
                                emailArresss: "",
                                fullName: "",
                                accountType: "",
                                groupNames: "",
                                groupId: "",
                                roleId: "",
                                accountAuthority: [],
                                userId: "",
                                mainName: "",
                                editable: true,
                                userWorkTimeId: "",
                                languageCode:'',
                                workOrderSetting:{
                                    limitOrderNum:false,
                                    maxNumOfWorkOrdersProcessed:30
                                }
                            };
                            this.dialogVisible = false;
                            this.saveAccountDisable = false;
                            // 跳转到最后一页
                            this.$http
                                .get(
                                    this.requestUrl.accountManage
                                        .getAccountList,
                                    {
                                        params: {
                                            pageNo: 1,
                                            pageSize: 15,
                                        },
                                    }
                                )
                                .then((res) => {
                                    if (res.data && res.data.code === "0") {
                                        let pages = res.data.data.pages;
                                        this.getAccountList(
                                            this.currentPage,
                                            15
                                        );
                                    } else {
                                        this.$message.error(res.data.message);
                                    }
                                });
                        } else {
                            this.saveAccountDisable = false;
                            this.$message.error(res.data.message);
                        }
                    });
            }
        },

        openOrCloseAutom() {
            this.$http
                .put(
                    this.requestUrl.accountManage.updateAccountv2 +
                        "/" +
                        this.openOrCloseAutomatedUserId,
                    {
                        status: this.currentStatus,
                    }
                )
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        this.$message({
                            message: this.$t('accountManage.editAccountSuccess'),
                            type: "success",
                        });
                        this.getAccountList(this.currentPage, 15);
                    } else {
                        this.$message.error(res.data.message);
                    }
                    this.openOrCloseAutomated = false;
                });
        },

        changeStatusAccount(userId, status) {
            console.log(status);
            if (status == "2") {
                this.openOrCloseAutomatedTitle = this.$t('accountManage.accountRecovery');
                this.openOrCloseAutomatedText = this.$t('accountManage.accountRecoveryTip');
                this.openOrCloseHandleType = this.$t('accountManage.recovery');
                this.openOrCloseAutomated = true;
                this.openOrCloseAutomatedUserId = userId;
                this.currentStatus = 1;
            }
            if (status == "1") {
                this.openOrCloseAutomatedTitle = this.$t('accountManage.accountDisabled');
                this.openOrCloseAutomatedText = this.$t('accountManage.accountDisabledTip');
                this.openOrCloseHandleType = this.$t('accountManage.disabled');
                this.openOrCloseAutomated = true;
                this.openOrCloseAutomatedUserId = userId;
                this.currentStatus = 2;
            }
            if (status == 3 || status == 4) {
                this.openOrCloseAutomatedTitle = this.$t('accountManage.accountRecovery');
                this.openOrCloseAutomatedText = this.$t('accountManage.accountRecoveryTip');
                this.openOrCloseHandleType = this.$t('accountManage.recovery');
                this.openOrCloseAutomated = true;
                this.openOrCloseAutomatedUserId = userId;
                this.currentStatus = 1;
            }
            if (status == "0") {
                this.$message.error(this.$t('accountManage.activateAccountTip'));
                return;
            }
           
        },
        showAllBot() {
            let div = document.getElementById("allbot");
            div.style.display = "flex";
        },
        // 删除账号
        deleteAccount(userId, index) {
            this.deleteAutomated = true;
            this.deleteAutomatedId = userId;
        },
        delAutomated() {
            this.$http
                .delete(
                    this.requestUrl.accountManage.deleteAccount +
                        "?userId=" +
                        this.deleteAutomatedId
                )
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        this.$message({
                            type: "success",
                            message: this.$t('accountManage.deleteSuccess'),
                        });
                        this.getAccountList(this.currentPage, 15);
                    } else {
                        this.$message.error(res.data.message);
                    }
                    this.deleteAutomated = false;
                });
        },
        // 获取用户分组list
        accountGroupList() {
            this.$http
                .get(this.requestUrl.accountGroup.getAccountGroupList)
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        this.groupList = [...res.data.data];
                        this.$nextTick(() => {
                            this.groupListOptions = [...this.groupList];
                            this.filterGroupListOptions = [...this.groupList];
                        });
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        // 点击账号组管理
        clickClassHandle() {
            this.classHandleVisible = true;
            this.accountGroupList();
        },
        // 新建用户分组
        addClass() {
            this.groupList.push({
                isEdit: true,
                name: "",
            });
            this.$nextTick(() => {
                let ele = document.getElementById("classList");
                ele.scrollTop = ele.scrollHeight + 100;
            });
        },
        // 编辑用户分组
        editClass(index) {
            this.groupList[index].isEdit = true;
            this.groupList = [...this.groupList];
        },
        // 删除用户分组
        deleteClass(index) {
            this.activeDeleteAccGroup = this.groupList[index];
            this.activeDeleteAccGroupIndex = index;
            //判断用户分组下是否存在用户关联
            if (this.activeDeleteAccGroup.id == undefined) {
                this.groupList.splice(index, 1);
            } else {
                this.$http
                    .get(
                        this.requestUrl.accountGroup.queryGroupHaveUserById +
                            "?groupId=" +
                            this.activeDeleteAccGroup.id
                    )
                    .then((res) => {
                        if (res.data && res.data.code === "0") {
                            this.activeAccGroupHasA = res.data.data;
                            this.dleteAccDialogVisible = true;
                        } else {
                            this.$message.error(res.data.message);
                        }
                    });
            }
        },
        // 保存用户分组
        saveClassHandle() {
            //判断新建的分类名称是否为空
            if(this.groupList.some(group =>{ return !group.name})) {
                this.$message.error(this.$t('accountManage.groupNameRequired'));
                return
            }
            let nameRepeat = ''
            for (let index = 0; index < this.groupList.length; index++) {
                if(this.groupList.some((item, groupIndex) =>{ return item.name == this.groupList[index].name && groupIndex != index })) {
                    nameRepeat = this.groupList[index].name
                }
            }
            if(nameRepeat !== '') {
                this.$message.error(this.$t('accountManage.groupName') + nameRepeat + this.$t('accountManage.repeat'));
                return
            }
            this.$http.put(
                    this.requestUrl.accountGroup.updateAccountGroup,
                    this.groupList
                )
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        this.$message({
                            message: this.$t('accountManage.updateAccountSuccess'),
                            type: "success",
                        });
                        this.accountGroupList();
                        this.getAccountList(this.currentPage, 15);
                        this.classHandleVisible = false;
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        processedInput(value) {
            if(!value || value > 10000) {
              this.changeAcountForm.workOrderSetting.maxNumOfWorkOrdersProcessed = ''   
            }
        },
        
    },
    
};
</script>

<style lang="less">
.search-account {
    ::placeholder {
        color: lightgrey !important;
        font-weight: 400 !important;
    }
    .el-input__icon {
        cursor: pointer;
    }
}
.el-popover.sort-order-r-user-systems-popover{
    display: none;
    max-height: 400px;
    overflow-y: auto;
    .el-tree{
        .el-tree-node__content{
            height: 34px;
            line-height: 34px;
            .el-tree-title{
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
        }
    }
    
}
.el-popover.sort-order-r-user-systems-popover:last-child{
    display: block;
    
}

.comask-el-dropdown-menu {
    max-height: 400px;
    overflow-y: auto;
}

.person-member-dropdown-menu{
    max-height: 400px;
    overflow-y: auto;
}
.el-picker-panel.add-count-time-picker{
    z-index: 2200 !important;
}
.add-count-tooltip,.contact-person-cascader{
    z-index: 2200 !important;
}
@import "./../../../assets/less/accountManageCustom/accountManageCustom.less";
.ac-type--icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
        display: inline-block;
        margin-right: 1px;
    }
}
.sort-order {
    // height: 29px;
    // background: #ffffff;
    // border: 1px solid #e0e6f7;
    // border-radius: 5px;
    color: #616161;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-right: 9px;
    position: relative;

    cursor: pointer;
    .iconfont {
        margin-right: 4px;
    }
    span {
        font-size: 14px;
    }
    .sort-order-l {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .iconfont {
            margin-right: 4px;
        }
    }
    .sort-order-r {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 23px;
        margin-left: 15px;
        i {
            display: inline-block;
            margin-top: -4px;
        }

        span {
            font-weight: 600;
            display: inline-block;
            margin-right: 4px;
        }
    }
    .sort-order-r-select,
    .sort-order-r-group-select {
        position: fixed;
        top: 140px;
        width: 280px;
        background: #ffffff;
        box-shadow: 0px 0px 9px 0px rgba(57, 63, 79, 0.21);
        border-radius: 5px;
        z-index: 1000;
        padding: 10px 0;
        display: none;
        max-height: 400px;
        overflow-y: auto;
        .sort-order-r-select-item {
            line-height: 32px;
            display: flex;
            align-items: center;
            padding: 0 20px;
            justify-content: space-between;
            font-weight: 500;
            text-align: left;
        }
        .sort-order-r-select-item:hover {
            background-color: #eff3ff;
        }
        .active-select {
            color: #366aff;
            .el-icon-check{
                font-weight: 500;
            }
        }
        // .sort-order-r-select-item:nth-child(2n) {
        //     border-bottom: 1px solid #e0e6f7;
        // }
    }
}

/deep/.el-select__input {
    color: red !important;
}
.delete-acc-group-c {
    .el-select {
        width: 100%;
    }
}
.quanxian {
    .el-form-item__content {
        margin-left: 0 !important;
    }
}
.work-order-tree {
    .el-select-dropdown__item {
        height: auto !important;
        background-color: chocolate;
        padding: 0 !important;
    }
}
#el-dropdown-work-order {
    width: 100%;
}
.el-dropdown-work-order{
    z-index:2200 !important;
}
.work-order-dropdown-item {
    width: 670px !important;
    padding: 0 !important;
}
.goto-workorder-type {
    display: inline-block;
    margin-left: 12px;
    color: #366AFF;
    cursor: pointer;
}
.active-el-dropdown-link {
    border: 1px solid #366AFF !important;
}
.el-dropdown-link-workorder {
    display: flex;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    /deep/.el-tree--highlight-current
        .el-tree-node.is-current
        > .el-tree-node__content {
        background-color: none !important;
    }
    .el-dropdown-work-order-content {
        flex: auto;
        display: block;
        min-height: 24px;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        padding: 5px 6px 7px;
        .el-tag {
            margin: 2px 5px 0 0;
        }
    }
    .el-dropdown-placeholder {
        flex: auto;
        display: block;
        height: 38px;
        line-height: 38px;
        padding: 0 18px;
        color: #c0c4cc;
    }
    .el-icon {
        flex: none;
        width: 30px;
        line-height: 38px;
        text-align: center;
        display: flex;
        align-items: center;
        em {
            align-items: center;
        }
    }
}

#work-order-dropdown {
    overflow-x: auto;
    .el-dropdown-menu__item {
        width: 380px;
    }
}
#account-manage-add {
    .el-form-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 0px;
        .el-select {
            width: 100%;
        }
        .el-form-item__label {
            width: 100% !important;
        }
        .el-form-item__content {
            margin-left: 0 !important;
        }
    }
  .bind-third-account-input{
    background-color: #FFF;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;
    display: flex;
    align-items: center;
    cursor:pointer;
    font-weight: 500;
  }
  .form-name-label{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .right{
      font-size: 12px;
      color: #999999;
    }
  }
}
.dialog-content {
    padding-bottom: 20px;
    .bind-bot-self-tree {
        padding-top: 5px;
        padding-left: 6px;
        z-index: 100000000000000000;
        position: absolute;
        top: 42px;
        background-color: white;
        border-left: solid 1px #e4e7ed;
        border-right: solid 1px #e4e7ed;
        border-bottom: solid 1px #e4e7ed;
        border-radius: 6px;
        max-height: 400px;
        overflow: scroll;
        width: 100%;
        .custom-tree-node {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 8px;
        }
    }
    
}
.processed {
    .el-input__inner{
        line-height: 1px !important;
    }
}

.add-account{
    .cascade-class {
        min-height: 16px;
        padding: 10px 12px;
        width: 100%;
        height: 36px;
        box-sizing: border-box;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        cursor: pointer;
        color: #606266;
        display: flex;
        align-items: center;
        >div{
            white-space: nowrap;
        }
        .placeholder-div{
            color:rgb(169, 179, 198);
            font-size:12px;
            font-weight: 500;
            cursor: pointer;
        }
    }
    .el-cascader{
        width: 100%;
        .el-icon-circle-close{
            font-size: 14px;
        }
    }
    .red {
        color: red;
    }
    .el-date-editor{
        width: 100%;
        .el-input__inner{
            width: 100%;
        }
        &.is-active {
            border-color: #366aff;
            box-shadow:inset 0 1px 1px rgba(0, 0, 0, 0.015), 0 0 4px #366aff;
        }
        .el-range-input:focus{
            border: none !important;
            box-shadow: none !important;
        }
    }
}
.cascade-tree {
    .container-tree {
        margin-top: 16px;
        .is-leaf::before{
            display: none;
        }
        .el-tree-title {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // .el-tree-title-content {
            //   flex: 1;
            // }
            .setDisabled{
                color: #dcdfe6;
            }
            .check-icon{
                i{
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }
         .el-tree-node__content{
            /* width: 434px;*/
            position: relative;
            height: 24px;
            line-height: 24px;
            text-align: left;
            margin-bottom: 12px;
            flex: none;
            .el-checkbox{
                position: absolute;
                right: 0;
            }
        }
        .el-tree-node__expand-icon{
            color:#c0c4cc;
        }
        
        /deep/.el-radio__label{
            display: none;
        }
        /deep/.el-radio{
            min-width: 0;
        }

    }
    /deep/.el-checkbox {
        min-width: 0px !important;
    }
}
</style>
