const requestUrl={
    bot: { // 用户
        getBotList: '/api-open/bot/list', // 获取机器人列表 GET
        getSkillList: '/api/skill/list', //获取知识库列表GET
        selectPlatformLogoSetByPlatformId:"/api-open/bot/selectPlatformLogoSetByPlatformId", // 根据网站接入渠道ID查询一个形象设置信息 GET
        selectDefaultAgentLogo: "/api-open/bot/selectDefaultAgentLogo", // 获取默认应用内头像/默认网站接入形像, type=1应用内, type=2网站接入 GET
        savePlatformLogoSet:"/api-open/bot/savePlatformLogoSet", // 保存/修改形象设置 post
        getUserCompanyModule:"/api/company/getUserCompanyModule", // 获取用户的系统入口 GET
    },
    role :{//角色管理
        getRoleList: '/api/role',//获取角色列表GET

    },
    version: { // 版本信息
        selectPayVersion: '/api/pay/version/selectPayVersion', // 查询版本差异信息 GET
        selectPayVersionBoard: '/api/pay/version/selectPayVersionBoard', // 查询版本看板信息 GET
        selectLimitMsg: '/api/company/selectLimitMsg', // 查询机器人/意图创建限制信息 GET
    },
    notice: { // 通知管理
        publishNotice: '/api/notice/publishNotice', // 发布公告 POST
        selectAllNotice: '/api/notice/selectAllNotice', // 分页获取公告 GET
    },
    accountManage :{//成员管理
        getAccountList: '/api/account/list',//获取成员列表GET
        account: '/api/account',//成员管理POST
        accountv2: '/api/account/v2',//成员管理POST
        deleteAccount: '/api/account', // 删除账号DELETE
        updateAccount: '/api/account', // 更新账号PUT
        updateAccountv2: '/api/account/v2', // 更新账号PUT

        corps4main: '/api/department/corps4main', // 获取主体下的公司 GET
        passWordChange: '/api/account/passWordChange', // 修改用户登录密码 GET
        checkBindPhone: '/api/account/checkBindPhone', // 验证用户已绑定手机号 GET
        checkPhoneOnlyOne: '/api/account/checkPhoneOnlyOne', // 检查手机号唯一性 GET
        bindPhone: '/api/account/bindPhone', // 绑定手机号 PUT
    },
    accountGroup :{//用户分组管理
        getAccountGroupList: '/api/user/group',//获取用户分组列表GET
        addAccountGroup: '/api/user/group',//新增用户组POST
        updateAccountGroup: '/api/user/group',//更新用户分组PUT
        deleteAccountGroup: '/api/user/group',//删除用户分组DELETE
        queryGroupHaveUserById: '/api/user/group/queryGroupHaveUserById', // 查询用户组下是否有账户 GET
        moveUserToSpecifiedGroup: '/api/user/group/moveUserToSpecifiedGroup', // 移动指定组下的成员到其他组 GET
    },
    userDataPrivilege: { // 用户数据权限相关接口
        addUserDataPrivileges: '/api/userDataPrivileges', // 新增用户数据权限 POST
        updateUserDataPrivileges: '/api/userDataPrivileges', // 更新用户数据权限 PUT
        deleteUserDataPrivileges: '/api/userDataPrivileges/', // 删除用户数据权限 DELETE
        getUserDataPrivilegesAllList: '/api/userDataPrivileges/queryAllUserDataPrivileges', // 查询所有用户数据权限(不分页) GET
        getUserDataPrivilegesList: '/api/userDataPrivileges/queryUserDataPrivilegesByPage', // 查询所有用户数据权限(分页) GET
        queryApplicationType: '/api/userDataPrivileges/queryApplicationType', // 新增数据权限弹框--工单数据权限--指定应用工单查询  GET
        queryUserBotSkill: '/api/userDataPrivileges/queryUserBotSkill', //新增数据权限弹框--查询机器人数据权限 GET
        queryWorkOrderGroup: '/api/userDataPrivileges/queryWorkOrderGroup', // 新增数据权限弹框--工单数据权限--指定工单组查询 GET
        queryWorkOrderTemplate: '/api/userDataPrivileges/queryWorkOrderTemplate', // 新增数据权限弹框--工单数据权限--根据当前登录用户主体ID获取所有工单模板 GET
    },
    companyManage :{
        onlyBindPhone: "/api/account/bindPhone", // 账号仅绑定手机号 PUT
        postCompanyBingModel : '/api/company/bind', //绑定模块 POST
        postCompanyAuth: '/api/company/auth/', //公司认证 POST
        getUserCompany: '/api/company' , //用户公司信息 GET
        newFirstBind: "/api/company/mobileFirstBind", // 保存用户所选平台信息及知识库版本信息 POST
        userSubmitBasicInfo: "/api/company/userSubmitBasicInfo", // 用户提交基础信息 GET
        selectEstimateValue: "/api/company/selectEstimateValue", // 查询企业预估价值 GET
        recordRemind: "/api/company/recordRemind", // 记录提醒状态 GET
        getCompanyIsOld: "/api/company/getPromotionCompanyIsOld", // 判断企业是不是旧客 GET
        setCompanyAccountAutoDisable:"/api/company/setCompanyAccountAutoDisable", // 设置企业下账号自动禁用 PUT
        queryCompanyAccountAutoDisable: "/api/company/queryCompanyAccountAutoDisable", // 查询企业下账号自动禁用设置信息 GET
        getUserCompanyModule:"/api/company/getUserCompanyModule", // 获取用户的系统入口 GET
    },
    systemModel :{
        getSystemModel: '/api/module' //获取模块 GET
    },
    file: {
        upload: '/api/file' //上传文件 POST
    },
    code: {
        mailSend: '/api/code/mail/send', //发送邮件验证码POST
        mailCheck: '/api/code/mail/check', //校验邮件验证码POST
    },
    member: {
        addMember: "/api/member", // 添加成员 POST
        getMemberById: "/api/member", // 根据Id获取成员信息 GET
        editMemberById: "/api/member", // 编辑成员信息 PUT
        deleteMemberById: "/api/member", // 删除成员 DELETE
        enableApp: "/api/member/enable/app", // 启用应用 PUT
        forbidApp: "/api/member/forbid/app", // 禁用应用 PUT
        memberImport: "/api/member/import", // 导入成员 POST
        getMemberListByDeptId: "/api/member/list", // 获取部门下成员 GET
    },
    department: {
        addDepartment: "/api/department", // 添加部门 POST
        getDepartmentById: "/api/department", // 根据Id获取部门信息 GET
        editDepartmentById: "/api/department", // 编辑部门信息 PUT
        deleteDepartmentById: "/api/department", // 删除部门 DELETE
        initDepartment: "/api/department/init", // GET initdepartment
        getDepartmentTree: "/api/department/list", // 获取部门树 GET
        getDepartmentTreeLazy: "/api/department/listDynamicLazy", // 获取部门树 GET
        moveDepartment: "/api/department/move", // 移动部门 POST
        searchDepartmentOrUser: "/api/department/search", // 搜索部门或者成员 GET
        getDepartmentMemberTree:'/api/department/list/all', // 获取部门成员树
        setSysPermissions: '/api/sysPermissions/set', // 设置系统使用权限 POST
        getQueryPeopleAuthNum: '/api/sysPermissions/queryPeopleAuthNum', // 查询主体下可咨询机器人的总数及已有可咨询机器人人数 POST
        newSearchDepartment:"/api/department/search-department-v2", //新搜索部门接口
        newSearchUser:"/api/department/search-member-v2" //新搜索员工接口
    },
    agentConfig:{
        getWechat:"/api/account/third-wechat-corp-info",
        agentConfigNew:"/api/account/askbot-wechat-agent-config"
    },
    wechatKF: { // 微信客服
        getWechatCustomer: '/api/wechat-customer-service', // 获取客服账号 GET
        addWechatCustomer: '/api/wechat-customer-service', // 添加客服账号 POST
        updateWechatCustomer: '/api/wechat-customer-service', // 更新客服账号 PUT
        deleteWechatCustomer: '/api/wechat-customer-service', // 删除客服账号 DELETE
        initWechatCustomer: '/api/wechat-customer-service/init', // 删除客服账号 PUT
        getWechatCustomerMenmber: '/api/wechat-customer-service/allow-member', // 获取部门及成员树 GET
        agentConfig: '/api/wechat-customer-service/agent-config', // js api agent config
        agentConfigNew:"/api/account/askbot-wechat-agent-config",
        wechatBindList: '/api/channel/access/list/1', // GET 获取为i想念公众号绑定信息
        // 微信客服接入
        addBindWechatKF: "/api-open/bind/wechat-kf", // 添加微信客服应用 POST
        bindBindWechatKF: "/api-open/bind/wechat-kf/bind", // 微信客服应用绑定 PUT
        getWechatKFList: "/api-open/bind/wechat-kf/list", // 获取微信客服绑定应用列表 GET
        unbindBindWechatKF: "/api-open/bind/wechat-kf/unbind", // 微信客服应用解除绑定 PUT
        selectOrganization: "/api-open/upAndDown/selectOrganizationTree", // GET 获取上下游树
    },
    authScrm: {
        getScrmAuthInfo: '/scrm-api/we-work-auth-info', // 获取企业授权信息
        gatWework3rdUrl: '/scrm-api/wework-3rd/install-app-url/kf', // 获取安装应用链接
    },
    capital: {
        treeWorkOrderTypeTree: "/api/workOrderType/treeWorkOrderTypeTree", // GET 获取工单类型树
        workOrderTypeTreeByUser: "/api/userGoodAtWorkOrder/getAllWorkOrderTypes", // GET 获取当前登陆用户主体下的所有工单类型
    },
    upAndDown: {
        getTreeData: "/api/upAndDown/selectOrganizationTree", // GET 获取上下游组织架构树
        getTreeDataSearch: "/api/upAndDown/selectOrganizationByName", // GET 获取上下游组织架构树,搜索的时候调用
        addUpAndDownStream: "/api/upAndDown/addUpAndDownStream", // POST 添加组织架构部门
        getUserData:'/api/upAndDown/selectUpAndDownMemberInOrganization', //查询组织架构内成员
        addUpAndDownMember:'/api/upAndDown/addUpAndDownMember', // 添加组织架构内成员（POST）
        deleteUpAndDownMemberById:'/api/upAndDown/deleteUpAndDownMemberById', // DELETE 根据ID删除组织架构内成员
        selectOrganizationTreeById:'/api/upAndDown/selectOrganizationTreeById', // GET  根据ID获取上下游组织架构树
        updateUpAndDownMemberById:'/api/upAndDown/updateUpAndDownMemberById', // PUT 根据ID修改组织架构内成员
        filterSelectUpAndDownMember:'/api/upAndDown/filterSelectUpAndDownMember', // POST 部门成员高级筛选
        updateUpAndDownStream:'/api/upAndDown/updateUpAndDownStream', // PUT 修改组织架构部门
        selectUpAndDownMemberByOrganizationId:'/api/upAndDown/selectUpAndDownMemberByOrganizationId', // GET 查询组织架构内成员，不包含子级节点
        updateUpAndDownMemberByIds:'/api/upAndDown/updateUpAndDownMemberByIds', // PUT 根据ID批量修改组织架构内成员
        deleteUpAndDownStreamById:'/api/upAndDown/deleteUpAndDownStreamById', // DELETE 根据ID删除组织架构
        setUpPrincipal:'/api/upAndDown/setUpPrincipal', // POST 设置负责人
        updateUpAndDownStreamOrderNum:'/api/upAndDown/updateUpAndDownStreamOrderNum', // PUT 组织架构部门同级位置变动
        memberExport:'/api/upAndDown/memberExport', // POST 组织架构内选中成员导出
        memberImport:'/api/upAndDown/memberImport', // POST 组织架构成员信息导如
        updateUserStatus:'/api/upAndDown/updateUserStatus', // PUT 禁用/启用用户
        confirmPrincipal:'/api/upAndDown/confirmPrincipal', // 查询成员是否是负责人 get
        queryPeopleAuthNum:'/api/sysPermissions/queryPeopleAuthNum', // 查询主体下可咨询机器人的总数及已有可咨询机器人人数 post
        sysPermissionsSet:'/api/sysPermissions/set', // 设置系统使用权限 post
        selectOrganizationHasMember: '/api/upAndDown/selectOrganizationHasMember', // 查询目标节点下是否存在成员 post
        queryFollowDept:'/api/sysPermissions/queryFollowDept', // 成员跟随部门设置查询可咨询机器人的总数及已有可咨询机器人人数
        
    },
    workTime: {
        queryAllUserWorkTimePage: '/api/userWorkTime/queryAllUserWorkTimePage', // GET 查询所有用户数据权限(分页),工作时间页面展示
        addWorkTime: '/api/userWorkTime', // POST 新增用户工作时间
        updateUserWorkTimeById: '/api/userWorkTime/updateUserWorkTimeById', // PUT 根据id修改工作时间
        selectWhoUseWorkTimeById: '/api/userWorkTime/selectWhoUseWorkTimeById/', // GET {userWorkTimeId} 查询要删除的工作时间是否正在被使用
        migrationUserWorkTime: '/api/userWorkTime/migrationUserWorkTime/', // PUT {userWorkTimeId} 迁移用户工作时间,如果在删除一个工作时间时有用户正在使用,调用此接口将这些用户使用的工作时间迁移到其他时间上
        deleteWorkTime: '/api/userWorkTime/', // DELETE {userWorkTimeId} 根据id删除用户工作时间
        queryAllUserWorkTime: '/api/userWorkTime/queryAllUserWorkTime', // GET 查询所有用户数据权限(不分页),新增账号弹框下拉展示
    },
    schedulingManagement: { // 排班管理
        configureScheduling: '/api/schedulingManagement/configureScheduling', // POST 一日排班配置
        configureSchedulingGroups: '/api/schedulingManagement/configureSchedulingGroups', // POST 排班组配置
        deleteSchedulingGroups: '/api/schedulingManagement/deleteSchedulingGroups', // GET 删除排班组
        getCalendar: '/api/schedulingManagement/getCalendar', // GET 获取日历信息
        queryAllSchedulingGroups: '/api/schedulingManagement/queryAllSchedulingGroups', // GET 查询所有排班组
        queryScheduling: '/api/schedulingManagement/queryScheduling', // GET 查看排班
        querySchedulingUser: '/api/schedulingManagement/querySchedulingUser', // GET 查询可选择的值班员工
        updateSchedulingGroups: '/api/schedulingManagement/updateSchedulingGroups', // PUT 编辑排班组
        getContactPersonList: '/api/account/getContactPersonList', // GET 获取账号组和组下成员
        updateConfigureScheduling: '/api/schedulingManagement/updateConfigureScheduling', // PUT 修改一日排班配置
        queryUserCanScheduling: '/api/schedulingManagement/queryUserCanScheduling', // GET 查询当前用户是否有配置排班组权限
        queryUserPermissions: '/api/schedulingManagement/queryUserPermissions', // GET 查询当前用户是否有配置排班组权限
    },
    invite: { // 邀请加入企业
        checkUserCanInvite: "/api/invite/checkUserCanInvite", // 获取邀请设置信息 GET
        getInviteSet:"/api/invite/getInviteSet", // 获取邀请加入企业信息 GET
        updateInviteSet:"/api/invite/updateInviteSet", // 修改邀请成员加入企业设置 PUT
        selectCompanyInviteSet:"/api/invite/selectCompanyInviteSet", // 查询企业邀请设置 GET
        updateCompanyInviteSet: "/api/invite/updateCompanyInviteSet", // 企业邀请设置 GET
        refreshInvite:"/api/invite/refreshInvite", // 刷新邀请码/邀请链接 GET
        substitution: "/api/invite/substitution", // 邀请码置换邀请链接 GET
        validationPeriodInviteCode: "/api/invite/validationPeriodInviteCode", // 验证邀请码有效期 GET
        getInviteDepartment: "/api/invite/getInviteDepartment", // 获取邀请部门信息  GET
        clientLogin: "/api/register/clientLogin", // 客户端登录 POST
        clientAuthCodeLogin: "/api/register/clientAuthCodeLogin", // 客户端验证码登录 POST
        registerAndLogin: "/api/register/registerAndLogin", // 客户端注册并登录账号 POST
        selectApplyListByMainId: "/api/invite/selectApplyListByMainId", // 获取申请列表 GET
        applyOperate: "/api/invite/applyOperate", // 申请审核
        loginAuth: "/api/sso/auth", //登录  POST
        joinAgain: "/api/invite/joinAgain", // 重新提交审核 GET
        userReadTheUpdate: "/api/invite/userReadTheUpdate", // 用户已查看过申请列表更新情况 GET
    },
    knowledge: { // 容量/流量管理
        selectCompanyUseCapacity: "/api/knowledge/selectCompanyUseCapacity", // 查询企业容量使用情况及配置情况 GET
        selectCompanyUseFlow: "/api/knowledge/selectCompanyUseFlow", // 查询企业流量量使用情况及配置情况（GET）
        setCompanyCapacity: "/api/knowledge/setCompanyCapacity", // 设置企业容量（POST）
        setCompanyFlow: "/api/knowledge/setCompanyFlow", // 设置企业流量（POST）
        selectFlowApply: "/api/knowledge/selectFlowApply", // 查看流量扩容申请列表（POST）
        selectCapacityApply: "/api/knowledge/selectCapacityApply", // 查看容量扩容申请列表（POST）
        approveCapacityApply: "/api/knowledge/approveCapacityApply", // 审批容量扩容申请（PUT）
        approveFlowApply: "/api/knowledge/approveFlowApply", //审批流量扩容申请（PUT）
        selectFlowBuyApply: "/api/knowledge/selectFlowBuyApply", // 查看流量购买明细（POST）
        selectCapacityBuyApply: "/api/knowledge/selectCapacityBuyApply", // 查看流量购买明细（POST）
        selectFlowUseApply: "/api/knowledge/selectFlowUseApply", // 查看流量使用明细（POST）
        selectCompanyPay:"/api/knowledge/selectCompanyPay", // 企业整体购买容量/流量及使用情况（GET）
        
    },
    pay: {
        getWxPayCodeData: "/api/pay/version/getWxPayCodeData", //管理平台根据用户购买产品生成微信支付二维码（POST）
        selectCapacityPrice: '/knowledge-api/pay/selectCapacityPrice', // 查询容量包价格（GET）
        selectFlowPrice: '/knowledge-api/pay/selectFlowPrice', // 查询流量包价格（GET）
        selectOrderPayStatus:'/api/pay/version/selectOrderPayStatus', // 管理平台根据订单号查询支付状态（GET）
    },
    lable: {
        addTag: "/api/tag/", // {corpId} 新增标签 POST
        getTagList: "/api/tag", // {corpId} 标签列表 GET
        editTagName: "/api/tag/", // {corpId} 自建标签重命名 PUT
        getLableMumber: "/api/tag/page", // 标签成员分页查询 GET
        deleteTag: "/api/tag/", // 删除自建标签 DELETE
        linkMem: "/api/tag/link/", // 添加成员/部门 PUT
        unlinkMem: "/api/tag/unlink/", // 删除成员/部门 PUT
        tagSync: "/api-open/wecom/contact-tag/sync?bindCode=", // 标签同步
    },
    knowledgeBase:{
        getFolderAndFile:"/knowledge-api/knowledgeBase/selectTreeFolderAndFile",
        knowledgeBaseTreeLazy:"/knowledge-api/knowledgeBase/selectKnowledgeBaseStructureTree",
        tagTree:"/tag-api/tag/selectTree", //GET 查询子标签
        pluginList:"/tag-api/plugins/private-available-with-tools",
        clientSelectKnowledgeBaseStructureTree: '/knowledge-api/knowledgeBase/clientSelectKnowledgeBaseStructureTree',
        clientSelectTreeFolderAndFile: '/knowledge-api/knowledgeBase/clientSelectTreeFolderAndFile',
    },
    eWechatBind: {
        //企业微信接入组件
        getAuthUrl: "/api-open/bind/ewechat/get/auth-url", //获取授权URL
        eWechatSave:"/api-open/bind/ewechat/get/wechatBot",
        getAuthDomain: "/api-open/bind/ewechat/get/domain", //获取授权域
        getBindList: "/api-open/bind/ewechat/list", //绑定应用列表
        postUnbind: "/api-open/bind/ewechat/unbind", //解绑应用
        //'/api/bind/ewechat/detail/{bindId}'/api//查看绑定详细信息
    },
    wechatBind: {
        //企业微信接入组件
        getAuthUrl: "/api-open/bind/wechat/get/auth-url", //获取授权URL GET
        newWechatBind:"/api-open/bind/wechat/get/saveWechatBot", //新绑定接口
        getBindList: "/api-open/bind/wechat/list", //绑定应用列表GET
        unbind: "/api-open/bind/wechat/unbind", //解绑应用POST
        detail: "/api-open/bind/wechat/detail/{bindId}", //查看绑定详细信息 GET
        changeAllowPushMsg: "/api-open/bind/wechat/change/status/", //更改应用推送状态 POST
    },
    dingdingBind: {
        // 钉钉接入
        getDingtalkAuthUrl: "/api-open/bind/dingtalk/auth-url", // 获取授权URL GET
        dingTalkSave:"/api-open/bind/dingtalk/save",
        getBindList: "/api-open/bind/dingtalk/list", // 绑定应用列表 GET
        unBind: "/api-open/bind/dingtalk/unbind", // 解绑应用 POST
        getOpenDomain: "/api-open/bind/dingtalk/platform-url", // 平台地址
        dingtalkWhitelist: "/api-open/bind/dingtalk/whitelist", // 获取ip白名单 GET
        getYunzhijiaAuthUrl:"/api-open/bind/yunzhijia/auth-url", //获取授权URL
        newYunzhijiaBind:"/api-open/bind/yunzhijia/saveWechatBot",
    },
    dingdingSingBind: {
        // 钉钉单聊接入
        getDingtalkSingAuthUrl: "/api-open/bind/dingtalk/bot/bind", // 获取授权URL GET
        newSingBind:"/api-open/bind/dingtalk/bot/newBind", //新绑定接口 POST
    },
    miniProgramBind: {
        // 小程序接入
        wxMiniCode: "/api-open/wx/mini/acode", // 获取小程序码
    },
    bindV2: {
        // 改版绑定
        unbindApp: "/api-open/channel/access/unbind", // 解绑应用
        addWebsiteApp: "/api-open/channel/access/web", // 添加网站应用 POST
        addBindBot: "/api-open/channel/access/bind/bot", // 企业微信、公众号、钉钉绑定机器人接口 POST
        channelAccessList: "/api-open/channel/access/list/", // GET 获取绑定列表 渠道类型0：企业微信 1：公众号 2：小程序（暂无） 3：钉钉 4：网站   21 客户端
        departmentTree: "/api-open/channel/access/department/tree", // GET 获取部门成员树
        clientBindBot: "/api-open/channel/access/clientBindBot", // 客户端绑定机器人（POST）
        unifySetGreeting: "/api-open/channel/access/unifySetGreeting", // 应用欢迎语统一设置（POST）
        selectUnifySetGreeting: "/api-open/channel/access/selectUnifySetGreeting", // 查询应用欢迎语统一设置（GET）
        bindZhiyuanOa: "/api-open/channel/access/bindZhiYuanOa",
        checkStartNode: '/api-open/intent/check/startNode/relation', // 渠道管理删除机器人，校验机器人下所有意图是否被统一欢迎语配置使用（POST）
        getBotIntentTree: '/api-open/intent/bots/tree', // 获取机器人层面的意图（POST）
        getTagsOptions: '/api-open/tenant/customize-fields/tags', // 获取自定义标签数据
        getCustomizeFields:'/api-open/tenant/customize-fields', // 获取自定义字段
        getPersonalJobLevelOptions: '/api-open/tenant/customize-fields/options', // 获取个人职级数据
        knowledgeScope:"/api-open/channel/access/knowledge/scope",
        channelList:"/api-open/channel/access/getAllList",
        getInfoByBindCode:"/api-open/channel/access/getWeChatBotByBindCode",
        bindAgent:"/api-open/channel/access/bind/agent"

    },
    websiteBind: {
        // 网站接入
        getBindWebsiteList: "/api-open/bot/web/access/list", // 获取绑定网站列表GET
        addWebsiteBind: "/api-open/bot/web/access", //新增网站接入信息POST
        deleteWebsiteBind: "/api-open/bot/web/access", //删除网站接入信息DELETE
        corps4main: "/api/department/corps4main", // 获取主体下的公司 GET
        updateWebInfo:"/api-open/channel/access/web" // 更新网站应用信息 PUT

    },
    dingdingGroup: {
        // 钉钉群接入
        dingtalkGroupBind: "/api-open/bind/dingtalk/group/bind", // 绑定钉钉群 POST
        dingtalkGroupTemplateBind: "/api-open/bind/dingtalk/group/template/bind", // 绑定钉钉群模板 POST
        newTemplateBind:"/api-open/bind/dingtalk/group/template/newBind", //绑定钉钉群模板新接口 POST
        getDingdinggroupByTemplate: "/api-open/bind/dingtalk/group/list/", // GET 根据群模板ID分页查询绑定的群
        sync2group: "/api-open/bind/dingtalk/group/sync2group", // POST 同步群模板机器人到绑定的群
        dingtalkGroupBatchChange:
            "/api-open/bind/dingtalk/group/batch/change/visible", // PUT {groupTemplateId} 批量设置是否全员回复开关
        dingtalkGroupChange: "/api-open/bind/dingtalk/group/change/visible", // PUT {bindId} 单个设置是否全员回复开关
    },
    channel:{
        uploadFile: "/api-open/oss", //上传文件 POST
    },
    chatFlowNode: {
        // 对话流程组件
        getIntentChatFlow: "/api-open/v1/dialog/process", // 获取指定意图流程 GET
        getStertChatFlow: "/api-open/v1/dialog/process/start_node", // 获取开始节点流程 GET
        getEndChatFlow: "/api-open/v1/dialog/process/end_node", // 获取结束节点流程 GET
        addNode: "/api-open/v1/dialog/process/node", // 添加节点 POST
        deleteNode: "/api-open/v1/dialog/process/node", // 删除节点 DELETE
        updateNode: "/api-open/v1/dialog/process/node/action", // 更新节点 PUT
        updateNodeName: "/api-open/v1/dialog/process/node/name", // 更新节点名称 PUT
        ipdateStartNodeNew: "/api-open/bot/start_node", // 更新保存开始节点
        ipdateEndNodeNew: "/api-open/bot/end_node", // 更新保存开始节点
        moveNode: "/api-open/v1/dialog/process/node/move", // 移动节点
    },
    webhook: {
        getWebhookDir: "/api-open/webhook/dir/list", //获取Webhook目录列表GET
        webhookDetail: "/api-open/webhook/", //查询api详情 GET
        webhookbaseList: "api-open/webhook/base-list", // webhook列表（组件配置使用）
    },
    trainList: {
        skillIntent: "/api-open/intent/list/skill-intent", // 搜索意图 GET
        intentTree: "/api-open/intent/tree", // 意图树 GET
    },
    entity:{
        getAllEntityBySkill: "/api-open/v1/entity/all", //  获取指定知识库下所有实体 GET
        getEntityTree: "/api-open/v1/entity/tree", //获取实体结构树
    },
    chatFlowComponent: {
        // 对话流程组件
        getComponentsTypes: "/api-open/v1/dialog/design/component", // 获取流程组件类型列表 GET
        getConditionList: "/api-open/v1/dialog/design/component/condition", // 获取条件列表 GET
        getContitionScopeList:
            "/api-open/v1/dialog/design/component/condition/scope", // 获取条件作用于列表 GET
        getLastFeedback: "/api-open/bot/lastFeedback", // 获取机器人最后设置反馈 GET
    },
    intelligentAgent:{
        saveIntelligentAgent:"/api/intelligent-agent/save",
        updateIntelligentAgent:"/api/intelligent-agent/update",
        deleteIntelligentAgent:"/api/intelligent-agent/remove",
        getList:"/api/intelligent-agent/list",
        getDetail:"/api/intelligent-agent/",
        getWelcomeConfig:"/api/intelligent-agent/unifySetGreeting"
    },
    FeedbackSetting: {
        getFeedbackSetting: "/api-open/unidentifiedFeedback/selectByBindCode", // 获取机器人最后设置反馈 GET
        updateFeedbackSetting: "/api-open/unidentifiedFeedback/setUnidentifiedFeedback", // 更新机器人最后设置反馈 PUT
    }
}

export  {requestUrl}