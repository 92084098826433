<template>
  <div class="intelligentAgentConfig">
    <div class="gr-content-container">
      <detail-page-header @goBack="goBack">
        <div slot="header-name" class="header-name">
          <el-avatar shape="square" :size="30" :src="intelligentAgentDetail.logoUrl"></el-avatar>
          <div class="name-des">
            <div class="name">{{intelligentAgentDetail.agentName}}</div>
            <div class="des">{{intelligentAgentDetail.description}}</div>
          </div>
          <i class="iconfont guoran-tongyichicun-16-15-lianjibianji" @click="editIntelligentAgentFun"></i>
        </div>
        <div slot="header-title-c-r-btn" class="header-c-r-btn">
          <el-popover
              placement="top-start"
              width="100"
              trigger="click"
              popper-class="delete-btn"
              v-model="deleteVisible">
            <div class="popoverItem" @click="deleteFun">
              <i class="iconfont guoran-piliangshanchu"></i>
              {{$t('channel.delete')}}
            </div>
            <i slot="reference" class="iconfont guoran-qita1"></i>
          </el-popover>
        </div>
      </detail-page-header>
      <div class="configContent">
        <div class="leftNav">
          <div class="navItem"
               v-for="(item,index) in leftNavList"
               :key="index"
               :class="activeNavId === item.id ? 'activeNav' : ''"
               @click="checkNav(index,item)"
          >
            <i :class="['iconfont ',item.icon]"></i>
            {{$t('channel.' + item.id)}}
          </div>
        </div>
        <div v-if="activeNavId == 'ability'" class="rightContent">
          <div class="header">
            <div class="left">能力管理</div>
            <div class="saveBtn" @click="save">
              <i class="guoran-tongyichicun-16-03-baocun iconfont"></i>
              {{$t('channel.save')}}
            </div>
          </div>
          <bind-information
              :bindDetail="intelligentAgentDetail"
              ref="bindInformation"
              :fromSource="'intelligentAgentConfig'"
          ></bind-information>
        </div>
        <div v-if="activeNavId == 'feedback'" class="rightContent">
          <feedbackSetting
              :channelType="channelType"
              :bindDetail="intelligentAgentDetail"
              ref="feedbackSetting"
          ></feedbackSetting>
        </div>
      </div>
    </div>
    <SideNav ref="leftMenuObj"></SideNav>
    <popup @closeEvent="editIntelligentAgentDialog = false" v-if="editIntelligentAgentDialog" class="intelligent-agent-popup" :haveTips="false">
      <div slot="popup-name">编辑智能体</div>
      <div slot="popup-con" class="popup-container">
        <el-form
            label-position="top"
            :model="formAgent">
          <el-form-item label="智能体名称">
            <span slot="label">
              智能体名称
              <span style="color: red">*</span>
            </span>
            <el-input v-model="formAgent.agentName" placeholder="请输入智能体名称"></el-input>
          </el-form-item>
          <el-form-item label="智能体描述">
            <span slot="label">
              智能体描述
              <span style="color: red">*</span>
            </span>
            <el-input v-model="formAgent.description" placeholder="请输入智能体描述"></el-input>
          </el-form-item>
          <el-form-item label="活动形式">
            <div slot="label" class="avatar-title">
              <span>智能体LOGO</span>
              <span style="color: red">*</span>
              <span class="tip">LOGO为方形的PNG文件，尺寸为256x256像素</span>
            </div>
            <div class="uploadLogo">
              <div class="avatarList">
                <el-upload
                    class="avatar-uploader"
                    :show-file-list="false"
                    accept=".png"
                    :before-upload="beforeAvatarUpload"
                    :on-success="handleAvatarSuccess"
                    :http-request="aliyunOssRequest"
                >
                  <img v-if="formAgent.logoUrl" :src="formAgent.logoUrl" class="avatar">
                  <div v-else class="avatar-uploader-icon">
                    <i class="el-icon-plus"></i>
                  </div>
                  <div class="re_upload_icon" v-if="formAgent.logoUrl">
                    <i class="iconfont guoran-youxuan"></i>
                  </div>
                </el-upload>
                <div class="aiImageList">
                  <div class="aiImageItem"
                       v-for="(item,index) in imageList"
                       :key="index"
                       :class="formAgent.logoUrl == item.url ? 'activeImage' : ''"
                       @click="setLogo(item)"
                  >
                    <el-image
                        style="width: 120px; height: 120px;"
                        :src="item.url"
                        fit="cover"></el-image>
                  </div>
                </div>
              </div>
              <div class="aiBtn" @click="aiImageFun">
                {{ loading ?
                  $t('channel.botAiBtnStatus0') :
                  $t('channel.botAiBtnStatus1') }}
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="dialog-footer">
        <el-button  plain size="small" @click="editIntelligentAgentDialog = false" class="cancel-btn">取消</el-button>
        <el-button type="primary" @click="confirmSave" class="confirm-btn">确认</el-button>
      </div>
    </popup>
    <popup @closeEvent="closeWelcomeMessage" v-if="welcomeMessageVisiable" class="welcome_message_type">
      <div slot="popup-name">{{$t('botLink.setWelcome')}}</div>
      <div slot="popup-con" class="member-contanier">
        <p class="popup_title">{{$t('botLink.welcomeMode')}}</p>
        <div class="welcome_content">
          <div class="welcome_type" :class="welcomeMessageType == 'askbot' ? 'welcomeActive' : ''" @click="setWelcomeType('askbot')">
            <section class="welcome_right">
              <p class="welcome_right_title">{{$t('botLink.welcomeTip1')}}</p>
              <p class="welcome_right_tip">{{$t('botLink.welcomeTip2')}}</p>
            </section>
          </div>
          <div class="welcome_type" :class="welcomeMessageType == 'knowledge' ? 'welcomeActive' : ''" @click="setWelcomeType('knowledge')">
            <section class="welcome_right">
              <p class="welcome_right_title">{{$t('botLink.welcomeTip3')}}</p>
              <p class="welcome_right_tip">{{$t('botLink.welcomeTip4')}}</p>
            </section>
          </div>
        </div>
      </div>
      <div slot="dialog-footer">
        <el-button style="width: 100px" @click="closeWelcomeMessage" plain size="small">{{$t('common.cancel')}}</el-button>
        <el-button style="width: 100px" type="primary"  @click="sureWelcomeType" size="small">{{$t('common.confirm')}}</el-button>
      </div>
    </popup>
  </div>
</template>

<script>
import DetailPageHeader from "../../components/detail-header";
import SideNav from "../menu/SideNav";
import Popup from "../../components/popupNew";
import BindInformation from "./components/bindInformation";
import { requestUrl } from "../../api/requestUrl";
import { multipartUpload, ossConfig } from "../../assets/js/AliyunIssUtil";
import { submitBindBot } from "./utils/access";
import feedbackSetting from "./components/feedbackSetting.vue";
export default {
  name: "intelligentAgentConfig",
  components: { BindInformation, SideNav, DetailPageHeader,Popup, feedbackSetting },
  data(){
    return{
      deleteVisible:false,
      activeNavId:"ability",
      // 在模版中做了多语言处理，无需在此改动
      leftNavList:[
        {
          name:"能力管理",
          icon:"guoran-tongyichicun-chanpinguanli",
          id:"ability"
        },
        {
          name:"欢迎语",
          icon:"guoran-tongyichicun-huanyingyu",
          id:"welcomeMsg"
        },
        {
          name:"未识别反馈",
          icon:"guoran-tongyichicun-daanfankuijilu",
          id:"feedback"
        },
      ],
      intelligentAgentDetail:{},
      editIntelligentAgentDialog:false,
      formAgent:{
        agentName:"",
        description:"",
        logoUrl:"",
        bindBot:null,
      },
      welcomeMessageDate:{},
      welcomeMessageType:"",
      messageType:"",
      welcomeMessageVisiable:false,
      previousActive:0,
      loading:false,
      imageList:[],

      channelType: "dingding"
    }
  },
  methods:{
    goBack(){
      if (this.$route.query.fromSource == '_competenceManagement') {
        this.$router.go(-1);
      } else {
        this.$router.push({
          path:'/channel'
        })
      }
    },
    editIntelligentAgentFun(){
      this.editIntelligentAgentDialog = true;
      this.formAgent = JSON.parse(JSON.stringify(this.intelligentAgentDetail));
    },
    deleteFun(){
      this.$confirm('是否确认删除智能体', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let url = requestUrl.intelligentAgent.deleteIntelligentAgent;
        url += '?id=' + this.$route.query.id;
        this.$http.delete(url).then(res => {
          if (res.data.code == 0){
            this.$message({
              message:"删除成功",
              type:"success"
            })
            this.$router.go(-1);
            sessionStorage.setItem('deleteIntelligent',true)
          }
        })
      }).catch(() => {

      });
    },
    checkNav(index,item){
      if (item.id === 'welcomeMsg'){
        if (!this.intelligentAgentDetail.bindBot || this.intelligentAgentDetail.bindBot.botInfos.length == 0){
          this.$message({
            message:"请先绑定机器人",
            type:"warning"
          })
          return
        }
        this.previousActive = this.activeNavId;
        sessionStorage.setItem('configPreviousActive',this.previousActive)
        this.setWelcomeMesaage()
      }
      this.activeNavId = item.id;
    },
    // 设置/获取欢迎语
    setWelcomeMesaage(){
      let data = this.intelligentAgentDetail;
      this.welcomeMessageDate = JSON.parse(JSON.stringify(data))
      this.$http.get(this.requestUrl.bindV2.selectUnifySetGreeting + '?bindCode=' + data.bindCode).then((res) => {
        if (res.data.code === "0") {
          let tampActions = res.data.data && res.data.data.setting && res.data.data.setting.unifySetStartNodeActions ? res.data.data.setting.unifySetStartNodeActions : [];
          if(tampActions.length == 0) {
            this.welcomeMessageVisiable = true;
            this.welcomeMessageType = 'askbot';
          } else {
            if(tampActions.some(action =>{ return action.type == 'welcome_knowledge_file' || action.type == 'welcome_llm_card' })) {
              this.messageType = 'knowledge';
              this.$router.push({
                path: '/knowledgeWelcomeMessage',
                query:{
                  bindCode: data.bindCode,
                  agentName: data.agentName,
                  wechatType: data.wechatType,
                  type:'',
                  source:"intelligentAgentConfig",
                  id:data.id
                }
              })
            } else {
              this.messageType = 'askbot';
              this.$router.push({
                path: '/intentWelcomeMessage',
                query:{
                  bindCode: data.bindCode,
                  agentName: data.agentName,
                  wechatType: data.wechatType,
                  type:'',
                  source:"intelligentAgentConfig",
                  id:data.id
                }
              })
            }
          }
        }
      })
    },
    async save(){
      let bindBots = this.$refs.bindInformation.bindBots;
      this.submitBindBot(this.intelligentAgentDetail,bindBots,false,this)
    },
    submitBindBot(intelligentAgentDetail,bindBots,enableKnowledgeSearch,that) {
      let botsArr = [];
      let conditionsFlag = true;
      bindBots.forEach(bot => {
        let conditionsCopy = []
        let itemsCopy = [];
        let itemsFlag = false
        //  selfType 0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段
        if(intelligentAgentDetail.bindBot == null || (intelligentAgentDetail.bindBot && intelligentAgentDetail.bindBot.count == 0)){
          itemsFlag = true
          bot.items && bot.items.forEach(itemsValue => {
            let obj = {
              id:itemsValue.id,
              type:itemsValue.selfType ? itemsValue.selfType === 2 ? 1 : 0 : itemsValue.type, // //  范围对应类型1：部门 0：成员
              name:itemsValue.name,
              source:'UPDOWN'
            }
            itemsCopy.push(obj)
          })

        }
        if(bot.conditions && bot.conditions.length > 0){
          bot.conditions.forEach(conditionsItem => {
            if(conditionsItem.type == 'ALL'){
              conditionsCopy.push({
                expect:[],
                type:conditionsItem.type,
                key:"",
                relation:null,
              })
            } else if(conditionsItem.type == 'STAFF_OR_DEPARTMENT'){
              conditionsCopy.push({
                expect:conditionsItem.expect,
                type:conditionsItem.type,
                key:"",
                relation:conditionsItem.relation,
              })
            } else if(conditionsItem.type == 'CUSTOMIZE'){
              if(conditionsItem.key != 'label' && conditionsItem.key != 'personalJobLevel' && conditionsItem.key !== '入职时间'){
                conditionsCopy.push({
                  expect: Array.isArray(conditionsItem.expect)?conditionsItem.expect:[conditionsItem.expect],
                  type: conditionsItem.type,
                  key: conditionsItem.key,
                  relation: conditionsItem.relation,
                })
              } else if( conditionsItem.key == '入职时间'){
                if(conditionsItem.relation == 'BEFORE' || conditionsItem.relation == 'AFTER'){
                  conditionsCopy.push({
                    expect: [conditionsItem.expect],
                    type: conditionsItem.type,
                    key: conditionsItem.key,
                    relation: conditionsItem.relation,
                  })
                } else {
                  conditionsCopy.push({
                    expect: conditionsItem.expect,
                    type: conditionsItem.type,
                    key: conditionsItem.key,
                    relation: conditionsItem.relation,
                  })
                }
              } else {
                conditionsCopy.push({
                  expect: conditionsItem.expect,
                  type: conditionsItem.type,
                  key: conditionsItem.key,
                  relation: conditionsItem.relation,
                })
              }
            }
          })
        } else {
          conditionsFlag = false
        }
        let obj = {
          apiKey: bot.apiKey,
          botId: bot.id || bot.botId,
          name: bot.name,
          scope: bot.scope,
          items: itemsFlag ? itemsCopy : bot.items,
          conditions:conditionsCopy,
          botType: bot.botType || 0,
          plugins:bot.plugins ? bot.plugins.map((res) => {
            let obj = {
              id:res.id,
              name: res.name,
              scope:res.scope,
              conditions:res.conditions.map(ress => {
                let conditions = {
                  expect:[],
                  type:ress.type,
                  key:"",
                  relation:null,
                }
                if(ress.type == 'ALL'){
                  conditions.type = ress.type
                } else if(ress.type == 'STAFF_OR_DEPARTMENT'){
                  conditions.expect = ress.expect
                  conditions.type = ress.type
                  conditions.relation = ress.relation
                } else if(ress.type == 'CUSTOMIZE'){
                  conditions.expect = ress.expect
                  conditions.type = ress.type
                  conditions.key =  ress.key;
                  conditions.relation = ress.relation
                }
                return conditions
              })
            }
            return obj
          }) : []
        }
        botsArr.push(obj)

      })
      botsArr = botsArr.filter(item => item.botId);
      console.log('botsArr',botsArr,bindBots)
      if (botsArr.length == 0) {
        that.$message.error(that.$t('channel.noBindBotTip'));
        return false
      }
      let flag = true;
      botsArr.forEach(item => {
        if(item.botId === ''){
          flag = false;
        }
      })
      if (!flag){
        that.$message.error(that.$t('channel.selectBindBot'));
        return
      }
      if(!conditionsFlag) {
        that.$message.warning(that.$t('channel.conditionsTip'));
        return false;
      }
      if(!flag || !conditionsFlag) return false;
      let url = requestUrl.intelligentAgent.updateIntelligentAgent;
      this.intelligentAgentDetail.bindBot.agentBindBotInfos = botsArr;
      delete this.intelligentAgentDetail.bindBot.botInfos
      this.$http.put(url,this.intelligentAgentDetail).then(res => {
        if (res.data.code == 0){
          this.$message({
            message:this.$t('channel.saveSuccess'),
            type:"success"
          })
          this.$refs.bindInformation.bindBots = botsArr
          this.getIntelligentAgentDetail();
        }
      })

    },
    async beforeAvatarUpload(file){
      const istype = file.type === 'image/png'
      const isSize = await new Promise(function (resolve, reject) {
        let width = 256
        let height = 256
        let _URL = window.URL || window.webkitURL
        let image = new Image()

        image.onload = function () {
          const valid =
              image.width <= width &&
              image.height <= height &&
              image.width / image.height == 1
          console.log('valid',valid,image.width)
          valid ? resolve() : reject()
        }
        image.src = _URL.createObjectURL(file);
      }).then(
          () => {
            return file
          },
          () => {
            this.$message({
              message:this.$t('channel.avatarSizeError'),
              type:"error"
            })
            return Promise.reject(false)
          }
      )

      if (!istype) {
        this.$message({
          message:this.$t('channel.avatarTypeErr'),
          type:"error"
        })
      }

      return isSize && istype
    },
    handleAvatarSuccess(res){
      console.log('handleAvatarSuccess',res)
      let url = res.res.requestUrls[0];
      let index = url.lastIndexOf("?")
      if (index !== -1){
        url = url.substr(0, url.lastIndexOf("?"));
      }
      this.$set(this.formAgent,'logoUrl',url);
    },
    aliyunOssRequest(data){
      let file = data.file;
      let res = multipartUpload(ossConfig,file);
      console.debug('oss upload res', data, res);
      return res
    },
    confirmSave(){
      let url = requestUrl.intelligentAgent.updateIntelligentAgent;
      this.$http.put(url,this.formAgent).then(res => {
        if (res.data.code == 0){
          this.$message({
            message:"更新成功",
            type:"success"
          })
          this.editIntelligentAgentDialog = false;
          this.getIntelligentAgentDetail();
        }
      })
    },
    async getIntelligentAgentDetail(){
      let id = this.$route.query.id;
      let url = requestUrl.intelligentAgent.getDetail + id;
      const res = await this.$http.get(url);
      if (res.data.code == 0){
        this.intelligentAgentDetail = res.data.data;
        if (!this.intelligentAgentDetail.bindBot){
          let obj = {
            botInfos:[],
            agentBindBotInfos:[]
          }
          this.$set(this.intelligentAgentDetail,'bindBot',obj);
        } else {
          this.$set(this.intelligentAgentDetail.bindBot,'botInfos',this.intelligentAgentDetail.bindBot.agentBindBotInfos);
        }
        
      }
    },
    closeWelcomeMessage(){
      this.welcomeMessageVisiable = false;
      this.welcomeMessageType = 'askbot';
      this.activeNavId = this.previousActive
    },
    setWelcomeType(type){
      this.welcomeMessageType = type;
    },
    sureWelcomeType(){
      this.welcomeMessageVisiable = false;
      if (!this.welcomeMessageType){
        return
      }
      this.messageType = this.welcomeMessageType;
      if(this.welcomeMessageType == 'askbot') {
        this.$router.push({
          path: '/intentWelcomeMessage',
          query:{
            bindCode: this.intelligentAgentDetail.bindCode,
            agentName: this.intelligentAgentDetail.agentName,
            wechatType: this.intelligentAgentDetail.wechatType,
            type:'',
            source:"intelligentAgentConfig",
            id:this.intelligentAgentDetail.id
          }
        })
      } else {
        this.$router.push({
          path: '/knowledgeWelcomeMessage',
          query:{
            bindCode: this.intelligentAgentDetail.bindCode,
            agentName: this.intelligentAgentDetail.agentName,
            wechatType: this.intelligentAgentDetail.wechatType,
            type:'',
            source:"intelligentAgentConfig",
            id:this.intelligentAgentDetail.id
          }
        })
      }
    },
    aiImageFun() {
      let url = "/tag-api/model/generate-logo";
      if (!this.formAgent.agentName && !this.formAgent.description) {
        this.$message({
          message: this.$t('channel.atLeastOneInput'),
          type: "warning",
        });
        return;
      }
      let obj = {
        prompt:
            "帮我生成一个智能体LOGO图片，正方形，简单风格，色彩三种以内，透明色背景，图片内容体现如下信息:" +
            this.formAgent.agentName +
            "," +
            this.formAgent.description,
        uid: localStorage.getItem("_uid"),
      };
      this.loading = true;
      this.$http.post(url, obj).then((res) => {
        this.loading = false;
        if (res.data) {
          this.imageList = [
            {
              url: res.data,
            },
          ];
        }
      }).catch(() => {
        this.$message({
          message:this.$t('channel.generationFailed'),
          type:"warning"
        })
        this.loading = false;
      });
    },
    setLogo(item){
      this.$set(this.formAgent,'logoUrl',item.url);
    },
  },
  async mounted() {
    await this.getIntelligentAgentDetail();
    
    if (sessionStorage.getItem('configPreviousActive')){
      this.activeNavId = sessionStorage.getItem('configPreviousActive')
      sessionStorage.setItem('configPreviousActive',this.activeNavId);
    } else {
      this.activeNavId = this.leftNavList[0].id;
    }
    this.previousActive = this.activeNavId;
    
  }
};
</script>

<style scoped lang="less">
.intelligentAgentConfig{
  .gr-content-container {
    position: absolute;
    left: 64px;
    width: calc(100% - 96px);
    height: calc(100% - 24px);
    padding: 12px 16px;
    min-width: 1000px;
    overflow-x: auto;
    text-align: left;
    .header-name{
      display: flex;
      align-items: center;
      img{
        height: 40px;
        width: 40px;
        object-fit: cover;
      }
      .tips{
        cursor:pointer;
        color: #366aff;
      }
      .name-des{
        display: flex;
        flex-direction: column;
        margin-left: 14px;
        margin-right: 14px;
        .name{
          color: #000000;
          margin-bottom: 4px;
          max-width: 240px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .des{
          color: #A9B3C6;
          font-size: 12px;
          max-width: 240px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .guoran-tongyichicun-16-15-lianjibianji{
        cursor: pointer;
        color: #366aff!important;
      }
    }
    .configContent{
      display: flex;
      align-items: center;
      margin-top: 20px;
      height: calc(100% - 82px);
      .leftNav{
        flex: none;
        width: 230px;
        height: 100%;
        background-color: #FFFFFF;
        .navItem{
          margin: 10px 0;
          padding: 14px 20px;
          border-left: 2px solid #FFFFFF;
          cursor:pointer;
          i{
            font-size: 14px;
            margin-right: 4px;
          }
        }
        .activeNav{
          border-left: 2px solid #366aff!important;
          background: #f1f4ff;
          color: #366aff;
        }
      }
      .rightContent{
        flex: 1;
        height: 100%;
        margin-left: 16px;
        background-color: #FFFFFF;
        box-sizing: border-box;
        padding: 20px;
        width: calc(100% - 240px);
        .header{
          margin-bottom: 14px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left{
            font-size: 16px;
            color: #000000;
          }
        }
        .saveBtn{
          height: 26px;
          background-color: #366aff;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 14px;
          font-size: 12px;
          border-radius: 17px;
          cursor: pointer;
          i{
            font-size: 12px;
            padding-right: 4px;
          }
        }
      }
    }
  }
}
.header-c-r-btn{
  padding-right: 10px;
  .guoran-qita1{
    color: #366aff;
    cursor: pointer;
  }
}
.popoverItem{
  display: flex;
  align-items: center;
  cursor:pointer;
  i{
    margin-right: 10px;
    font-size: 14px;
    color: #366aff;
  }
}
.intelligent-agent-popup{
  #popup-assembly-new{
    width: 790px !important;
  }
  .popup-container{
    .avatar-title{
      display: flex;
      align-items: center;
      .tip{
        margin-left: 10px;
        font-size: 12px;
        color: #A9B3C6;
      }
    }
    /deep/.el-form-item__label{
      line-height: 20px;
    }
    .uploadLogo{
      display: flex;
      flex-direction: column;
      .avatar-uploader{
        margin-right: 10px;
        .el-upload {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }
        .re_upload_icon{
          height: 120px;
          width: 120px;
          display: none;
          align-items: center;
          justify-content: center;
          background: rgba(0, 0, 0, 0.3);
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 5px;
          i {
            color: #ffffff;
          }
        }
        .el-upload:hover {
          border-color: #409EFF;
          .re_upload_icon{
            display: flex;
          }
        }
        .avatar-uploader-icon {
          color: #8c939d;
          width: 120px;
          height: 120px;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: 1px dashed #d9d9d9;
          .el-icon-plus{
            font-size: 28px;
            //color: #366aff;
            margin-bottom: 10px;
          }
          .tips{
            margin-bottom: 6px;
          }
        }
        .avatar {
          width: 120px;
          height: 120px;
          display: block;
        }
      }
      .aiImageList{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .aiImageItem{
          margin-right: 10px;
          border: 1px solid #ffffff;
        }
        .activeImage{
          .el-image{
            border: 1px solid #366aff;
          }
        }

      }
      .logoTips{
        display: flex;
        flex-direction: column;
        color: #A9B3C6;
      }
      .avatarList{
        display: flex;
      }
      .aiBtn{
        flex: none;
        margin-top: 10px;
        padding: 0 10px;
        max-width: 80px;
        height: 30px;
        background: #366AFF;
        border-radius: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }

}
.welcome_message_type {
  #popup-assembly-new {
    width: 500px !important;
  }
  .popup_title {
    line-height: 28px;
    text-align: left;
    font-size: 16px;
  }
  .welcome_content {
    width: 100%;
    .welcome_type {
      width: 100%;
      height: 70px;
      border-radius: 15px;
      background: #f0f4ff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 12px 0;
      text-align: left;
      .welcome_left {
        width: 48px;
        height: 48px;
        border-radius: 10px;
        overflow: hidden;
        background: #606266;
        margin: 0 16px;
      }
      .welcome_right {
        // width: calc(100% - 80px);
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 16px;
        box-sizing: border-box;
        .welcome_right_title {
          margin: 2px 0;
        }
        .welcome_right_tip {
          margin: 2px 0;
          color: #A9B3C6;
        }
      }
    }
    .welcomeActive {
      border: 1px solid #366AFF;
    }
  }
}
</style>